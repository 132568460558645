import { useEffect, useRef } from 'react';
import party from 'party-js';


const Confetes = ({ avalicao }: any) => {
    const div = useRef(null);

    const handleParty = () => {
        if (div.current) {
            party.confetti(div.current, {
                count: 60,
                spread: 80
            });
        }
    };

    useEffect(() => {
        handleParty()
        let intervalo = setInterval(handleParty, 5000);


        return () => {
            clearInterval(intervalo);
        };
    }, []);




    return (
        <div ref={div}>{avalicao !== 2 ?
            <>Resposta Certa - Parabéns!</>
            : <>Obrigado!</>
        }
        </div>
    );
};

export default Confetes;
