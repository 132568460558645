import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useCallback } from 'react';
import { Avatar, AvatarGroup, Button, Dialog, Grid, Paper } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import { CorButton } from '../cor';
import { useNavigate } from 'react-router-dom';
import api from '../../axios/axios';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import BackspaceIcon from '@mui/icons-material/Backspace';
import EscolherFoto from './escolher';




const ProfileImageUploader: React.FC<any> = ({ onImageUpload, fotoPerfil }: any) => {
    const [nome, setNome] = useState("")

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        setNome(file.name)
        if (file.size > 25 * 1024 * 1024) {
            toast.error("Arquivo muito grande")
            return
        }
        onImageUpload(file);
    }, [onImageUpload]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: { 'image/*': [] },
    });

    return (
        <div
            {...getRootProps()}
            style={{
                border: '2px dashed #568fb6',
                width: "100%",
                textAlign: 'center',
                cursor: 'pointer',

                backgroundColor: isDragActive ? '#eeeeee' : 'white',
            }}
        >
            <input {...getInputProps()} />
            <p>{isDragActive ? 'Solte a imagem aqui...' : nome === "" ? 'Arraste e solte a imagem aqui ou clique para selecionar' : fotoPerfil?.id === -1 ? 'Arraste e solte a imagem aqui ou clique para selecionar' : nome}</p>
        </div>
    );
};

export default function Perfil({ decodedToken, setDecodedToken }: any) {
    const [fotoPerfil, setfotoPerfil] = useState<any>(decodedToken?.foto !== undefined ? { data: decodedToken.foto } : null);

    const [editar, setEditar] = useState(false);
    const [esperar, setEsperar] = useState(false);
    const navigate = useNavigate()

    const handleImageUpload = (file: File) => {
        const reader = new FileReader();

        reader.onload = () => {
            setfotoPerfil({ nome: file.name, data: reader.result as string });
        };

        reader.readAsDataURL(file);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleAvatarClick = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const enviarFoto = () => {
        toast.info("Enviando foto")
        setEsperar(true);
        api.post("perfil", fotoPerfil).then((e) => {
            setDecodedToken({ ...decodedToken, foto: e.data.data });
            setfotoPerfil({ ...fotoPerfil, data: e.data.data })
            toast.success("Foto envida com sucesso");
            setEditar(false);

        }).catch(e => toast.error("Foto não enviada")).finally(() => setEsperar(false))

    }

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginBottom: "1rem" }}>
                <Grid item>
                    <CorButton cor="#1976d2">
                        <Button onClick={() => navigate("/")} size="small" >
                            Voltar
                        </Button>
                    </CorButton>
                </Grid>
            </div>

            <Grid

                container
                direction="row"
                justifyContent="center"
                alignItems="center" spacing={2}
                style={{ marginTop: "1rem" }}
            >
                <Grid item xs={8} className="sombra" style={{ padding: "1rem", borderRadius: '1rem' }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"

                    >
                        <Grid item xs={true}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems={editar ? "flex-start" : "center"}
                                spacing={2}

                            >
                                <Grid item >
                                    <Avatar

                                        onClick={(e) => fotoPerfil?.data !== undefined ? handleAvatarClick() : ""}
                                        style={{
                                            border: "2.5px solid white", cursor: fotoPerfil?.data !== undefined ? "pointer" : "unset",

                                            objectFit: "cover",
                                        }}
                                        component={Paper}
                                        elevation={3}
                                        sx={{ width: 70, height: 70 }}
                                        src={fotoPerfil ? fotoPerfil?.data : ""}
                                    />
                                    <Dialog open={isModalOpen} onClose={handleModalClose}>
                                        <img
                                            src={fotoPerfil ? fotoPerfil?.data : ""}
                                            alt="Profile"
                                            style={{ width: '100%', height: 'auto', maxWidth: '100vw', maxHeight: '100vh' }}
                                        />
                                    </Dialog>

                                </Grid>
                                <Grid item xs={true}>
                                    {editar ?
                                        <>
                                            <Grid item><span>Avatar:</span></Grid>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                style={{ paddingBottom: ".5rem", cursor: "pointer" }}
                                            >


                                                <EscolherFoto decodedToken={decodedToken} esperar={esperar} setfotoPerfil={setfotoPerfil} />

                                            </Grid>
                                            <Grid item>
                                                <span >{"Foto:"}</span>
                                            </Grid>
                                            <Grid item xs={true}>
                                                <ProfileImageUploader fotoPerfil={fotoPerfil} onImageUpload={handleImageUpload} />
                                            </Grid>
                                        </>
                                        :
                                        <Grid item>
                                            <span >{decodedToken.name}</span>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>

                        </Grid>
                        {editar === false ?
                            <Grid item>
                                <Button onClick={(e) => setEditar(true)} size='small' variant="contained" >
                                    <EditIcon></EditIcon>
                                </Button>
                            </Grid>
                            : <></>}

                    </Grid>

                    {editar ?
                        <Grid item xs={true}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center" spacing={2}
                                style={{ marginTop: ".5rem" }}
                            >
                                <Grid item>
                                    <LoadingButton loading={esperar} loadingPosition="start"
                                        startIcon={<SaveIcon />} disabled={esperar} onClick={(e) => enviarFoto()} size='small' variant="contained" >
                                        Salvar
                                    </LoadingButton>
                                </Grid>
                                <Grid item>
                                    <LoadingButton loading={esperar} loadingPosition="end" endIcon={<BackspaceIcon />} disabled={esperar} onClick={(e) => { setEditar(false); setfotoPerfil({ ...fotoPerfil, data: decodedToken.foto }) }} size='small' color="error" variant="contained" >
                                        Cancelar
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        : ""}
                </Grid>


            </Grid>
        </>
    );
};