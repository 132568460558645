import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Button as ButtonAntd, Modal } from 'antd';
import Curso from '../curso';

const ITEM_HEIGHT = 48;

function LongMenu({ trilha, turma, deleteAPI, setCurso, curso, turmaEditar, handleOpen, setNovaTurma, setEditar, editar, editarNovoCurso, central }: any) {

    let options = [
        "Editar",
        "Deletar",
    ];
    const [modal, setModal] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [abrir, setAbrir] = React.useState(false);

    const handleClickOpen = () => {
        setAbrir(true);
    };

    const handleCloseOpen = () => {
        setAbrir(false);
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.target);
    };


    const handleClose = (e: number) => {
        if (options[e] && options[e].toLocaleLowerCase() === "editar") {
            if (turmaEditar) {
                setModal(true);
                handleOpen();
                if (turma)
                    setNovaTurma(turma);
                setEditar(true)
            }
            else {
                setModal(true)
            }

        }
        else if (options[e] && options[e].toLocaleLowerCase() === "deletar") {
            handleClickOpen()
        }
        setAnchorEl(null);
    };

    return (
        <div>
            {turmaEditar ? "" :
                <Modal
                    width="100%"
                    centered
                    footer={null}
                    open={modal}

                    onCancel={() => setModal(false)}
                >
                    <Curso trilha={trilha} modal={modal} setCursos={setCurso} cursos={curso} setModal={setModal} cursoItem={curso} editar={editar} editarNovoCurso={editarNovoCurso} central={central} turma={turma} />

                </Modal>
            }
            <Dialog
                open={abrir}
                onClose={handleCloseOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Tem certeza que deseja realmente deletar ${turma?.nome || curso.nome}?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonAntd type="primary" onClick={handleCloseOpen}>Não</ButtonAntd>
                    <ButtonAntd type="primary" danger onClick={_ => { handleCloseOpen(); if (turma) { deleteAPI(turma) } else { deleteAPI(curso) } }} autoFocus>
                        Sim
                    </ButtonAntd>

                </DialogActions>
            </Dialog>

            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 'auto',

                    },
                }}
            >
                {options.map((option: any, i: number) => (
                    <MenuItem key={option} selected={option === 'Pyxis'} onClick={_ => handleClose(i)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default React.memo(LongMenu)