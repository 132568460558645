import Grid from "@mui/material/Grid";
import { GridAtividade, GridAtividadeBorda, GridAtividades, GridSpanCenter, GridSpanDiv } from "../cor";
import { Checkbox, DatePicker, Input, InputNumber, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import locale from 'antd/lib/date-picker/locale/pt_BR';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArticleIcon from '@mui/icons-material/Article';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import EditIcon from '@mui/icons-material/Edit';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LinkIcon from '@mui/icons-material/Link';
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDrop, DropTargetMonitor, DragSourceMonitor } from 'react-dnd';
import { useDrag } from 'react-dnd';
import { Atividade, AulaItem, AuthToken, CursoItem, ListaCurso, convertToEmbedUrl, formatarNumero, newAtividade, newAulaItem, validateLink } from "../types";
import React from "react";
import moment from 'moment-timezone';
import { Button, Switch } from "@mui/material";
import ExpandirAtividade from "./expandirAtividade";
import api from "../../axios/axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { keycloak } from "../..";
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { Quiz } from "@mui/icons-material";
import AddTaskIcon from '@mui/icons-material/AddTask';


function GridContainer(atividade: any, setItems: any, cor: any, turma: any, aula: any, trilha = false) {

    const [dropIndex, setDropIndex] = useState<number | null>(null);
    const moveItem = (dragIndex: number, hoverIndex: number) => {

        const draggedItem = atividade[dragIndex];
        const updatedItems = [...atividade];

        updatedItems.splice(dragIndex, 1);
        updatedItems.splice(hoverIndex, 0, draggedItem);
        setItems(updatedItems);
    };



    return (<>


        <Grid container spacing={3} onClick={_ => _.stopPropagation()}>

            {atividade.map((item: AulaItem, index: number) => (
                <React.Fragment key={item.id} >
                    <Grid item xs={12}>
                        <DraggableItem

                            key={item.id}
                            item={item}
                            cor={cor}
                            index={index}
                            moveItem={moveItem}
                            dropIndex={dropIndex}
                            setDropIndex={setDropIndex}
                            atividade={atividade}
                            setAtividade={setItems}
                            turma={turma}
                            aula={aula}
                            trilha={trilha}
                        />
                    </Grid>
                </React.Fragment>
            ))}

        </Grid>


    </>);

}




function DraggableItem({
    item,
    index,
    moveItem,
    dropIndex,
    setDropIndex,
    cor,
    atividade,
    setAtividade,
    turma,
    aula,
    trilha
}: any) {
    const ref = useRef<HTMLDivElement>(null);
    const draggedItem = useRef<any | null>(null);
    const [{ isDragging }, drag] = useDrag<any, any, { isDragging: boolean }>({
        type: 'item',
        item: { type: 'item', id: item.id, index },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop<any, any, any>({
        accept: 'item',
        hover: (item: any, monitor: DropTargetMonitor) => {
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            setDropIndex(hoverIndex);
        },
        drop: (item: any) => {
            if (dropIndex !== null) {
                moveItem(item.index, dropIndex);
                item.index = dropIndex;
            }
            setDropIndex(null);
        },
    });

    useEffect(() => {
        if (isDragging) {
            draggedItem.current = item;
        } else {
            draggedItem.current = null;
        }
    }, [isDragging, item]);

    const opacity = isDragging ? 0.5 : 1;

    drag(drop(ref));

    return (<>
        <div
            ref={ref}
            style={{
                opacity,
                backgroundColor: '#f1f4f8',
                borderRadius: '.5rem',
                cursor: 'move',
                margin: "-1rem",

            }}
        >
            <Grid item xs={10}> <div style={{ marginTop: "1rem", position: "absolute", width: "-webkit-fill-available", height: "2.5rem" }}></div></Grid>
        </div >
        <div

            style={{
                opacity,
                backgroundColor: '#f1f4f8',
                borderRadius: '.5rem',
                cursor: 'move',
                margin: "-1rem",

            }}
        >
            {ExpandirAtividade({ atividade, cor, setAtividade, index, turma, aula, trilha })}
        </div>

    </>
    );
}



function Aula_Criar({ setAulaItem, cursoTrilha, trilha = false, dataAberta = false, uuid_aula, setAulaBack, curso, setModal, setCursos, setCurso, atividade, setAtividade, aula, setAula, editar, editarNovoCurso, central, checkNomeCurso, setCheckNomeCurso, user, turma, xy = false, setAulaMat, aulaMat, matriz, setMatriz }: any) {
    const cor = "#0048b6"
    let { uuid_curso, uuid_turma } = useParams();
    const [check, setCheck] = useState(false);
    const decodedToken = keycloak.token && jwtDecode(keycloak.token) as AuthToken;
    const [data, setData] = useState(dataAberta);


    useEffect(() => {
        setData(dataAberta)

    }, [dataAberta])

    const navigate = useNavigate();

    if (curso)
        uuid_curso = curso.id;

    useEffect(() => {
        if (editar === false && curso) {
            setAula(newAulaItem(curso.id));
        }

    }, [])

    if (!trilha && curso) {
        curso.trilha = null;
    }

    const postApi = () => {

        if (curso?.nome.trim() === "") {
            toast.error("Erro ao criar curso");
            setCheckNomeCurso(true);
            return
        }
        if (checkNomeCurso === undefined && aula?.nome.trim() === "") {
            toast.error("Erro ao criar aula");
            setCheck(true);
            return
        }
        if (editarNovoCurso && editar && uuid_turma !== undefined && curso && central === undefined) {
            if (trilha)
                curso.trilha = [
                    [0, 0, 0, 0, 0],
                    [0, 0, 0, 0, 0],
                    [0, 0, 0, 0, 0],
                    [0, 0, 0, 0, 0],
                ]

            curso.id_turma = uuid_turma
            curso.id_pessoa = turma?.id_pessoa

            if (trilha)
                toast.info("Criando trilha")
            else
                toast.info("Criando curso")


            api.post(`/curso`, JSON.stringify(curso)).then(_ => {
                if (trilha)
                    toast.success("Trilha criada")
                else
                    toast.info("Curso criado")
                let p = new CursoItem();
                if (uuid_turma)
                    p.id_turma = uuid_turma
                setCheck(false)
                setCursos((c: ListaCurso) => ({ ...c, items: [curso, ...c.items] }))
                setModal(false);
                setCheckNomeCurso(false)
                if (trilha)
                    navigate(`/trilha/${uuid_turma}/${curso.id}`);
                else
                    navigate(`/turma/${uuid_turma}/${curso.id}`);
            }).catch(_ => { toast.error("Erro ao criar curso"); })

        }

        else if (editar && curso) {
            curso.id_turma = uuid_turma;
            toast.info("Editando curso")

            api.patch(`curso/${curso.id}`, JSON.stringify(curso)).then(_ => {
                toast.success("Curso editado")
                setModal(false)
                setCheck(false)

                setCursos((ant: ListaCurso) => {
                    const novosItems = ant.items.map((item: CursoItem) => {
                        if (item.id === curso.id) {
                            curso.aulas = item.aulas
                            return curso;
                        }
                        return item;
                    });
                    return { ...ant, items: novosItems };

                })
            }).catch(_ => toast.error("Erro ao editar curso"))

        }
        else if (uuid_aula) {
            toast.info("Editando aula")
            aula.atividades = atividade;
            aula.id_curso = uuid_curso;
            if (xy) {
                aula.xy = xy
            }
            api.patch(`aula/${aula.id}`, JSON.stringify(aula)).then(_ => {
                toast.success("Aula editada");
                setModal(false);
                setCheck(false)

                if (xy) {
                    if (aulaMat.hasOwnProperty(xy[0]) === false)
                        aulaMat[xy[0]] = {}

                    aulaMat[xy[0]][xy[1]] = aula;
                    setAulaMat(aulaMat)
                    matriz[xy[0]][xy[1]] = 4
                    setMatriz(matriz)
                    setAulaItem(aula)
                }

                setAulaBack((ant: ListaCurso) => {
                    const novosItems = ant.items.map((item: CursoItem) => {
                        if (item.id === aula.id) {
                            return aula;
                        }
                        return item;
                    });
                    return { ...ant, items: novosItems };

                })

            }
            ).catch(_ => toast.error("Erro ao editar aula"))
        }

        else if (curso) {
            curso.id_turma = null;

            toast.info("Criando curso")
            api.post(`curso`, JSON.stringify(curso)).then(_ => {
                aula.atividades = atividade;
                toast.success("Curso criado");
                setModal(false)
                curso.aulas = 0;
                curso.user = user;
                setCheck(false)
                setCursos((ant: ListaCurso) => ({ ...ant, items: [curso, ...ant.items] }))
                setCheckNomeCurso(false)

                navigate(`/centralcursos/${curso.id}`);
            }

            ).catch(_ => { toast.error("Erro ao criar curso"); api.delete(`curso/${curso.id}`) })

        }
        else if (uuid_aula === undefined) {
            aula.atividades = atividade;
            aula.id_curso = uuid_curso;
            toast.info("Criando aula")
            if (xy) {
                aula.xy = xy
            }
            api.post(`aula/${uuid_curso}`, JSON.stringify(aula)).then(_ => {
                toast.success("Aula criada");
                setCheck(false)
                aula.user = decodedToken ? decodedToken?.preferred_username : "";

                if (xy) {
                    if (aulaMat.hasOwnProperty(xy[0]) === false)
                        aulaMat[xy[0]] = {}

                    aulaMat[xy[0]][xy[1]] = aula;
                    setAulaMat(aulaMat)
                    matriz[xy[0]][xy[1]] = 4
                    setMatriz(matriz)

                    cursoTrilha.trilha = matriz;
                    api.patch('/curso/' + cursoTrilha.id, JSON.stringify(cursoTrilha))
                    setCurso(cursoTrilha);

                }
                setAulaBack((ant: ListaCurso) => {
                    const novosItems = [aula, ...ant.items];
                    return { ...ant, items: novosItems };
                });
                setModal(false)


            }).catch(_ => toast.error("Erro ao criar aula"))
        }

    }



    const handleButtonAtividade = (i: number = 0, add: boolean = true) => {
        if (add) {
            let c = newAtividade(aula.id)
            setAtividade((ant: Atividade[]) => [...ant, c])
        } else {
            const filter = atividade.filter((e: Atividade, j: number) => i !== j ? e : "")
            setAtividade([...filter])
        }
    }



    const handleChangeData = (e: any) => {
        if (e === null) {
            setAula((ant: AulaItem) => ({ ...ant, data_entrega: null }));
            return
        }
        setAula((ant: AulaItem) => ({ ...ant, data_entrega: new Date(e) }));
    }

    const disabledDate = (current: any) => {

        return current && current < moment().tz('America/Sao_Paulo').startOf('day');
    };

    const handleChangeDataAbertura = (e: any) => {
        if (e === null) {
            setAula((ant: AulaItem) => ({ ...ant, data_abertura: null }));
            return
        }
        setAula((ant: AulaItem) => ({ ...ant, data_abertura: new Date(e) }));
    }



    return (<>


        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
            style={{ marginBottom: "1rem" }}
        >
            {central !== true ? <>

                {editar !== true ? <>
                    <Grid item xs={curso === undefined ? 11 : 12}>
                        <div style={{ display: "flex" }}>
                            <GridSpanCenter>
                                <div className="centralizarIcone">
                                    <ArticleIcon style={{ marginRight: ".5rem" }} />
                                    <span>AULA</span>
                                </div>
                            </GridSpanCenter>
                        </div>


                        <GridAtividadeBorda cor={cor}>

                            <Grid container spacing={4}>

                                <Grid item xs={12}>
                                    <GridSpanDiv cor={cor}>
                                        <div style={{ display: "inline-block" }}>
                                            <span style={{ paddingBottom: ".3rem" }}>
                                                <AssignmentIcon style={{ marginRight: ".5rem" }} />
                                                Avaliação
                                            </span>
                                        </div>
                                    </GridSpanDiv>
                                    <GridAtividade cor={cor} style={{ marginTop: "-.1rem" }}>
                                        <Grid container
                                            direction="row"
                                            alignItems="center" spacing={2} rowSpacing={1}>
                                            <Grid item>
                                                <Button onClick={() => { setAula({ ...aula, avaliacao: 0 }); }} style={{ opacity: aula?.avaliacao === 0 ? 1 : 0.5 }} size="small" startIcon={<Quiz />} variant="contained">
                                                    Treinamento
                                                </Button>
                                            </Grid>

                                            <Grid item>
                                                <Button onClick={() => { setAula({ ...aula, avaliacao: 1 }); }} style={{ opacity: aula?.avaliacao === 1 ? 1 : 0.5 }} size="small" startIcon={<ChecklistIcon />} variant="contained">
                                                    Avaliação
                                                </Button>
                                            </Grid>

                                            <Grid item>
                                                <Button onClick={() => { setAula({ ...aula, avaliacao: 2 }); }} style={{ opacity: aula?.avaliacao === 2 ? 1 : 0.5 }} size="small" startIcon={<AddTaskIcon />} variant="contained">
                                                    Quiz
                                                </Button>
                                            </Grid>

                                        </Grid>
                                    </GridAtividade>
                                </Grid>

                                <Grid item xs={12}>
                                    <GridSpanDiv cor={cor}>
                                        <div style={{ display: "inline-block" }}>
                                            <span style={{ paddingBottom: ".3rem" }}>
                                                <EditIcon style={{ marginRight: ".5rem" }} />
                                                Nome da aula
                                            </span>
                                        </div>
                                    </GridSpanDiv>
                                    <GridAtividade cor={cor} style={{ marginTop: "-.1rem" }}>
                                        <Input status={check && aula.nome.trim() === "" ? "error" : ""} value={aula?.nome} onChange={_ => { if (aula.nome.trim().length > 0) { setCheck(true) } setAula({ ...aula, nome: _.target.value }) }} style={{ boxShadow: 'black' }} minLength={1} showCount maxLength={120} className="cor" placeholder="Nome da aula" />
                                    </GridAtividade>
                                </Grid>


                                <Grid item xs={12}>
                                    <GridSpanDiv cor={cor}>
                                        <div style={{ display: "inline-block" }}>
                                            <span style={{ paddingBottom: ".5rem" }}>
                                                <CalendarMonthIcon style={{ marginRight: ".5rem" }} />
                                                Data da entrega
                                            </span>
                                        </div>
                                    </GridSpanDiv>
                                    <GridAtividade cor={cor}>
                                        <DatePicker
                                            disabledDate={disabledDate}
                                            value={aula?.data_entrega ? dayjs(aula.data_entrega) : null}
                                            onChange={handleChangeData}
                                            format="DD/MM/YYYY"
                                            className="cor"
                                            locale={locale}
                                            style={{ width: "100%" }}
                                            dateRender={(current: any) => {
                                                const selected = current && current.isSame(aula.data_entrega, "day");
                                                const style = selected ? { backgroundColor: cor } : undefined;
                                                const todayStyle = current && current.isSame(dayjs(), "day") ? { border: `1px solid ${cor}`, borderRadius: "4px" } : undefined;

                                                return (
                                                    <div className="ant-picker-cell-inner" style={{ ...style, ...todayStyle, }}>
                                                        {current.date()}
                                                    </div>
                                                );
                                            }}
                                        />
                                    </GridAtividade >
                                </Grid>


                                <Grid item xs={12}>
                                    <GridSpanDiv cor={cor}>
                                        <div style={{ display: "inline-block" }}>
                                            <span style={{ paddingBottom: ".5rem" }}>

                                                <ArticleIcon style={{ marginRight: ".5rem" }} />
                                                Descrição
                                            </span>
                                        </div>
                                    </GridSpanDiv>
                                    <GridAtividade cor={cor}>
                                        <TextArea value={aula?.descricao} onChange={(_: ChangeEvent<HTMLTextAreaElement>) => setAula({ ...aula, descricao: _.target.value })} style={{ marginBottom: ".6rem" }} showCount maxLength={600} rows={4} className="cor" placeholder="Descrição" />
                                    </GridAtividade>
                                </Grid>


                                <Grid item xs={12} >
                                    <GridSpanDiv cor={cor}>
                                        <div style={{ display: "inline-block" }}>
                                            <span style={{ paddingBottom: ".5rem", alignItems: "flex-end" }}>

                                                <StarBorderIcon style={{ marginRight: ".5rem" }} />
                                                Pontuação
                                            </span>
                                        </div>
                                    </GridSpanDiv>
                                    <GridAtividade cor={cor}>
                                        <InputNumber status={aula?.pontuacao === null ? "error" : ""} value={aula.pontuacao} onChange={(_: number | null) => { setAula({ ...aula, pontuacao: _ }) }} className="cor" placeholder="Pontuação" min={0} max={100} onBlur={(e: any) => {
                                            e.target.value = formatarNumero(e.target.value.replace(",", "."), 2);
                                        }} style={{ width: "100%" }} />
                                    </GridAtividade>
                                </Grid>

                                {!trilha ?
                                    <Grid item xs={12}>
                                        <GridSpanDiv cor={cor}>
                                            <div style={{ display: "inline-block" }}>
                                                <span style={{ paddingBottom: ".5rem", alignItems: "flex-end" }}>

                                                    <AccessTimeIcon style={{ marginRight: ".5rem" }} />
                                                    Status da aula
                                                </span>
                                            </div>
                                        </GridSpanDiv>


                                        <GridAtividade cor={cor}>
                                            <Grid container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center" spacing={2}>
                                                <Grid item xs={12}>
                                                    <Select
                                                        labelInValue

                                                        style={{ width: "100%" }}

                                                        value={aula?.status} onChange={(_: any) => setAula({ ...aula, status: _.value })}

                                                        options={[
                                                            {
                                                                value: true,
                                                                label: 'Aberto',
                                                            },
                                                            {
                                                                value: false,
                                                                label: 'Fechado',
                                                            },
                                                        ]}
                                                    />
                                                </Grid>
                                                {aula.status === false || aula.data_abertura ?
                                                    <>

                                                        <Grid item xs={true}>
                                                            <DatePicker
                                                                disabledDate={disabledDate}
                                                                value={aula.data_abertura ? dayjs(aula.data_abertura) : null}
                                                                onChange={handleChangeDataAbertura}
                                                                format="DD/MM/YYYY"
                                                                className="cor"
                                                                locale={locale}
                                                                disabled={!data}
                                                                style={{ width: "100%" }}
                                                                dateRender={(current: any) => {
                                                                    const selected = current && current.isSame(aula.data_abertura, "day");
                                                                    const style = selected ? { backgroundColor: cor } : undefined;
                                                                    const todayStyle = current && current.isSame(dayjs(), "day") ? { border: `1px solid ${cor}`, borderRadius: "4px" } : undefined;

                                                                    return (
                                                                        <div className="ant-picker-cell-inner" style={{ ...style, ...todayStyle, }}>
                                                                            {current.date()}
                                                                        </div>
                                                                    );
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <span>Abertura automática?</span> <Checkbox checked={data} onChange={_ => { setData(!data) }}></Checkbox>
                                                        </Grid>

                                                    </> : ""
                                                }
                                            </Grid>
                                        </GridAtividade>

                                    </Grid>

                                    : ""}
                                <Grid item xs={12}>
                                    <GridSpanDiv cor={cor}>
                                        <div style={{ display: "inline-block" }}>
                                            <span style={{ paddingBottom: ".5rem" }}>

                                                <LinkIcon style={{ marginRight: ".5rem" }} />
                                                Link da aula
                                            </span>
                                        </div>
                                    </GridSpanDiv>

                                    <GridAtividades cor={cor}>
                                        <Input status={aula.link.trim() === "" || validateLink(aula.link) ? "error" : ""} value={typeof (aula.link) === 'string' ? aula.link : ""} onChange={(_: ChangeEvent<HTMLInputElement>) => setAula({ ...aula, link: _.target.value })} maxLength={200} className="cor" placeholder="Link da aula" />
                                        <Grid item xs={12}>

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Grid item xs={5}>
                                                    <div className="url" style={{ marginTop: "1rem" }}>

                                                        <iframe allow="fullscreen" className="responsive-iframe" title="Atividade" src={(convertToEmbedUrl(aula?.link) || aula?.link)} />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </GridAtividades>
                                </Grid>




                            </Grid>
                        </GridAtividadeBorda>


                    </Grid>

                    <Grid item xs={curso === undefined ? 11 : 12}>
                        <div style={{ display: "flex" }}>
                            <GridSpanCenter>
                                <div className="centralizarIcone" style={{ alignItems: "flex-end" }}>
                                    <BorderColorIcon style={{ marginRight: ".5rem" }} />
                                    <span>ATIVIDADES</span>
                                </div>
                            </GridSpanCenter>
                        </div>




                        <GridAtividadeBorda cor={cor}>


                            {GridContainer(atividade, setAtividade, cor, turma, aula, trilha)}

                            <Grid container
                                direction="row" justifyContent="center"
                                alignItems="center" spacing={2} style={{ marginTop: "1rem" }}>
                                <Grid item>
                                    <Button className="opacidade" onClick={() => { handleButtonAtividade(atividade.length - 1) }} style={{ backgroundColor: "#0048b6", color: "white" }}>
                                        Adicionar Atividade
                                    </Button>
                                </Grid>

                            </Grid>

                        </GridAtividadeBorda>



                    </Grid>
                </> : ""}
                <Grid item xs={12}> <div style={{ display: "flex", justifyContent: "center", marginTop: "-.5rem", marginBottom: "1rem" }}>
                    <Button className="opacidade"
                        style={{ backgroundColor: "#0048b6", color: "white", }} onClick={postApi}> Publicar</Button></div>
                </Grid>
            </> :
                <Grid item xs={12}> <div style={{ display: "flex", justifyContent: "center", marginTop: "-.5rem", marginBottom: "-1rem" }}>
                    <Button className="opacidade"
                        style={{ backgroundColor: "#0048b6", color: "white", }} onClick={postApi}> Publicar</Button></div>
                </Grid>
            }

        </Grid >


    </>)
}

export default Aula_Criar;

