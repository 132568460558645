import { FormControl, Grid } from "@mui/material";
import { Input, Button } from "antd";
import { useState } from "react";
import api from "../../axios/axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Pessoa as Pessoas } from "../types";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function Pessoa({ turma, setTurma, cor, setModal, decodedToken }: any) {
    const [pessoa, setPessoa] = useState("")
    const { uuid_turma } = useParams();
    const [tipo, setTipo] = useState('Aluno');

    const handleChange = (event: SelectChangeEvent) => {
        setTipo(event.target.value);
    };

    const handleClick = () => {
        let inserir = { "id_keycloak": pessoa, tipo: tipo.toLowerCase() };
        let existe = turma.pessoas.filter((_: Pessoas) => _.id_keycloak === inserir.id_keycloak);
        if (existe.length > 0) {
            toast.error("Pessoa já existe")
        }
        if(pessoa.trim() == decodedToken?.preferred_username){
            toast.error("Pessoa não inserida")
            return
        }
        else if (pessoa.trim() === "") {
            toast.error("Pessoa não inserida")
            return
        }
        else {
            toast.info("Inserindo pessoa");
            api.post(`pessoa/${uuid_turma}`, JSON.stringify(inserir)).then((r) => {
                toast.success("Pessoa inserida");
                setModal(false);
                let novaPessoa = r.data;
                novaPessoa.tipo = [{ tipo: tipo }]

                const novoArrayAlunos = [...turma.pessoas, novaPessoa];
                novoArrayAlunos.sort((a, b) => a.nome.localeCompare(b.nome));
                setTurma({ ...turma, pessoas: novoArrayAlunos });
                setPessoa("");

            }
            ).catch(() => toast.error("Pessoa não inserida"))
        }


    }

    return (<>
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"

                >
                    <Grid item>
                        <p style={{ fontWeight: "bold", fontSize: "19px" }}>Inserir pessoa</p>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item>
                        <p>Digite uma matrícula para inserir uma pessoa dentro da turma.</p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"

                >
                    <Grid item xs={8}>
                        <Input value={pessoa} onChange={_ => setPessoa(_.target.value)} minLength={1} showCount maxLength={60} placeholder="Pessoa" />
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"

                >
                    <Grid item xs={8}>
                        <FormControl size="small" style={{ width: "100%" }}>
                            <InputLabel id="demo-select-small-label">Tipo</InputLabel>
                            {decodedToken?.realm_access?.roles.includes('admin') || decodedToken?.realm_access?.roles.includes('coordenador') || decodedToken?.realm_access?.roles.includes('diretor') ?
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={tipo}
                                    label="Tipo"
                                    onChange={handleChange}
                                >

                                    <MenuItem value={"Aluno"}>Aluno</MenuItem>
                                    <MenuItem value={"Professor"}>Professor</MenuItem>
                                </Select>
                                :
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={tipo}
                                    label="Tipo"
                                    onChange={handleChange}
                                >

                                    <MenuItem value={"Aluno"}>Aluno</MenuItem>
                                </Select>
                            }



                        </FormControl>
                    </Grid>
                </Grid>

            </Grid>



            <Grid item></Grid>
            <Grid item>
                <Button onClick={handleClick} className="opacidade" style={{ textTransform: "capitalize", border: "0px", backgroundColor: cor, color: "white", paddingLeft: "2rem", paddingRight: "2rem" }}>
                    Inserir
                </Button>
            </Grid>
        </Grid >
    </>)
}

export default Pessoa;
