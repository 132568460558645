import { Grid } from "@mui/material";
import "../index.css"
import { Button } from '@mui/material';
import { PlusOutlined } from '@ant-design/icons';
import FundoTurma from "./fundo";
import { memo, useState } from "react";
import Selecao from "./selecao";
import ButtonSair from "../sair"
import { CorButton } from "../cor";
import { Modal } from "antd";
import Curso from "../curso";
import Pessoa from "../pessoa";
import { getContratos } from "../types";



function MenuTurma({ trilha = false, turma, setTurma, setTela, professor, cor, turmaNome, selecionado, setCurso, curso, editar, editarNovoCurso, central, decodedToken }: any) {

    const [modal, setModal] = useState(false);

    return (<>
        <Grid item xs={12}>
            <FundoTurma cor={cor} turmaNome={turmaNome} />
        </Grid>

        <Grid item xs={12}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                rowSpacing={2}
                spacing={1} >
                {/* <Grid item xs={12} sm={4} zeroMinWidth>
                    <Selecao cor={cor} menu="MURAL" selecionado={selecionado} />
                </Grid> */}
                <Grid item xs={12} sm={4} zeroMinWidth>
                    <Selecao setTela={setTela} cor={cor} menu="CURSOS" selecionado={selecionado} />
                </Grid>
                <Grid item xs={12} sm={4} zeroMinWidth>
                    <Selecao setTela={setTela} cor={cor} menu="TRILHAS" selecionado={selecionado} />
                </Grid>
                <Grid item xs={12} sm={4} zeroMinWidth>
                    <Selecao setTela={setTela} cor={cor} menu="PESSOAS" selecionado={selecionado} />
                </Grid>
            </Grid>
        </Grid >

        <Grid item xs={12} >
            <Grid container direction="row" justifyContent="space-between" spacing={2}>
                {selecionado === "CURSOS" ?
                    <Grid item>
                        {((((turma?.tipo === 'professor' || turma?.tipo === 'coordenador' || turma?.tipo === 'diretor') && getContratos(decodedToken)) || decodedToken?.realm_access?.roles.includes('admin') || (central || professor) && window.location.href.includes('turma')) && turma?.tipo !== 'aluno') && ((turma?.tipo !== 'aluno' && getContratos(decodedToken))) ?
                            <CorButton cor={cor}>
                                <Button startIcon={<PlusOutlined />} style={{ paddingLeft: "1rem", paddingRight: "1.5rem" }} size="small" onClick={() => setModal(true)}>
                                    Novo curso
                                </Button>
                                <Modal
                                    width="100%"
                                    centered
                                    footer={null}
                                    open={modal}

                                    onCancel={() => setModal(false)}
                                >
                                    <Curso trilha={trilha} modal={modal} setCursos={setCurso} cursos={curso} setModal={setModal} editar={editar} editarNovoCurso={editarNovoCurso} central={central} turma={turma} />

                                </Modal>
                            </CorButton>
                            : ""}

                    </Grid>
                    : ""}
                {selecionado === "PESSOAS" ?
                    <Grid item>
                        {(decodedToken?.realm_access?.roles.includes('admin') || (turma?.tipo === 'professor' || turma?.tipo === 'coordenador' || turma?.tipo === 'diretor') && getContratos(decodedToken)) && ((turma?.tipo !== 'aluno' && getContratos(decodedToken))) ?
                            <CorButton cor={cor}>
                                <Button startIcon={<PlusOutlined />} style={{ paddingLeft: "1rem", paddingRight: "1.5rem" }} size="small" onClick={() => setModal(true)}>
                                    Inserir
                                </Button>
                                <Modal
                                    width="100%"
                                    centered
                                    footer={null}
                                    open={modal}

                                    onCancel={() => setModal(false)}
                                >
                                    <Pessoa decodedToken={decodedToken} turma={turma} setTurma={setTurma} cor={cor} setModal={setModal} />

                                </Modal>
                            </CorButton>
                            : ""}

                    </Grid>
                    : ""}
                {selecionado === "TRILHAS" ?
                    <Grid item>
                        {((((turma?.tipo === 'professor' || turma?.tipo === 'coordenador' || turma?.tipo === 'diretor') && getContratos(decodedToken)) || decodedToken?.realm_access?.roles.includes('admin') || (central || professor) && window.location.href.includes('turma')) && turma?.tipo !== 'aluno') && ((turma?.tipo !== 'aluno' && getContratos(decodedToken))) ?
                            <CorButton cor={cor}>
                                <Button startIcon={<PlusOutlined />} style={{ paddingLeft: "1rem", paddingRight: "1.5rem" }} size="small" onClick={() => setModal(true)}>
                                    Nova trilha
                                </Button>
                                <Modal
                                    width="100%"
                                    centered
                                    footer={null}
                                    open={modal}

                                    onCancel={() => setModal(false)}
                                >
                                    <Curso trilha={true} modal={modal} setCursos={setCurso} cursos={curso} setModal={setModal} editar={editar} editarNovoCurso={editarNovoCurso} central={central} turma={turma} />

                                </Modal>
                            </CorButton>
                            : ""}

                    </Grid>
                    : ""}

                <Grid item>
                    <ButtonSair cor={cor} texto={"Voltar"} />
                </Grid>
            </Grid>

        </Grid>

    </>)
}

export default memo(MenuTurma);