import { Autocomplete, Button, Container, Grid, IconButton, Paper, TextField } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from "react";
import EventIcon from '@mui/icons-material/Event';
import { CorButton } from "../cor";
import { useNavigate } from "react-router";
import { toast } from 'react-toastify';
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';
import api from "../../axios/axios";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Lottie from "react-lottie";
import { lottieDefaultOptions } from "../types";
import carregando from '../../img/lotties/98432-loading.json';
import Erro from "../erro";
import React from "react";
import SchoolIcon from '@mui/icons-material/School';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as uuid from "uuid";

const useStyles = makeStyles((btnVoltar) => ({
    root: {
        minHeight: '75vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        padding: "2.5rem",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paper2: {
        padding: "0rem",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: 1,
    },
    submit: {
        marginTop: "2rem",
    },
}));

function Cadastro({ professor, decodedToken, btnVoltar, pessoas, editarPessoa, setIsModalOpen, setEditarPessoa, setPessoas }: any) {

    const classes = useStyles(btnVoltar);
    const navigate = useNavigate()
    const [perfil, setPerfil] = useState<any>(null);
    const [escola, setEscola] = useState<any>({});
    const [contrato, setContrato] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [erro, setErro] = useState(false);
    const [escolasOpcoes, setEscolasOpcoes] = React.useState<any>({ 1: [{ id: -1, nome: "Nenhuma escola" }] });
    const [contratoOpcoes, setContratoOpcoes] = React.useState<any[]>([{ id: 1, nome: "Nenhum contrato" }]);
    const [mostrarSenha, setMostrarSenha] = useState(false);
    const [mostrarSenhaTwo, setMostrarSenhaTwo] = useState(false);
    const [textoContrato, setTextoContrato] = useState("");
    const [textoEscola, setTextoEscola] = useState("");

    let data = null;
    if (editarPessoa) {
        try {
            const novaData = editarPessoa?.attributes?.data_nascimento[0].split("/");
            data = (`${novaData[2]}-${novaData[1]}-${novaData[0]}`);
        }
        catch {

        }
    }



    useEffect(() => {
        if (editarPessoa) {
            Promise.all([
                api.get(`/contrato`),
                api.get("/escola"),
            ]).then(([r, r2]) => {


                let contratosExistem = null;
                let contratosLower = editarPessoa?.attributes?.contratos.map((e: any) => e.toLowerCase())
                r.data?.filter((c: any) => contratosLower?.includes(c.nome) ? contratosExistem = `${c.nome} - ${c.id}` : null)

                setContrato(contratosExistem);
                setContratoOpcoes(r.data);
                let dict: Record<string, any> = {};
                r.data.forEach((e: any) => dict[e.id] = []);
                r2.data.forEach((e: any) => dict[e.id_contrato].push(e))
                setEscolasOpcoes(dict);
                if (r2.data.length > 0)
                    return api.get(`/escola/tipo/${editarPessoa.username}`).then((r) => {
                        if (r.data.length > 0) {
                            setPerfil(`${r.data[0].tipo.charAt(0).toUpperCase()}${r.data[0].tipo.slice(1)}`)

                            let escolasExistem: any[] = [];
                            r2.data?.map((c: any) => r?.data.filter((esc: any) => esc.id_escola == c.id ? escolasExistem.push(c) : null))
                            const dic: Record<string, any> = {};
                            escolasExistem.forEach((e: any) => { dic[e.id_contrato] === undefined ? dic[e.id_contrato] = [`${e.nome} - ${e.id}`] : dic[e.id_contrato].push(`${e.nome} - ${e.id}`) })

                            setEscola(dic);

                        }
                    });
            }).then(e => { setLoading(false) }).catch(e => { setErro(true); })
        }
        else {
            Promise.all([
                api.get(`/contrato`),
                api.get("/escola"),
            ]).then(([r, r2]) => {
                setContratoOpcoes(r.data);
                const dict: Record<string, any> = {};
                r.data.forEach((e: any) => dict[e.id] = []);
                let cto: any = {};
                r2.data.forEach((e: any) => { cto = e; dict[e.id_contrato].push(e) })
                setEscolasOpcoes(dict);
                if (decodedToken?.realm_access?.roles.includes('diretor') && cto?.id_contrato) {

                    setContrato(`${decodedToken.contratos?.[0] ?? ''} - ${cto.id_contrato}`)

                }
            }).then(e => setLoading(false)).catch(e => setErro(true))
        }

    }, [])


    const [formulario, setFormulario] = useState({
        nome: editarPessoa ? `${editarPessoa?.firstName} ${editarPessoa?.lastName}`.trim() : "",
        email: editarPessoa && editarPessoa?.email ? `${editarPessoa?.email}`.trim() : "",
        senha: '',
        confirmSenha: '',
        dataNascimento: editarPessoa ? data : null,
        expiracaoAcesso: editarPessoa && editarPessoa?.attributes?.expiracaoAcesso?.length > 0 ? editarPessoa?.attributes?.expiracaoAcesso[0] : null,
        matricula: editarPessoa ? `${editarPessoa?.username}`.trim() : "",
        perfil: '',
        contrato: {} || null,
        escolas: [],
        limite_criacao: editarPessoa && editarPessoa?.attributes?.limite_criacao?.length > 0 ? +editarPessoa?.attributes?.limite_criacao[0] : null || Number
    });

    const ativarescola: boolean = (() => {
        try {
            if (contrato.split(' - ')[1] !== undefined) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    })();




    if (erro)
        return <><Erro /></>

    if (loading) {
        return (<>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "70vh" }}
            >
                <Lottie
                    options={lottieDefaultOptions(carregando)}
                    height={"40%"}
                    width={"40%"}
                />
            </Grid>
        </>
        )
    }


    const trocarStatusSenha = () => {
        setMostrarSenha(!mostrarSenha);
    };

    const trocarStatusSenhaTwo = () => {
        setMostrarSenhaTwo(!mostrarSenhaTwo);
    };

    const handleChangeContrato = (event: any, value: any) => {
        if (value && escola[value.split(' - ')[1]] === undefined) {
            if (value && escolasOpcoes[value.split(' - ')[1]] === undefined)
                setEscolasOpcoes((prevEscolasOpcoes: any) => ({
                    ...prevEscolasOpcoes,
                    [value.split(' - ')[1]]: [],
                }));

            setEscola((prevEscolasOpcoes: any) => ({
                ...prevEscolasOpcoes,
                [value.split(' - ')[1]]: []
            }));

        }

        setContrato(value);
    };

    const handleChangeEscola = (event: any, value: any) => {
        setEscola((prevEscolasOpcoes: any) => ({
            ...prevEscolasOpcoes,
            [contrato.split(' - ')[1]]: value
        }));
    };

    const handleChangePerfil = (event: any, value: any) => {
        setPerfil(value);
    };

    let perfilOpcoes = []
    if (decodedToken?.realm_access?.roles.includes('admin')) {
        perfilOpcoes = [
            'Aluno',
            'Professor',
            'Coordenador',
            'Diretor'
        ];
    }
    else if (decodedToken?.realm_access?.roles.includes('diretor')) {
        perfilOpcoes = [
            'Aluno',
            'Professor',
            'Coordenador',
        ];
    }
    else if (decodedToken?.realm_access?.roles.includes('coordenador')) {
        perfilOpcoes = [
            'Aluno',
            'Professor',
        ];
    }
    else {
        perfilOpcoes = [
            'Aluno'
        ];
    }

    function isValidEmail(email: any) {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    }

    const regex = /([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;

    const getUuind = (str: any) => {

        if (str === undefined || str === null || str === "" || str.length === 0)
            return null

        const match = str.match(regex);


        if (match) {
            return { id: match[1], nome: str.split(` - ${match[1]}`)[0] };
        } else {
            return null;
        }
    }


    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (editarPessoa === undefined && formulario.senha !== formulario.confirmSenha) {
            toast.error('A senha e a confirmação de senha não coincidem.');
        }
        else if (!isValidEmail(formulario.email)) {
            toast.error('Digite um e-mail valido');
        }
        else {
            if (editarPessoa)
                toast.info('Editando usuário');
            else
                toast.info('Cadastrando usuário');

            const form = ({
                ...formulario,
                contrato: getUuind(contrato) || null,
                perfil: perfil.toLowerCase(),
                escolas: escola[contrato.split(' - ')[1]].map((e: string) => getUuind(e)) as never[],
                senha: formulario.senha.trim() === "" ? null : formulario.senha,
                confirmSenha: formulario.confirmSenha.trim() === "" ? null : formulario.confirmSenha
            });


            if (editarPessoa)
                api.patch(`/cadastro/${editarPessoa.username}`, form).then(e => {
                    setIsModalOpen(false);
                    setPessoas({ ...pessoas, items: pessoas.items.map((p: any) => p.id !== e.data.id ? p : e?.data) });
                    setEditarPessoa({ "username": null })

                    toast.success("Usuário editado")
                }).catch((e: any) => {
                    if (e.response.status === 403)
                        toast.error(e.response.data.detail)
                    else if (form.senha && form.senha.length < 8)
                        toast.error("Escolha uma senha mais forte")
                    else
                        toast.error("Usuário não editado")
                })
            else
                api.post('/cadastro', form).then((e: any) => {
                    toast.success("Usuário cadastrado")
                    const resetState = Object.keys(formulario).reduce((acc: any, key: any) => {
                        acc[key] = null || '';
                        return acc;
                    }, {});

                    setFormulario(resetState);
                    setContrato("");
                    setEscola("");
                    setPerfil("");


                }).catch((e: any) => {
                    if (e.response.status === 403)
                        toast.error(e.response.data.detail)
                    else if (form.senha && form.senha.length < 8)
                        toast.error("Escolha uma senha mais forte")
                    else
                        toast.error("Usuário não cadastrado")
                })
        }
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormulario({
            ...formulario,
            [name]: value,
        });
    };

    const handleInputChange = (event: any, newInputValue: any) => {

        if (newInputValue.trim() !== '' && !escolasOpcoes[contrato.split(' - ')[1]].some((option: any) => option.nome === newInputValue)) {

            const novaEscola = {
                id: uuid.v4(),
                nome: newInputValue,
            };

            setEscolasOpcoes((prevEscolasOpcoes: any) => ({
                ...prevEscolasOpcoes,
                [contrato.split(' - ')[1]]: [...(prevEscolasOpcoes[contrato.split(' - ')[1]]), novaEscola],
            }));
        }
    };

    const handleInputChangeContrato = (event: any, newInputValue: any) => {

        if (newInputValue.trim() !== '' && !contratoOpcoes.some(option => option.nome === newInputValue)) {

            const novoContrato = {
                id: uuid.v4(),
                nome: newInputValue.toLowerCase(),
            };

            setContratoOpcoes([...contratoOpcoes, novoContrato]);

        }
    };

    return (
        <> {btnVoltar !== false ?
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginBottom: "1rem" }}>
                <Grid item>
                    <CorButton cor="#1976d2">
                        <Button onClick={() => navigate("/")} size="small" >
                            Voltar
                        </Button>
                    </CorButton>
                </Grid>
            </div>
            : ""}
            <Container component="main" maxWidth="xs">
                <Paper elevation={btnVoltar !== false ? 3 : 0} className={btnVoltar !== false ? classes.paper : classes.paper2}>
                    <AccountCircleIcon style={{ fontSize: 60, color: "#1976d2", marginBottom: "1rem" }} />
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="matricula"
                                    label="Matrícula"
                                    name="matricula"
                                    autoComplete="matricula"
                                    placeholder="Matrícula"
                                    value={formulario.matricula}
                                    InputProps={{
                                        startAdornment: (
                                            <PersonIcon color="action" />
                                        ),
                                    }}
                                    onChange={handleChange}
                                    disabled={editarPessoa ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    noOptionsText={'Perfil não existe'}
                                    options={perfilOpcoes}
                                    onChange={handleChangePerfil}
                                    value={perfil}
                                    multiple={false}

                                    renderInput={params => {
                                        return (
                                            <TextField
                                                {...params}
                                                label="Perfil"
                                                placeholder="Perfil"
                                                fullWidth
                                                required={perfil === null ? true : false}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <>
                                                            <ManageAccountsIcon color="action" />
                                                            {params.InputProps.startAdornment}
                                                        </>
                                                    )
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="nome"
                                    label="Nome completo"
                                    name="nome"
                                    placeholder="Nome completo"
                                    autoComplete="nome"
                                    value={formulario.nome}
                                    InputProps={{
                                        startAdornment: (
                                            <AccountCircleIcon color="action" />
                                        ),
                                    }}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Endereço de Email"
                                    name="email"
                                    autoComplete="email"
                                    placeholder="E-mail"
                                    value={formulario.email}
                                    InputProps={{
                                        startAdornment: (
                                            <MailOutlineIcon color="action" />
                                        ),
                                    }}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="dataNascimento"
                                    name="dataNascimento"
                                    label="Data de Nascimento"
                                    type="date"
                                    value={formulario.dataNascimento}
                                    InputProps={{
                                        startAdornment: (
                                            <EventIcon color="action" />
                                        ),

                                    }}
                                    onChange={handleChange}
                                />
                            </Grid>
                            {decodedToken?.realm_access?.roles.includes('admin') ?
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="expiracaoAcesso"
                                        name="expiracaoAcesso"
                                        label="Data de Expiração"
                                        type="date"
                                        value={formulario.expiracaoAcesso}
                                        InputProps={{
                                            startAdornment: (
                                                <EventIcon color="action" />
                                            ),

                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                : ""}
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required={editarPessoa ? false : true}
                                    fullWidth
                                    name="senha"
                                    label="Senha"
                                    type={mostrarSenha ? 'text' : 'password'}
                                    id="senha"
                                    autoComplete="new-password"
                                    placeholder="Senha"
                                    value={formulario.senha}
                                    InputProps={{
                                        startAdornment: (
                                            <LockOutlinedIcon color="action" />
                                        ),
                                        endAdornment: (

                                            <IconButton onClick={trocarStatusSenha}>
                                                {mostrarSenha ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>

                                        ),
                                    }}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required={editarPessoa ? false : true}
                                    fullWidth
                                    name="confirmSenha"
                                    label="Confirmar Senha"
                                    type={mostrarSenhaTwo ? 'text' : 'password'}
                                    id="confirmSenha"
                                    autoComplete="new-password"
                                    placeholder="Senha"
                                    value={formulario.confirmSenha}
                                    InputProps={{
                                        startAdornment: (
                                            <LockOutlinedIcon color="action" />
                                        ),
                                        endAdornment: (

                                            <IconButton onClick={trocarStatusSenhaTwo}>
                                                {mostrarSenhaTwo ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>

                                        ),
                                    }}
                                    onChange={handleChange}
                                />
                            </Grid>
                            {decodedToken?.realm_access?.roles.includes('admin') ?

                                <>
                                    {perfil && perfil.toLowerCase() === "diretor" ?

                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"

                                                fullWidth
                                                id="limite_criacao"
                                                label="Limite de criação"
                                                name="limite_criacao"
                                                autoComplete="limite_criacao"
                                                placeholder="Limite de criação"
                                                type="number"
                                                value={formulario.limite_criacao}
                                                inputProps={{ min: 0 }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <PersonIcon color="action" />
                                                    ),
                                                }}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        : ""}

                                    < Grid item xs={12}>
                                        <Autocomplete


                                            noOptionsText={'Contrato não existe'}
                                            options={contratoOpcoes.map((e: any) => `${e.nome} - ${e.id}`)}
                                            onChange={handleChangeContrato}
                                            value={contrato}

                                            renderInput={params => {
                                                return (
                                                    <TextField
                                                        {...params}
                                                        label="Contrato"
                                                        placeholder="Contrato"
                                                        fullWidth
                                                        value={textoContrato}
                                                        onChange={(e) => setTextoContrato(e.target.value)}
                                                        onKeyDown={(ev) => {

                                                            if (ev.key === 'Enter') {
                                                                handleInputChangeContrato(ev, textoContrato.toLowerCase());
                                                                ev.preventDefault();
                                                            }
                                                        }}
                                                        required
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startAdornment: (
                                                                <>
                                                                    <ArticleIcon color="action" />
                                                                    {params.InputProps.startAdornment}
                                                                </>
                                                            )
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </>
                                : ""}
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple


                                    componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                                    noOptionsText="Escola não existe"
                                    options={ativarescola ? escolasOpcoes?.[contrato.split(' - ')[1]]?.map((e: any) => `${e?.nome} - ${e?.id}`) : []}
                                    onChange={handleChangeEscola}
                                    value={ativarescola && escola[contrato.split(' - ')[1]] !== undefined ? escola[contrato.split(' - ')[1]] : []}
                                    disabled={ativarescola ? false : true}
                                    renderInput={params => {
                                        return (
                                            <TextField
                                                {...params}
                                                label={"Escolas"}
                                                placeholder="Escolas"
                                                fullWidth
                                                value={textoEscola}
                                                onChange={(e) => setTextoEscola(e.target.value)}
                                                onKeyDown={(ev) => {

                                                    if (ev.key === 'Enter') {
                                                        handleInputChange(ev, textoEscola);
                                                        ev.preventDefault();
                                                    }
                                                }}

                                                required={ativarescola && escola[contrato.split(' - ')[1]] !== undefined && escola[contrato.split(' - ')[1]].length > 0 ? false : true}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            alignItems="flex-start"
                                                        >
                                                            <SchoolIcon color="action" />
                                                            <Grid item style={{ maxWidth: "100%" }}>
                                                                {params.InputProps.startAdornment}
                                                            </Grid>
                                                        </Grid>
                                                    ),
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </Grid>

                        </Grid>
                        {btnVoltar !== false ?
                            <Button
                                style={{ marginTop: "1rem" }}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Cadastrar
                            </Button>
                            :
                            <Button
                                style={{ marginTop: "1rem", marginBottom: "-1rem" }}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}

                            >
                                Atualizar
                            </Button>
                        }

                    </form>
                </Paper>
            </Container >
            <div style={{ marginTop: "3rem" }}></div>
        </>

    )
}

export default Cadastro;