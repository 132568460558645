import Grid from "@mui/material/Grid";
import Paginacao from "../paginacao";
import { Modal, Space } from "antd";
import api from "../../axios/axios";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import BookIcon from '@mui/icons-material/Book';
import Search from "antd/es/transfer/search";
import AddIcon from '@mui/icons-material/Add';
import ButtonSair from "../sair";
import { toast } from "react-toastify";
import { Button as ButtonAntd } from 'antd';
import Curso from "../curso";
import LongMenu from '../turma/menu'
import { CursoItem, ListaCurso, getContratos, lottieDefaultOptions } from "../types";
import Erro from "../erro";
import Lottie from "react-lottie";
import carregando from '../../img/lotties/98432-loading.json';

function Cursos({ voltar = true, setCursos = false, decodedToken, setModalBack, central }: any) {
    const user = decodedToken?.preferred_username;
    const [curso, setCurso] = useState<ListaCurso>(new ListaCurso());
    const [buscar, setBuscar] = useState("");
    const { uuid_turma } = useParams();
    const [loading, setLoading] = useState(true);
    const cor = "#0048b6"
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [item, setItem] = useState<CursoItem>(new CursoItem());
    const [modal, setModal] = useState(false);
    const [erro, setErro] = useState(false);
    const [atribuirLoading, setAtribuir] = useState(false);
    const [pessoa, setPessoa] = useState<any>({});

    const handleClickOpen = (item: CursoItem) => {
        setItem(item)
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (loading === false && buscar !== undefined) {
            const delay = setTimeout(() => {
                buscarAPI();
            }, 2000);

            return () => clearTimeout(delay);
        }

    }, [buscar]);

    const buscarPaginacao = async (pagina: number = 1, buscar_palavra: boolean = false) => {
        try {
            let cursoResponse;
            if (buscar_palavra)
                cursoResponse = await api.get(`/curso/buscar/${buscar}?page=${pagina}&size=${curso.size}`);
            else
                cursoResponse = await api.get(`/curso?page=${pagina}&size=${curso.size}`);

            let cursoData = cursoResponse.data;

            const aulaPromises = cursoData.items.map((item: CursoItem) =>
                api.get(`/aula/list/${uuid_turma}/${item.id}`).then((r) => r.data)
            );
            const aulaResponses = await Promise.all(aulaPromises);

            cursoData.items = cursoData.items.map((item: CursoItem, index: number) => ({
                ...item,
                aulas: aulaResponses[index].total,
            }));


            setCurso(cursoData)

        } catch (error) {
            setErro(true)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {

        api.get('pessoa/username').then((r) => setPessoa(r.data)).catch((e) => setErro(true))

        buscarPaginacao(1, false);
    }, []);

    if (erro)
        return <><Erro /></>


    const buscarAPI = () => {
        if (buscar !== undefined && buscar.trim() !== "")

            buscarPaginacao(1, true)
        else
            buscarPaginacao(1);
    }



    const handlePage = (pagina: number) => {
        setLoading(true)
        buscarPaginacao(pagina)
    }

    const atribuir = (ite: CursoItem) => {
        toast.info("Atribuindo curso")
        const { aulas, id_origem, ...item } = ite;
        setAtribuir(true);

        api.post(`/curso/atribuir/${uuid_turma}`, JSON.stringify(item)).then((_: any) => {

            api.get(`/aula/list/${uuid_turma}/${item.id}`).then((r) => {
                _.data.aulas = r.data.total

                if (setCursos !== false) {
                    setCursos((curso: ListaCurso) => ({
                        ...curso,
                        items: [_.data, ...curso.items]
                    }));
                } toast.success("Curso atribuido");
                setAtribuir(false);
            })

        }).catch(_ => {
            toast.error("Erro ao atribuir curso");
            setAtribuir(false);
        })
    }

    const sair = () => {
        navigate(`/`)
    }

    const handleBuscar = (e: any) => {
        setBuscar(e.target.value)
    }

    const deleteAPI = (turmaIte: CursoItem) => {
        toast.info("Deletando curso");
        api.delete("/curso/" + turmaIte.id).then(_ => {
            toast.success("Curso deletado")
            setCurso((curso: ListaCurso) => ({
                ...curso,
                items: curso.items.filter((tu: CursoItem) => tu.id !== turmaIte.id)
            }));

        }).catch(_ => toast.error("Erro ao deletar curso"))
    }




    if (loading)
        return (<>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "70vh" }}
            >
                <Lottie
                    options={lottieDefaultOptions(carregando)}
                    height={"40%"}
                    width={"40%"}
                />
            </Grid>
        </>)



    return (<>
        <div className="divHeader">
            <div>
                <div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {`Tem certeza que deseja realmente atribuir?`}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <ButtonAntd type="primary" onClick={handleClose}>Não</ButtonAntd>
                            <ButtonAntd type="primary" danger onClick={_ => {
                                handleClose();
                                atribuir(item)
                            }} autoFocus>
                                Sim
                            </ButtonAntd>

                        </DialogActions>
                    </Dialog>
                </div>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={2}
                    spacing={1}
                >

                    <Grid item>
                        <div style={{ margin: "0", fontWeight: "bold", color: "#0048B6", textAlign: "center" }}>
                            <div style={{ border: "1px solid #0048B6", borderRadius: "50%", width: "50px", height: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <BookIcon fontSize="large" />
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <p style={{ margin: "0px", fontWeight: "bold", color: "#0048B6", textAlign: "center" }}>CENTRAL DE CURSOS</p>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        {voltar === true ? < ButtonSair cor={cor} uuid_turma={""} texto={"Voltar"} sair={sair} /> : ""}
                        {window.location.href.includes('centralcursos') ? "" : <Button variant="outlined" className="opacidade" onClick={_ => setModalBack(false)} style={{ background: cor, color: "white" }}>Sair</Button>}
                    </Grid>
                    <Grid item xs={11} style={{ marginTop: "1rem" }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item >
                                <Space direction="vertical">
                                    <Search value={buscar}
                                        onChange={handleBuscar}
                                        placeholder="Pesquisar..." />
                                </Space>
                            </Grid>
                            <Grid item>

                                {decodedToken?.realm_access?.roles.includes('admin') || voltar === true && getContratos(decodedToken) ?
                                    <>
                                        <Button onClick={_ => setModal(true)} size="small" startIcon={<AddIcon />} variant="outlined">
                                            Criar Curso
                                        </Button>
                                        <Modal
                                            width="100%"
                                            centered
                                            footer={null}
                                            open={modal}

                                            onCancel={() => setModal(false)}
                                        >
                                            {<Curso modal={modal} setCursos={setCurso} cursos={curso} setModal={setModal} editar={false} central={central} user={user} />}

                                        </Modal>
                                    </>

                                    : ""}
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={11} style={{ marginTop: "1.5rem" }}>
                        <Grid container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center" spacing={2} >
                            {curso.items.map((item: CursoItem) =>


                                <Grid key={item.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                                    <div style={{ marginBottom: "1rem", boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px", padding: "1rem", borderRadius: " .5rem" }}>

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            style={{ position: "relative" }}
                                        >
                                            <div onClick={_ => _.stopPropagation()} style={{
                                                position: "absolute",
                                                top: "3%",
                                                right: ".2rem",
                                            }}>
                                                {(decodedToken?.realm_access?.roles.includes('admin') || voltar === true && user && item?.id_pessoa === pessoa?.id && getContratos(decodedToken)) ?
                                                    <LongMenu curso={item} setCurso={setCurso} deleteAPI={deleteAPI} central={true} />
                                                    : ""}
                                            </div>
                                            <Grid item xs={12} >

                                                <div style={{
                                                    userSelect: "none",
                                                    background: `radial-gradient(#009907, #006322)`,
                                                    padding: "1.5rem", borderRadius: ".5rem", color: "white"
                                                }}>

                                                    <Grid item xs={12}>
                                                        <span style={{ textTransform: "uppercase", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            {item.nome}
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <span style={{ marginTop: ".5rem", fontSize: "12px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            {item.aulas !== undefined ? item.aulas + " aulas" : ""}
                                                        </span>
                                                    </Grid>
                                                </div>

                                            </Grid>

                                            <Grid item xs={12}>

                                                <Grid container justifyContent={"center"} spacing={2}>
                                                    {uuid_turma ?
                                                        <Grid item>
                                                            <Button onClick={_ => handleClickOpen(item)} style={{ fontSize: "12px", marginTop: "1rem", paddingLeft: "1.5rem", paddingRight: "1.5rem", backgroundColor: "#0048B6" }} variant="contained" disabled={atribuirLoading}>
                                                                Atribuir
                                                            </Button>
                                                        </Grid> :
                                                        <Grid item>
                                                            <Button onClick={_ => navigate(`/centralcursos/${item.id}`)} style={{ fontSize: "12px", marginTop: "1rem", paddingLeft: "1.5rem", paddingRight: "1.5rem", backgroundColor: "#0048B6" }} variant="contained">
                                                                Abrir
                                                            </Button>
                                                        </Grid>}
                                                </Grid>

                                            </Grid>

                                        </Grid>



                                    </div>



                                </Grid>




                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </div >
            <Paginacao atividade={curso} handlePage={handlePage} />
        </div >
    </>)
}

export default Cursos;