import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import atencao from '../../img/lotties/134878-warning-status.json';
import Lottie from 'react-lottie';
import { lottieDefaultOptions } from "../types"


function Erro() {
    const navigate = useNavigate();

    return (
        <Result
            icon={<Lottie
                options={lottieDefaultOptions(atencao)}
                height={"200px"}
                width={"200px"}
            />}
            title="Há alguns problemas com sua operação."
            extra={
                <Button type="primary" key="console" onClick={() => navigate("/home")}>
                    Voltar
                </Button>
            }
        />
    )
}

export default Erro;