import { useEffect, useState } from "react";
import api from "../../axios/axios";
import { Avatar, Grid, Paper, TextField } from "@mui/material";
import Lottie from "react-lottie";
import carregando from '../../img/lotties/98432-loading.json';
import { lottieDefaultOptions } from "../types";
import Erro from "../erro";
import React from "react";
import { Button, Modal } from "antd";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import Paginacao from "../paginacao";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Button as ButtonMui } from "@mui/material";
import { toast } from "react-toastify";
import Cadastro from "../cadastro";
import SearchIcon from '@mui/icons-material/Search';
import { CorButton } from "../cor";
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function GerenciarCadastro({ decodedToken }: any) {

    const [pessoas, setPessoas] = useState<any>();
    const [pessoaModal, setPessoaModal] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const [erro, setErro] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editarPessoa, setEditarPessoa] = useState({ "username": null });
    const [buscar, setBuscar] = useState("");
    const navigate = useNavigate()

    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        if (loading === false && buscar !== undefined) {
            const delay = setTimeout(() => {
                buscarAPI();
            }, 2000);

            return () => clearTimeout(delay);
        }

    }, [buscar]);

    const buscarAPI = () => {
        if (buscar !== undefined && buscar.trim() !== "")
            buscarPaginacao(1)
        else
            api.get(`/cadastro?page=${1}`).then((r: any) => setPessoas(r.data))
    }

    const handleBuscar = (e: any) => {
        setBuscar(e.target.value)
    }

    const buscarPaginacao = async (pagina: number = 1) => {
        try {
            api.get(`/cadastro/${buscar}?page=${pagina}&size=${5}`).then((r) => setPessoas(r.data)).catch(() => toast.error("Não foi possível pesquisar"));
        } catch (error) {
            setErro(true)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        Promise.all([
            api.get("/cadastro"),
        ]).then(([r]) => {

            setPessoas(r.data)
        }).then(e => setLoading(false)).catch(e => setErro(true))
    }, [])



    if (erro)
        return <><Erro /></>

    if (loading) {
        return (<>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "70vh" }}
            >
                <Lottie
                    options={lottieDefaultOptions(carregando)}
                    height={"40%"}
                    width={"40%"}
                />
            </Grid>
        </>
        )
    }


    const handlePage = (pagina: number) => {
        if (buscar.trim() === "")
            api.get(`/cadastro?page=${pagina}`).then((r: any) => setPessoas(r.data)).catch(e => setErro(true))
        else
            buscarPaginacao(pagina)

    }

    const deletar = (pessoa: any) => {
        toast.info("Deletando pessoa")
        api.delete(`/cadastro?username=${pessoa.username}&uuid=${pessoa.id}`).then(() => {
            toast.success("Pessoa deletada")

            setPessoas({
                ...pessoas,
                items: pessoas.items.filter((p: any) => p.id !== pessoa.id),
            });

        }).catch(() => toast.error("Erro ao deletar pessoa"))


    }


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    return (<>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Tem certeza que realmente deseja apagar " + pessoaModal?.username + "?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button type="primary" onClick={handleClose}>Não</Button>
                <Button type="primary" danger onClick={_ => { handleClose(); deletar(pessoaModal) }} autoFocus>
                    Sim
                </Button>

            </DialogActions>
        </Dialog>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginBottom: "1rem" }}>
            <Grid item>
                <CorButton cor="#1976d2">
                    <ButtonMui onClick={() => navigate("/")} size="small" >
                        Voltar
                    </ButtonMui>
                </CorButton>
            </Grid>
        </div>
        <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center" >
            <Grid item >
                <Modal
                    centered
                    footer={null}
                    visible={isModalOpen}
                    onCancel={handleCancel}
                    style={{ padding: "-2rem" }}
                >
                    <Cadastro key={editarPessoa?.username} pessoas={pessoas} setIsModalOpen={setIsModalOpen} setPessoas={setPessoas} setEditarPessoa={setEditarPessoa} btnVoltar={false} professor={true} decodedToken={decodedToken} editarPessoa={editarPessoa} />
                </Modal>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                <TextField
                    id="buscar"
                    label="Pesquisar..."
                    type="search"
                    size="small"
                    fullWidth
                    onChange={handleBuscar}
                    InputProps={{
                        endAdornment: (
                            <SearchIcon color="action" />
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12} className="sombra" style={{ padding: "1rem", borderRadius: '1rem' }}>
                {pessoas.items.map((pessoa: any, _: number) =>
                    <React.Fragment key={pessoa.id}>
                        <Grid item style={{ margin: "1rem" }}>
                            <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item>
                                    <Avatar component={Paper}
                                        elevation={3} style={{
                                            border: "2.5px solid white"
                                        }} src={pessoa?.attributes?.foto ? pessoa?.attributes?.foto[0] : null} />
                                </Grid>
                                <Grid item xs>
                                    <span style={{ display: "inline-block", fontWeight: "bold", textTransform: "capitalize" }}>
                                        {`${pessoa.firstName.trim()} ${pessoa.lastName.trim()}`.trim()}
                                    </span>
                                    <p style={{ margin: "0", fontSize: "12px", marginTop: ".5rem" }}>
                                        <span style={{ fontWeight: "bold" }}>Matrícula:</span> {pessoa.username}
                                    </p>
                                    <p style={{ margin: "0", fontSize: "12px", marginTop: ".3rem" }}>
                                        <span style={{ fontWeight: "bold" }}>Email:</span> {pessoa.email}
                                    </p>
                                </Grid>

                                <Grid item>
                                    <ButtonMui onClick={() => { setEditarPessoa(pessoa); showModal(); }} variant="outlined" style={{}} size={"small"} >
                                        <BorderColorIcon />
                                    </ButtonMui>


                                </Grid>


                                <Grid item>


                                    <ButtonMui style={{


                                        backgroundColor: "red",
                                        color: "white"
                                    }} size={"small"}
                                        onClick={i => { setPessoaModal(pessoa); i.stopPropagation(); handleClickOpen() }}

                                    ><PersonRemoveIcon />
                                    </ButtonMui>



                                </Grid>


                                {_ + 1 < pessoas.items.length ?
                                    <Grid item xs={12}>
                                        <hr style={{ position: "relative", marginLeft: "-2rem", marginRight: "-2rem", marginTop: "2rem", border: "1px solid #DADADA" }} />
                                    </Grid>
                                    : ""}
                            </Grid>

                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
            <Paginacao atividade={pessoas} handlePage={handlePage}></Paginacao>
        </Grid>
    </>)
}

export default GerenciarCadastro;