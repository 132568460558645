import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import MenuTurma from "../atividade/menu";
import { Avatar, Paper } from '@mui/material'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { Button as ButtonAnt } from "antd";
import { useState } from "react";
import api from "../../axios/axios";
import { toast } from "react-toastify";

import { Pessoa, getContratos, getNotAluno } from "../types";
import React from "react";

function TurmaPessoas({ setTurma, tela, professor, setTela, setCurso, cor, curso, turma, uuid_turma, decodedToken }: any) {

    const [open, setOpen] = useState(false);
    const [pessoa, setPessoa] = useState<Pessoa>();


    const handleClose = () => {
        setOpen(false);
    };

    const deleteAPI = () => {
        toast.info("Removendo pessoa");
        api.delete(`/pessoa/${uuid_turma}/${pessoa?.id_keycloak}`).then((_) => { toast.success("Pessoa removida"); setTurma({ ...turma, pessoas: turma.pessoas.filter((_: Pessoa) => pessoa && _.id_keycloak !== pessoa.id_keycloak) }) }).catch((_) => toast.error("Erro ao remover pessoa"))

    }



    let professores: Pessoa[] = [];
    let alunos: Pessoa[] = [];
    let coordenador: Pessoa[] = [];

    if (turma.pessoas)
        turma.pessoas.map((_: Pessoa) => { if (_.tipo[0]?.tipo && _.tipo[0]?.tipo.toLowerCase() === 'professor') { professores.push(_) } else if (_.tipo[0]?.tipo && _.tipo[0]?.tipo.toLowerCase() === 'coordenador') { coordenador.push(_) } else alunos.push(_) })
    return (<>

        <MenuTurma turma={turma} decodedToken={decodedToken} setTurma={setTurma} tela={tela} setTela={setTela} professor={professor} selecionado="PESSOAS" setCurso={setCurso} curso={curso} turmaNome={turma.nome} cor={cor} uuidTurma={uuid_turma ? uuid_turma : ""} editarNovoCurso={true} />

        <Grid item xs={11}>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Tem certeza que realmente deseja remover aluno ${pessoa?.nome}?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonAnt type="primary" onClick={handleClose}>Não</ButtonAnt>
                    <ButtonAnt type="primary" danger onClick={(_: any) => { handleClose(); deleteAPI(); }} autoFocus>
                        Sim
                    </ButtonAnt>

                </DialogActions>
            </Dialog>

            {professores.length > 0 ?
                <Grid container
                    direction="row"
                    justifyContent="center"

                    alignItems="center" >
                    <Grid item> <h1 style={{ color: cor }}>PROFESSORES</h1></Grid>
                    <Grid item xs={12} className="sombra" style={{ padding: "1rem", borderRadius: '1rem' }}>
                        {professores.map((aluno: Pessoa, _: number) =>
                            <React.Fragment key={aluno.id}>
                                <Grid item style={{ margin: "1rem" }}>
                                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                                        <Grid item>
                                            <Avatar component={Paper}
                                                elevation={3} style={{
                                                    border: "2.5px solid #568fb6",
                                                    objectFit: "cover",
                                                }} src={aluno?.foto ? aluno.foto : ""} />
                                        </Grid>
                                        <Grid item xs>
                                            <span style={{ display: "inline-block", fontWeight: "bold", textTransform: "capitalize" }}>
                                                {aluno.nome}
                                            </span>
                                        </Grid>

                                        {getNotAluno(decodedToken) && decodedToken?.preferred_username === aluno?.id_keycloak ?
                                            <Grid item>

                                                <Button style={{
                                                    backgroundColor: "red",
                                                    color: "white",

                                                }}

                                                    onClick={() => { setOpen(true); setPessoa(aluno) }}
                                                ><PersonRemoveIcon />
                                                </Button>

                                            </Grid>
                                            : ""}


                                        {(turma?.tipo === 'aluno') || (turma?.tipo === 'professor') ? "" : decodedToken?.realm_access?.roles.includes('admin') || decodedToken?.realm_access?.roles.includes('diretor') || decodedToken?.realm_access?.roles.includes('coordenador') || (getContratos(decodedToken)) ?
                                            <Grid item>

                                                <Button style={{
                                                    backgroundColor: "red",
                                                    color: "white",

                                                }}

                                                    onClick={() => { setOpen(true); setPessoa(aluno) }}
                                                ><PersonRemoveIcon />
                                                </Button>

                                            </Grid>
                                            : ""}
                                    </Grid>



                                    {_ + 1 < professores.length ?
                                        <Grid item xs={12}>
                                            <hr style={{ position: "relative", marginLeft: "-2rem", marginRight: "-2rem", marginTop: "2rem", border: "1px solid #DADADA" }} />
                                        </Grid>
                                        : ""}
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>

                : ""}
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" />

            {alunos.length > 0 ?
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center" >
                    <Grid item> <h1 style={{ color: cor }}>ALUNOS</h1></Grid>
                    <Grid item xs={12} className="sombra" style={{ padding: "1rem", borderRadius: '1rem' }}>
                        {alunos.map((aluno: Pessoa, _: number) =>
                            <React.Fragment key={aluno.id}>
                                <Grid item style={{ margin: "1rem" }}>


                                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                                        <Grid item>
                                            <Avatar component={Paper}
                                                elevation={3} style={{
                                                    border: "2.5px solid #568fb6",
                                                    objectFit: "cover",
                                                }} src={aluno?.foto ? aluno.foto : ""} />
                                        </Grid>
                                        <Grid item xs>
                                            <span style={{ display: "inline-block", fontWeight: "bold", textTransform: "capitalize" }}>
                                                {aluno.nome}
                                            </span>
                                        </Grid>

                                        {getNotAluno(decodedToken) && decodedToken?.preferred_username === aluno?.id_keycloak ?
                                            <Grid item>

                                                <Button style={{
                                                    backgroundColor: "red",
                                                    color: "white",

                                                }}

                                                    onClick={() => { setOpen(true); setPessoa(aluno) }}
                                                ><PersonRemoveIcon />
                                                </Button>

                                            </Grid>
                                            : ""}

                                        {(turma?.tipo === 'aluno') ? "" : decodedToken?.realm_access?.roles.includes('admin') || decodedToken?.realm_access?.roles.includes('diretor') || decodedToken?.realm_access?.roles.includes('coordenador') || ((turma?.tipo === 'professor' || turma?.tipo === 'coordenador' || turma?.tipo === 'diretor') && getContratos(decodedToken)) ?
                                            <Grid item>

                                                <Button style={{
                                                    backgroundColor: "red",
                                                    color: "white",

                                                }}

                                                    onClick={() => { setOpen(true); setPessoa(aluno) }}
                                                ><PersonRemoveIcon />
                                                </Button>

                                            </Grid>
                                            : ""}
                                        {_ + 1 < alunos.length ?
                                            <Grid item xs={12}>
                                                <hr style={{ position: "relative", marginLeft: "-2rem", marginRight: "-2rem", marginTop: "2rem", border: "1px solid #DADADA" }} />
                                            </Grid>
                                            : ""}
                                    </Grid>

                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>

                : ""}
        </Grid >
    </>)
}

export default TurmaPessoas;