import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Button as ButtonAntd } from 'antd';
import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CorButton } from "../cor";



function ButtonSair({ uuid_turma, texto = "sair", cor, sair, item, uuid_curso, central, aula = false }: any) {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleClickOpen = () => {
        if (texto.toLocaleLowerCase() !== "sair") {
            if (uuid_turma) {
                if (uuid_curso) {
                    if (aula?.xy) {
                        navigate(`/trilha/${uuid_turma}/${uuid_curso}`)
                    }
                    else
                        navigate(`/turma/${uuid_turma}/${uuid_curso}`)
                }
            }
            else if (item) {
                sair(item)
            }
            else if (sair) {
                sair()
            }
            else if (uuid_turma === undefined) {
                navigate("/")
            }
        }
        else {
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (<>
        <div>
            <CorButton cor={cor}>
                <Button onClick={handleClickOpen} size="small" >
                    {texto}
                </Button>
            </CorButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Tem certeza que deseja realmente ${texto.toLocaleLowerCase()}?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonAntd type="primary" onClick={handleClose}>Não</ButtonAntd>
                    <ButtonAntd type="primary" danger onClick={_ => {
                        handleClose();
                        if (central) {
                            navigate(`/centralcursos/${uuid_curso}`);
                        }
                        else if (uuid_turma) {
                            if (uuid_curso) {

                                if (aula?.xy) {
                                    navigate(`/trilha/${uuid_turma}/${uuid_curso}`)
                                }
                                else
                                    navigate(`/turma/${uuid_turma}/${uuid_curso}`)
                            }
                        }
                        else if (item) {
                            sair(item)
                        }
                        else if (sair) {
                            sair()
                        }
                        else if (uuid_turma === undefined) {
                            navigate("/")
                        }
                    }} autoFocus>
                        Sim
                    </ButtonAntd>

                </DialogActions>
            </Dialog>
        </div>
    </>)
}

export default memo(ButtonSair);