import { useEffect, useState } from "react";
import api from "../../axios/axios";
import Lottie from "react-lottie";
import { Atividade, Resposta, calcular_pontuacao, convertToEmbedUrl, imagem_turma_listagem, lottieDefaultOptions, validateLink } from "../types";
import dados from '../../img/lotties/87986-data-analysis.json';
import { Grid } from "@mui/material";
import Erro from "../erro";
import carregando from '../../img/lotties/98432-loading.json';
import React from "react";
import { Card, List, Space } from "antd";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DangerousIcon from '@mui/icons-material/Dangerous';
import Search from "antd/es/transfer/search";

function ListarRespostas({ aula, turma }: any) {
    const [respostas, setRespostas] = useState<any>();
    const [respostasBusca, setRespostasBusca] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [erro, setErro] = useState(false);
    const [buscar, setBuscar] = useState("");

    useEffect(() => {
        api.get(`resposta/listar/${aula.id}`).then((r) => setRespostas(r.data)).then(_ => setLoading(false)).catch(_ => setErro(true));
    }, [])



    useEffect(() => {
        if (loading === false && buscar !== undefined) {

            const delay = setTimeout(() => {
                if (buscar.trim() !== "") {
                    setRespostasBusca({ "items": respostas.items.filter((item: any) => item.pessoa.nome.toLowerCase().includes(buscar.toLowerCase()) || item.pessoa.id_keycloak.toLowerCase().includes(buscar.toLowerCase())) });
                }
            }, 2000);

            return () => clearTimeout(delay);
        }

    }, [buscar]);


    const handleBuscar = (e: any) => {
        setBuscar(e.target.value)
    }



    if (erro)
        return <><Erro /></>

    if (loading)
        return (<>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "70vh" }}
            >
                <Lottie
                    options={lottieDefaultOptions(carregando)}
                    height={"40%"}
                    width={"40%"}
                />
            </Grid>
        </>)

    const respostaCerta = (perguntaItem: Atividade, res: any, __: number) => {
        if (turma.tipo === 'aluno') {
            return ""
        }

        if (perguntaItem.respostas.length === 0) {
            return "Concluído!"
        }

        const r = res.respostas.filter((_: Resposta) => perguntaItem.id === _.id_atividade);
        if (r.length > 0) {
            const selecao = perguntaItem.respostas.filter((_: Resposta) => _.id === r[0].id_resposta);
            if (selecao.length > 0)
                return selecao[0].resposta
            return "Nenhuma seleção"
        }
        return "Nenhuma seleção"
    }



    return (<>
        <div style={{ overflow: "hidden" }}>
            <div style={{ display: "flex" }}>
                <Lottie
                    options={lottieDefaultOptions(dados)}
                    height={"300px"}
                    width={"300px"}
                />
            </div>
        </div>
        <Grid container justifyContent="center" spacing={1} style={{ marginBottom: "2rem" }}>
            <Grid item xs={8} className="boxSombra" style={{ marginTop: "1rem", marginBottom: "1rem" }}>

                <p style={{ fontSize: "2rem", textAlign: 'center' }}>
                    <span style={{ fontWeight: "bold" }}>Aula: </span><span>{aula.nome}</span>
                </p>
                {aula.pontuacao ?
                    <p style={{ fontSize: "1.3rem", textAlign: 'center', marginTop: "-1rem" }}>
                        <span style={{ fontWeight: "bold" }}>Pontuação: </span><span>{aula.pontuacao}</span>
                    </p>
                    : ""}

                <Grid item xs={12} style={{ marginRight: ".5rem" }}><iframe allow="fullscreen" src={(convertToEmbedUrl(aula.link) || aula.link)} /></Grid>
                <Grid container
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                    alignItems="center" style={{ marginBottom: "2rem", marginTop: "1rem" }}>
                    {aula.atividades.map((atv: Atividade, __: number) =>
                        <React.Fragment key={atv.id}>
                            {turma?.tipo === 'professor' ? <>
                                <hr style={{ marginLeft: ".5rem", width: "100%" }} />
                                <Grid item><span style={{ fontWeight: "bold" }}>Quiz: {__ + 1} - </span><span>{atv.nome}</span></Grid>
                                <Grid item style={{ marginRight: ".5rem" }}><span style={{ fontWeight: "bold" }}>Nota: </span>{atv.nota ? atv.nota : 0}</Grid>
                                <Grid item xs={12} style={{ marginRight: ".5rem" }}>
                                    {
                                        validateLink(atv.pergunta) === false ? <iframe allow="fullscreen" src={(convertToEmbedUrl(atv.pergunta) || atv.pergunta)} />
                                            :
                                            <Grid xs={true} style={{
                                                color: "white", backgroundColor: turma?.cor?.hex,
                                                backgroundImage: `url('${imagem_turma_listagem[turma?.cor?.hex]}')`,
                                                backgroundRepeat: "repeat",
                                                backgroundSize: "auto 10vh",
                                                padding: "1rem",
                                                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                                border: "2px solid black",
                                                borderRadius: "1rem",
                                                userSelect: "none",
                                                fontSize: "20px",
                                            }}>
                                                <p style={{
                                                    textAlign: "center",
                                                    textJustify: "inter-word",
                                                }}>{atv.pergunta}</p>
                                            </Grid>

                                    }

                                </Grid>
                                {atv.resposta_certa && atv.resposta_certa !== "" ?
                                    <Grid item xs={12} style={{ marginTop: "-.5rem" }}><span style={{ fontWeight: "bold" }}>Resposta: </span><span style={{ textAlign: 'initial' }}>{atv.respostas.filter((r: Resposta) => r.id === atv.resposta_certa)[0].resposta}</span></Grid>
                                    : ""}
                            </> : ""}
                        </React.Fragment>
                    )
                    }
                </Grid>


            </Grid>
            <Grid item xs={12}>
                <Space direction="vertical">
                    <Search value={buscar}
                        onChange={handleBuscar}
                        placeholder="Pesquisar..." />
                </Space>
            </Grid>
            {respostas?.items.length > 0 ? <>
                {/* <Grid item xs={12}>
                    <p style={{ marginBottom: "1rem", marginTop: ".5rem", textAlign: 'center' }}>Resumo:</p>
                </Grid> */}




                <List
                    locale={{ emptyText: "Não encontrado." }}
                    grid={{

                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 2,
                        xl: 3,
                        xxl: 4,
                    }}
                    dataSource={buscar.trim() !== "" && respostasBusca?.items ? respostasBusca?.items : respostas?.items}
                    pagination={{
                        pageSize: 10,
                        align: "center"
                    }}

                    style={{ width: "100%", marginTop: "1rem" }}
                    renderItem={(res: any) => (
                        <List.Item>
                            <React.Fragment key={res?.user} >

                                <Card className="boxSombra" style={{ marginLeft: "1rem" }} >
                                    <Grid item xs={12}>
                                        <Grid container justifyContent={"end"} >
                                            <Grid item overflow={"auto"}>
                                                {res.timestamp ? <span>{new Date(new Date(res?.timestamp).getTime() - new Date(res?.timestamp).getTimezoneOffset() * 60000).toLocaleString().split(",")[0]}<br /> </span> : ""}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center">
                                            <Grid item>
                                                <span style={{ fontWeight: "bold", marginBottom: "0rem" }}>{res?.pessoa.id_keycloak}</span><br />
                                            </Grid>
                                            <Grid item xs={12}></Grid>
                                            <Grid item >
                                                <span style={{ fontWeight: "bold", marginBottom: "0rem" }}>{res?.pessoa.nome}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent={"center"}>
                                            <Grid item xs={12}><br /></Grid>
                                            <Grid item>
                                                {aula.atividades.length > 0 && aula?.avaliacao !== 2 ?
                                                    "Nota: " + calcular_pontuacao(aula.atividades.map((_: Atividade) => _.nota), aula.atividades.map((perguntaItem: Atividade, __: number) => {
                                                        const r = res.respostas.filter((_: Resposta) => perguntaItem.id === _.id_atividade);

                                                        if (perguntaItem.respostas.length === 0 || r[0].id_resposta === perguntaItem.resposta_certa)
                                                            return perguntaItem.nota
                                                        else
                                                            return 0

                                                    }), aula.pontuacao) + "%" : "Nota: 100%"}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    {aula.atividades.length > 0 ? <>
                                        <Grid item xs={12}>
                                            <Grid container justifyContent={"center"}>
                                                <Grid item>
                                                    <span style={{ fontWeight: "bold", marginBottom: "0rem" }}>Resumo:</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <hr style={{ width: "100%" }} />
                                        <Grid container direction="row"
                                            justifyContent="center"
                                            alignItems="center" style={{ marginTop: "-.5rem" }} spacing={1}>
                                            {aula.atividades.map((perguntaItem: Atividade, __: number) =>
                                                <React.Fragment key={perguntaItem.id}>



                                                    <Grid item xs={12}>
                                                        <Grid container >
                                                            <Grid item textAlign={"justify"} overflow={"auto"}>

                                                                <span style={{ fontWeight: "bold" }}>
                                                                    {turma.tipo === 'professor' ? perguntaItem.nome : "Questão " + (__ + 1)}:</span> {respostaCerta(perguntaItem, res, __)}
                                                                {aula?.avaliacao !== 2 ?
                                                                    <>
                                                                        {

                                                                            perguntaItem.resposta_certa === res.respostas[__].id_resposta || perguntaItem.respostas.length === 0 || res.respostas.filter((_: Resposta) => perguntaItem.id === _.id_atividade)[0].id_resposta === perguntaItem.resposta_certa ?

                                                                                <span style={{ marginTop: "-.15rem", position: "absolute" }}>
                                                                                    <CheckBoxIcon style={{ color: "green" }} />
                                                                                </span>
                                                                                :
                                                                                <span style={{ marginTop: "-.15rem", position: "absolute" }}>
                                                                                    <DangerousIcon style={{ color: "red" }} />
                                                                                </span>

                                                                        }</> : <span style={{ marginTop: "-.15rem", position: "absolute" }}>
                                                                                    <CheckBoxIcon style={{ color: "green" }} />
                                                                                </span>}

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <hr style={{ width: "100%" }} />
                                                </React.Fragment>)}
                                        </Grid>
                                    </> : ""}
                                </Card>

                            </React.Fragment>
                        </List.Item>
                    )}
                />



            </>
                : <Grid item xs={12}>
                    <p style={{ marginBottom: "0rem", marginTop: ".5rem", textAlign: 'center' }}>Nenhuma resposta.</p>
                </Grid>
            }
        </Grid >

    </>)
}

export default ListarRespostas;