import React from 'react';
import App from './App';
import Keycloak from 'keycloak-js';
import ReactDOM from 'react-dom/client';
import { DndProvider } from 'react-dnd'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'
import { MultiBackend } from 'react-dnd-multi-backend'

const keycloak = new Keycloak({
  url: `${process.env.REACT_APP_KEYCLOAK_URL}`,
  realm: `${process.env.REACT_APP_KEYCLOAK_REALM}`,
  clientId: `${process.env.REACT_APP_KEYCLOAK_CLIENT}`,
});



keycloak.init({
  checkLoginIframe: true,
  checkLoginIframeInterval: 5
}).then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  if (root) {
    root.render(
      <React.StrictMode>
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
          <App />
        </DndProvider>
      </React.StrictMode>
    );

  }
});

export { keycloak };
