import { keycloak } from "../..";
import { useEffect } from "react";

const Nav = () => {

    useEffect(() => {
        const login = () => {
            keycloak.login();

        };
        const timerId = setTimeout((login), 50);

        return () => clearTimeout(timerId);
    }, []);

    return (
        <></>
    );
};

export default Nav;