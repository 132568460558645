import { memo } from "react";
import "../index.css";
import { CorCirculosFundo } from "../cor";
import { imagem_turma_listagem } from "../types";


interface IFundoTurma {
    cor: string,
    turmaNome: string;
}
function FundoTurma({ cor, turmaNome }: IFundoTurma) {

    return (
        <CorCirculosFundo cor={cor}>
            <div className="container" style={{
                height: "7rem", display: "flex",
                backgroundImage: `url('${imagem_turma_listagem[cor]}')`,
                backgroundRepeat: "repeat",
                backgroundSize: "10rem auto",
                boxShadow: ` rgba(0, 0, 0, 0.5) 0px 3px 16px, ${cor} 0px 3px 6px`,

                justifyContent: "center", alignItems: "center"
            }}>

                <p style={{
                    position: "absolute", overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "80vw"
                }}> {turmaNome} </p>
            </div>
        </CorCirculosFundo>
    );
}

export default memo(FundoTurma);
