import { Grid } from '@mui/material';
import moment from 'moment-timezone';
import LockIcon from '@mui/icons-material/Lock';

export function DataEntrega({ aula, professor }: any) {

    if (aula?.data_entrega === undefined) {
        return (<>
            <Grid item xs={true}>

            </Grid></>)
    }

  

    const entrega = aula.entrega?.timestamp ? new Date(new Date(aula.entrega?.timestamp).getTime() - new Date(aula.entrega?.timestamp).getTimezoneOffset() * 60000).setHours(0, 0, 0, 0) : null;
    const dataEntrega = aula.data_entrega ? new Date(aula.data_entrega).setHours(0, 0, 0, 0) : null;
    const dataAtual = new Date(moment().tz('America/Sao_Paulo').toDate()).setHours(0, 0, 0, 0);



    var date = new Date(aula.entrega?.timestamp)
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const dataEntregaFim = (new Date(date.getTime() - userTimezoneOffset))



    if (professor) {
        return (<>
            <Grid item xs={true} style={{ marginRight: '.5rem' }}>
                <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center" spacing={2}>
                    <Grid item style={{ marginRight: "-.5rem" }}>
                        {aula.data_entrega ?
                            <span style={{ fontWeight: "bold", color: "#1f5ebe", marginTop: "-1.5rem" }}>{`Data da entrega: ${new Date(aula.data_entrega).toLocaleDateString()}`}</span>
                            :
                            ""}
                    </Grid>
                    <Grid item>
                        {professor === true && aula.status === false ? <LockIcon style={{ color: "#1f5ebe" }} /> : ""}
                    </Grid>
                </Grid>
            </Grid>
        </>)
    }

    return (<>
        <Grid item xs={true}>
            {dataEntrega ?

                entrega && dataEntrega && entrega > dataEntrega ?
                    <>
                        <p style={{ fontWeight: "bold", color: "#E02A2A", marginRight: "1rem", marginTop: "-1.5rem" }}>{`Data da entrega: ${new Date(aula.data_entrega).toLocaleDateString()}`}</p>
                        <p style={{ fontSize: ".9rem", marginTop: "-1rem", color: "#727272", marginRight: "1rem" }}>
                            {aula.entrega?.timestamp ? `Entregue: ${new Date(aula.entrega?.timestamp).toLocaleDateString()}` :
                                `Atrasado`}
                        </p>
                    </>

                    :
                    aula.entrega && entrega && dataEntrega && entrega <= dataEntrega ?
                        <>
                            <p style={{ fontWeight: "bold", color: "#076310", marginRight: "1rem", marginTop: "-1.5rem" }}>{`Data da entrega: ${new Date(aula.data_entrega).toLocaleDateString()}`}</p>
                            <p style={{ fontSize: ".9rem", marginTop: "-1rem", color: "#727272", marginRight: "1rem" }}>
                                {`Entregue: ${aula.entrega?.timestamp
                                    ? dataEntregaFim.toLocaleString().split(",")[0]
                                    : "Pendente"
                                    }`}
                            </p>
                        </> :
                        dataAtual > dataEntrega ? <>
                            <p style={{ fontWeight: "bold", color: "#E02A2A", marginRight: "1rem", marginTop: "-1.5rem" }}>{`Data da entrega: ${new Date(aula.data_entrega).toLocaleDateString()}`}</p>
                            <p style={{ fontSize: ".9rem", marginTop: "-1rem", color: "#727272", marginRight: "1rem" }}>
                                {`Entregue: ${aula.entrega?.timestamp
                                    ? dataEntregaFim.toLocaleString().split(",")[0]
                                    : "Pendente"
                                    }`}
                            </p>
                        </> :
                            <p style={{ fontWeight: "bold", color: "#1f5ebe", marginRight: "1rem", marginTop: "-1.5rem" }}>{`Data da entrega: ${new Date(aula.data_entrega).toLocaleDateString()}`}</p>
                : aula.entrega ? <p style={{ fontSize: ".9rem", marginTop: "-1rem", color: "#727272", marginRight: "1rem" }}>
                    {`Entregue: ${aula.entrega?.timestamp
                        ? dataEntregaFim.toLocaleString().split(",")[0]
                        : "Pendente"
                        }`}
                </p> : ""}

        </Grid>
    </>)
}