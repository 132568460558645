import Grid from "@mui/material/Grid";
import { toast } from 'react-toastify';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../axios/axios";
import "../index.css"
import carregando from '../../img/lotties/98432-loading.json';
import Box from '@mui/material/Box';
import { Input } from 'antd';
import Modal from '@mui/material/Modal';
import { Button } from "@mui/material";
import { ListaCor, ListaTurma, TurmaItem, getContratos, imagem_turma_listagem, lottieDefaultOptions } from "../types";
import LongMenu from "./menu";
import * as uuid from "uuid";
import { PlusOutlined } from "@ant-design/icons";
import Erro from "../erro";
import Lottie from "react-lottie";
import DropDown from "./dropdown";
import Paginacao from "../paginacao";
import banners_abertura from "../../img/gif_img/banners_gif_abertura_nova.gif";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
    radios: 1
};


function Turma({ professor = false, decodedToken }: any) {

    const [turma, setTurma] = useState<ListaTurma>(new ListaTurma());
    const [turmaCadastrado, setTurmaCadastrado] = useState<ListaTurma>(new ListaTurma());
    const [cor, setCor] = useState<ListaCor>(new ListaCor())
    const [loading, setLoading] = useState(true);
    const [erro, setErro] = useState(false);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [check, setCheck] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [editar, setEditar] = useState(false);
    const [novaTurma, setNovaTurma] = useState(new TurmaItem());

    const [escolaAtual, setEscolaAtual] = React.useState<any>({ id: 1, nome: "Nenhuma escola" });
    const [escolas, setEscolas] = React.useState<any[]>([{ id: 1, nome: "Nenhuma escola" }]);


    useEffect(() => {
        setLoading(true);
        api.get(`/cor`).then(cor => {
            setCor(cor.data);

            Promise.all([
                api.get("/escola"),
            ]).then(async ([r]) => {

                if (r.data.length > 0) {
                    setEscolas(r.data);
                    setEscolaAtual(r.data[0]);

                    let r3 = await api.get(`/escola/${r.data[0].id}?page=${turma.page}&size=${turma.size}`);
                    if (r3?.data?.length > 0) {
                        setTurma(r3?.data);
                    }



                }

            }).then(r => setLoading(false)).catch(r => setErro(true))

        }).catch(error => {
            setErro(true)
        });
    }, []);

    useEffect(() => {

        if (escolaAtual.id !== 1) {
            api.get(`/escola/${escolaAtual.id}?page=${turma.page}&size=${turma.size}`).then(r => {
                if (r.data) {


                    setTurma(r.data);

                }
            }).then(r => setLoading(false)).catch(r => setErro(true))
        }


    }, [escolaAtual])


    const buscarPaginacao = async (pagina: number = 1) => {
        try {
            api.get(`/escola/${escolaAtual.id}?page=${pagina}&size=${turma.size}`).then(r => {
                if (r.data) {
                    setTurma(r.data);
                }

            }).then(r => setLoading(false)).catch(r => setErro(true))

        } catch (error) {
            setErro(true)
        } finally {
            setLoading(false);
        }
    };



    if (erro)
        return <><Erro /></>

    if (loading)
        return (<>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "70vh" }}
            >
                <Lottie
                    options={lottieDefaultOptions(carregando)}
                    height={"40%"}
                    width={"40%"}
                />
            </Grid>
        </>)

    const handleButtonCriar = () => {

        if (novaTurma.nome.trim() === "") {
            setCheck(true);
            toast.error("Erro ao publicar atividade")
            return
        }
        else {
            if (check) {
                setCheck(false);
            }
        }

        if (editar) {
            setOpen(false)
            toast.info("Editando turma");
            api.patch("/turma/" + novaTurma.id, JSON.stringify(novaTurma)).then(_ => {
                toast.success("Turma editada");
                setTurma((turma: ListaTurma) => ({
                    ...turma,
                    items: turma.items.map((tu: TurmaItem) => {
                        if (tu.id === novaTurma.id) {
                            return novaTurma;
                        }
                        return tu;
                    })


                })

                );
                setTurmaCadastrado((turma: ListaTurma) => ({
                    ...turma,
                    items: turma.items.map((tu: TurmaItem) => {
                        if (tu.id === novaTurma.id) {
                            return novaTurma;
                        }
                        return tu;
                    })


                }));

            }
            ).catch(_ => toast.error("Erro ao editar turma"))
        }
        else {
            toast.info("Criando turma");
            novaTurma.id = uuid.v4();
            novaTurma.cor = cor.items[Math.floor(Math.random() * (cor.items.length))];
            novaTurma.id_cor = novaTurma.cor.id;
            novaTurma.id_escola = escolaAtual.id;
            api.post("/turma", JSON.stringify(novaTurma)).then(_ => {
                novaTurma.tipo = [{ tipo: "professor" }]

                setTurma((turma: ListaTurma) => ({
                    ...turma,
                    items: [novaTurma, ...turma.items]
                })); setOpen(false); toast.success("Turma criada")
            }).catch(_ => toast.error("Erro ao criar turma"))
        }
    }

    const deleteAPI = (turmaIte: TurmaItem) => {
        toast.info("Deletando turma");
        api.delete("/turma/" + turmaIte.id).then(_ => {
            toast.success("Turma deletada")
            setTurma((turma: ListaTurma) => ({
                ...turma,
                items: turma.items.filter((tu: TurmaItem) => tu.id !== turmaIte.id)
            }));
            setTurmaCadastrado((turma: ListaTurma) => ({
                ...turma,
                items: turma.items.filter((tu: TurmaItem) => tu.id !== turmaIte.id)
            }));

        }).catch(_ => toast.error("Erro ao deletar turma"))
    }

    const handleClickAbrir = async (e: string) => {
        navigate(`/turma/${e}`)
    };

    const handlePage = (pagina: number) => {
        setLoading(true)
        buscarPaginacao(pagina)
    }

    return (<>
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        rowSpacing={1}>
                        <Grid item >
                            <div onClick={handleOpen}>
                                {editar ?
                                    <p style={{ borderBottom: '1px solid', cursor: "default" }}>EDITAR TURMA</p>
                                    :
                                    <p style={{ borderBottom: '1px solid', cursor: "default" }}>CRIAR TURMA</p>
                                }
                            </div>
                        </Grid>

                        <Grid item xs={12} >
                            <div></div>
                        </Grid>
                        <Grid item xs={6}>
                            <Input minLength={1} showCount maxLength={60} status={check && novaTurma.nome.trim() === "" ? "error" : ""} value={novaTurma.nome} onChange={_ => { if (novaTurma.nome.trim().length > 0) { setCheck(true) } setNovaTurma({ ...novaTurma, nome: _.target.value }) }} placeholder="Nome da turma" />
                        </Grid>
                        <Grid item xs={12} >
                        </Grid>
                        <Grid item >
                            {editar ?
                                <Button onClick={handleButtonCriar} className="opacidade" style={{ marginTop: "1.5rem", backgroundColor: "#0048b6", color: "white", paddingLeft: "2rem", paddingRight: "2rem" }}
                                    variant="outlined">
                                    editar
                                </Button>
                                :
                                <Button onClick={handleButtonCriar} className="opacidade" style={{ marginTop: "1.5rem", backgroundColor: "#0048b6", color: "white", paddingLeft: "2rem", paddingRight: "2rem" }}
                                    variant="outlined">
                                    criar
                                </Button>
                            }

                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>

        <Grid item xs={12} style={{ marginBottom: ".1rem" }}>
            <Grid container
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <img
                    style={{ maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto" }}
                    src={banners_abertura}
                    alt={professor === true ? "banners gif abertura de professor" : "banners gif abertura de alunos"}
                />
            </Grid>
        </Grid>


        <Grid item xs={12}>

            {professor === true && escolaAtual.id !== 1 ?
                <>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        spacing={1}
                    >

                        <Grid item xs={true}>
                            <Grid container spacing={2}>
                                <Grid item style={{ overflow: "clip" }}>
                                    <DropDown escolas={escolas} escolaAtual={escolaAtual} setEscolaAtual={setEscolaAtual} />
                                </Grid>
                                <Grid item>{decodedToken?.realm_access?.roles.includes('admin') || getContratos(decodedToken) ?
                                    <Button className="opacidade" size="small" variant="outlined" startIcon={<PlusOutlined />} onClick={_ => { if (editar === true) setEditar(false); setNovaTurma(new TurmaItem()); handleOpen() }} >
                                        Criar turma
                                    </Button>
                                    : ""}
                                </Grid>
                            </Grid>

                        </Grid>
                        {decodedToken?.realm_access?.roles.includes('admin') || getContratos(decodedToken) ?
                            <Grid item>
                                <Button variant="outlined" className="opacidade" size="small" onClick={_ => { navigate("/centralcursos") }} >
                                    Central de cursos
                                </Button>
                            </Grid>
                            : ""}
                    </Grid>
                    <div style={{ marginBottom: "1rem" }}></div>
                </>



                : professor === true && escolaAtual.id === 1 ?
                    <>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"

                            alignItems="center" style={{ marginBottom: "1rem" }}>

                            <Grid item>
                                <Grid container alignItems="center" spacing={3}>
                                    <Grid item>
                                        <DropDown escolas={escolas} escolaAtual={escolaAtual} setEscolaAtual={setEscolaAtual} />
                                    </Grid>
                                </Grid>

                            </Grid>
                            {getContratos(decodedToken) ?
                                <Grid item>
                                    <Button variant="outlined" className="opacidade" size="small" onClick={_ => { navigate("/centralcursos") }} >
                                        Central de cursos
                                    </Button>
                                </Grid>
                                : ""}
                        </Grid>
                    </> :
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center" style={{ marginBottom: "1rem", paddingRight: "1rem" }}>
                        <Grid item xs={12} >
                            <DropDown escolas={escolas} escolaAtual={escolaAtual} setEscolaAtual={setEscolaAtual} />
                        </Grid>

                    </Grid>


            }

        </Grid>
        <Grid item xs={10}>
            <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
            >

                {turma.items.map((itemTurma: TurmaItem) =>
                    <React.Fragment key={itemTurma.id} >
                        <Grid item xs={12} sm={4} xl={2} >

                            <div onClick={_ => handleClickAbrir(itemTurma.id)} className="centro" style={{
                                color: "white", backgroundColor: itemTurma.cor.hex, cursor: "pointer",
                                backgroundImage: `url('${imagem_turma_listagem[itemTurma.cor.hex]}')`,
                                backgroundRepeat: "repeat",
                                backgroundSize: "auto 10vh",

                                padding: "3rem",
                                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                border: "2px solid black",
                                position: "relative",
                                userSelect: "none",


                                maxHeight: "1rem",
                            }}>
                                <div onClick={(e) => e.stopPropagation()} style={{
                                    position: "absolute",
                                    top: "3%",

                                    right: ".2rem",
                                }}

                                >
                                    {

                                        ((((decodedToken?.realm_access?.roles.includes('admin')) || (professor && getContratos(decodedToken) && (decodedToken?.realm_access?.roles.includes('coordenador') || decodedToken?.realm_access?.roles.includes('diretor'))) || (professor && getContratos(decodedToken) && itemTurma.tipo && itemTurma.tipo.length > 0 && ['professor'].includes(itemTurma.tipo[0]?.tipo) || (itemTurma.tipo[0]?.tipo === 'professor' || itemTurma.tipo[0]?.tipo === 'coordenador' || itemTurma.tipo[0]?.tipo === 'diretor') && getContratos(decodedToken))))) || (itemTurma.tipo[0]?.tipo !== 'aluno' && getContratos(decodedToken)) ?
                                            itemTurma.tipo && itemTurma.tipo.length > 0 && itemTurma.tipo[0].tipo != 'aluno' || (itemTurma?.tipo?.length == 0 || itemTurma?.tipo == undefined) && decodedToken?.realm_access?.roles.includes('aluno') === false && getContratos(decodedToken) ?

                                                <LongMenu deleteAPI={deleteAPI} setEditar={setEditar} setNovaTurma={setNovaTurma} handleOpen={handleOpen} turma={itemTurma} turmaEditar={true} />
                                                : ""
                                            :
                                            ""

                                    }
                                </div>
                                <p style={{

                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    maxWidth: "51vw"
                                }}>{itemTurma.nome}</p>
                            </div>
                        </Grid>
                    </React.Fragment>
                )}




            </Grid>

            <Paginacao atividade={turma} handlePage={handlePage} />
        </Grid>

    </>)
}

export default Turma;
