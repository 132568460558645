import Grid from "@mui/material/Grid";
import { GridAtividade, GridAtividadeBorda, GridSpanCenter, GridSpanDiv } from "../cor";
import { Checkbox, DatePicker, Input, InputNumber, Select, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ArticleIcon from '@mui/icons-material/Article';
import EditIcon from '@mui/icons-material/Edit';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useState } from "react";
import ButtonSair from "../sair";
import { useNavigate, useParams } from "react-router-dom";
import Aula from "../aula";
import { Atividade, CursoItem, formatarNumero } from "../types";
import moment from "moment";
import locale from "antd/lib/date-picker/locale/pt_BR";
import dayjs from "dayjs";


function Curso_Criar({ modal, setCursos, cursos, setModal, editar, editarNovoCurso, central, cursoItem, user, turma, trilha = false }: any) {
    const cor = "#0048b6"
    const [checkNomeCurso, setCheckNomeCurso] = useState(false);
    const [curso, setCurso] = useState<CursoItem>(editar ? cursoItem : central && cursoItem ? cursoItem : new CursoItem());
    const navigate = useNavigate();
    const { uuid_turma } = useParams();
    const [atividade, setAtividade] = useState<Atividade[]>([])
    const [aula, setAula] = useState<any>("")
    const [data, setData] = useState(curso?.data_abertura ? true : false);
    
    // useEffect(() => {
    //     if (central) {
    //         Promise.all([api.get(`/curso/${cursos.id}`),
    //         api.get(`/aula/curso/${curso.id}`)])
    //             .then(([r1, r2]) => {
    //                 setCurso(r1.data);
    //                 setAula(r2.data);
    //                 setAtividade(r2.data.atividades);
    //             })
    //     }
    // }, [cursos.id])


    const sair = () => {
        navigate(`/turma/${uuid_turma}`)
    }

    const disabledDate = (current: any) => {

        return current && current < moment().tz('America/Sao_Paulo').startOf('day');
    };

    const handleChangeData = (e: any) => {
        if (e === null) {
            setCurso((ant: CursoItem) => ({ ...ant, data_abertura: null }));
            return
        }
        setCurso((ant: CursoItem) => ({ ...ant, data_abertura: new Date(e) }));
    }


    return (<>
        <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
        >
            {modal === null ? <ButtonSair cor={cor} uuid_turma={""} texto={"Voltar"} sair={sair} /> : ""}
        </Grid>
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
            style={{ marginBottom: "1rem" }}
        >

            <Grid item xs={11}>
                <div style={{ display: "flex" }}>
                    <GridSpanCenter>
                        <div className="centralizarIcone">
                            <FolderOpenIcon style={{ marginRight: ".5rem" }} />
                            <span>{trilha ? "TRILHA" : "CURSO"}</span></div>
                    </GridSpanCenter>
                </div>

                <GridAtividadeBorda cor={cor}>

                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <GridSpanDiv cor={cor}>
                                <div style={{ display: "inline-block" }}>
                                    <span style={{ paddingBottom: ".5rem" }}>

                                        <EditIcon style={{ marginRight: ".5rem" }} />
                                        {trilha ? "Nome da trilha" : "Nome do curso"}
                                    </span>
                                </div>
                            </GridSpanDiv>
                            <GridAtividade cor={cor}>
                                <Input status={checkNomeCurso && curso.nome.trim() === "" ? "error" : ""} value={curso.nome} onChange={_ => { if (curso.nome.trim().length > 0) { setCheckNomeCurso(true) } setCurso(() => ({ ...curso, nome: _.target.value })) }} style={{ boxShadow: 'black' }} minLength={1} showCount maxLength={120} className="cor" placeholder={trilha ? "Nome da trilha" : "Nome do curso"} />
                            </GridAtividade>
                        </Grid>


                        <Grid item xs={12}>
                            <GridSpanDiv cor={cor}>
                                <div style={{ display: "inline-block" }}>
                                    <span style={{ paddingBottom: ".5rem" }}>

                                        <ArticleIcon style={{ marginRight: ".5rem" }} />
                                        Descrição
                                    </span>
                                </div>
                            </GridSpanDiv>
                            <GridAtividade cor={cor}>
                                <TextArea value={curso.descricao} onChange={_ => setCurso(() => ({ ...curso, descricao: _.target.value }))} style={{ marginBottom: ".6rem" }} showCount maxLength={600} rows={4} className="cor" placeholder="Descrição" />
                            </GridAtividade>
                        </Grid>


                        <Grid item xs={12} >
                            <GridSpanDiv cor={cor}>
                                <div style={{ display: "inline-block" }}>
                                    <span style={{ paddingBottom: ".5rem", alignItems: "flex-end" }}>

                                        <StarBorderIcon style={{ marginRight: ".5rem", }} />
                                        Pontuação
                                    </span>
                                </div>
                            </GridSpanDiv>
                            <GridAtividade cor={cor}>
                                <InputNumber value={curso.pontuacao || null} onChange={_ => setCurso(() => ({ ...curso, pontuacao: _ }))} className="cor" placeholder="Pontuação" min={0} max={100} onBlur={(e: any) => {
                                    e.target.value = formatarNumero(e.target.value.replace(",", "."), 2);
                                }} style={{ width: "100%" }} />
                            </GridAtividade>
                        </Grid>

                        {trilha ? "" :
                            <Grid item xs={12}>
                                <GridSpanDiv cor={cor}>
                                    <div style={{ display: "inline-block" }}>
                                        <span style={{ paddingBottom: ".5rem", alignItems: "flex-end" }}>

                                            <AccessTimeIcon style={{ marginRight: ".5rem" }} />
                                            Status do curso
                                        </span>
                                    </div>
                                </GridSpanDiv>

                                <GridAtividade cor={cor}>
                                    <Grid container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center" spacing={2}>
                                        <Grid item xs={12}>
                                            <Select
                                                labelInValue
                                                value={curso.status}
                                                defaultValue={true}
                                                style={{ width: "100%" }}
                                                onChange={(_: any) => setCurso(() => ({ ...curso, status: _.value }))}
                                                options={[
                                                    {
                                                        value: true,
                                                        label: 'Aberto',
                                                    },
                                                    {
                                                        value: false,
                                                        label: 'Fechado',
                                                    },
                                                ]}
                                            />
                                        </Grid>


                                        {curso.status === false || curso.data_abertura ?
                                            <>
                                                <Grid item xs={true}>
                                                    <DatePicker
                                                        disabledDate={disabledDate}
                                                        value={curso.data_abertura ? dayjs(curso.data_abertura) : null}
                                                        onChange={handleChangeData}
                                                        format="DD/MM/YYYY"
                                                        className="cor"
                                                        locale={locale}
                                                        disabled={!data}
                                                        style={{ width: "100%" }}
                                                        dateRender={(current: any) => {
                                                            const selected = current && current.isSame(curso.data_abertura, "day");
                                                            const style = selected ? { backgroundColor: cor } : undefined;
                                                            const todayStyle = current && current.isSame(dayjs(), "day") ? { border: `1px solid ${cor}`, borderRadius: "4px" } : undefined;

                                                            return (
                                                                <div className="ant-picker-cell-inner" style={{ ...style, ...todayStyle, }}>
                                                                    {current.date()}
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <span>Abertura automática?</span> <Switch checked={data} onChange={_ => { setData(!data) }}></Switch >
                                                </Grid>
                                            </> : ""}
                                    </Grid>
                                </GridAtividade >


                            </Grid>

                        }


                    </Grid>

                </GridAtividadeBorda>

                <Grid item style={{ marginTop: "2rem" }}>
                    <Aula trilha={trilha} curso={curso} setModal={setModal} setCurso={setCurso} setCursos={setCursos} atividade={atividade} setAtividade={setAtividade} aula={aula} setAula={setAula} editar={editar === undefined ? true : editar} editarNovoCurso={editarNovoCurso} central={central} checkNomeCurso={checkNomeCurso} setCheckNomeCurso={setCheckNomeCurso} user={user} turma={turma} />
                </Grid>




            </Grid >
        </Grid >


    </>)
}

export default Curso_Criar;

