import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router';
import { Avatar, Paper } from '@mui/material'

const ITEM_HEIGHT = 48;

function MenuPerfil({ decodedToken }: any) {

    let options = [""];

    if (decodedToken?.realm_access?.roles.includes('diretor') || decodedToken?.realm_access?.roles.includes('admin')) {
        options = ["Cadastrar usuário", "Gerenciar usuário", "Perfil"]
    }
    else {
        options = ["Perfil"]
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event: any) => {
        setAnchorEl(event.target);
    };

    const handleClose = (e: number) => {
        if (options[e] && options[e].toLocaleLowerCase() === "cadastrar usuário") {
            navigate(`/cadastro`)
        }
        else if (options[e] && options[e].toLocaleLowerCase() === "gerenciar usuário") {
            navigate(`/gerenciar`)
        }
        else if (options[e] && options[e].toLocaleLowerCase() === "perfil") {
            navigate(`/perfil`)
        }

        setAnchorEl(null);
    };

    return (
        <div>
            {decodedToken?.realm_access?.roles.includes('diretor') || decodedToken?.realm_access?.roles.includes('admin') ?

                <b style={{ display: "flex", alignItems: "center", width: "100%" }}>Olá, {decodedToken.given_name.toUpperCase()} <Avatar component={Paper}
                    elevation={3} onClick={handleClick} style={{ marginLeft: ".8rem", border: "2.5px solid #568fb6", objectFit: "cover", cursor: "pointer" }} src={decodedToken?.foto ? decodedToken.foto : ""}></Avatar></b>
                :
                <b style={{ display: "flex", alignItems: "center", width: "100%" }}>Olá, {decodedToken.given_name.toUpperCase()} <Avatar component={Paper}
                    elevation={3} onClick={handleClick} style={{ marginLeft: ".8rem", border: "2.5px solid #568fb6", objectFit: "cover", cursor: "pointer" }} src={decodedToken?.foto ? decodedToken.foto : ""}></Avatar></b>
            }

            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 'auto',

                    },
                }}
            >
                {options.map((option: any, i: number) => (
                    <MenuItem key={option} selected={option === 'Pyxis'} onClick={_ => handleClose(i)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default React.memo(MenuPerfil)