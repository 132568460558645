import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RespostaEnviar from "./pages/resposta";
import { default as TurmaProfessor } from "./components/turma";
import jwtDecode from 'jwt-decode';
import { keycloak } from ".";
import sappens from './img/sappens.png';
import boneco from "./img/sair/boneco_sair.png"
import sair from "./img/sair/logout.png"
import Cursos from "./components/central_cursos";
import TurmaCuro from "./components/turma_curso";
import TurmaCursoAula from "./components/turma_curso_aula";
import { AuthToken } from "./components/types";
import RecuperarSenha from "./pages/recuperar_senha";
import Nav from "./components/Nav/Nav";
import MenuPerfil from "./components/menu";
import Cadastro from "./components/cadastro";
import GerenciarCadastro from "./components/gerenciar_cadastro";
import Perfil from "./components/perfil";
import { useState } from "react";
import Caminho from "./components/caminho";
import FormularioCadastro from "./pages/govtech";

function App() {

  const [decodedToken, setDecodedToken] = useState(keycloak.token && jwtDecode(keycloak.token) as AuthToken);

  const Header = ({ decodedToken }: any) => {
    return (
      <>
        <div className="logo" style={{ backgroundColor: "rgba(232, 232, 232, 1)", userSelect: "none", color: "black", height: "5rem", marginTop: "-.5rem" }}>

          <div>
            <img className="img" onClick={_ => window.location.href = "/"} style={{ cursor: "pointer" }} src={sappens} alt="Imagem de sappens" />
          </div>
          {decodedToken?.given_name !== undefined ?
            <div style={{ width: "auto" }}>
              <b style={{ display: "flex", alignItems: "center" }}>

                <MenuPerfil decodedToken={decodedToken} />

                <div style={{ display: "flex", cursor: "pointer", alignItems: "center", paddingLeft: ".5rem" }} onClick={_ => keycloak.logout()}>
                  <img style={{ margin: "0px", maxWidth: "35%", minWidth: "30px" }} src={boneco} alt="Imagem de sappens" />
                  <img style={{ margin: "0px", maxWidth: "35%" }} src={sair} alt="Imagem de sappens" />
                </div>
              </b>
            </div>
            : ""}
        </div >
      </>
    );
  };



  if (decodedToken !== undefined && decodedToken && (decodedToken?.realm_access?.roles.includes('professor') || decodedToken?.realm_access?.roles.includes('admin') || decodedToken?.realm_access?.roles.includes('coordenador') || decodedToken?.realm_access?.roles.includes('diretor'))) {

    return (
      <BrowserRouter>

        <Header decodedToken={decodedToken} />
        <ToastContainer />

        <Routes>
          <Route path="/" element={<TurmaProfessor professor={true} decodedToken={decodedToken} />} />

          {
            decodedToken?.realm_access?.roles.includes('admin') || decodedToken?.realm_access?.roles.includes('diretor') ?
              <>
                <Route path="/cadastro" element={<Cadastro professor={true} decodedToken={decodedToken} />} />
                <Route path="/gerenciar" element={<GerenciarCadastro decodedToken={decodedToken} />} />
              </>
              :
              ""
          }

          <Route path="/turma/:uuid_turma" element={<TurmaCuro professor={true} decodedToken={decodedToken} />} />
          <Route path="/trilha/:uuid_turma/:uuid_curso" element={<Caminho decodedToken={decodedToken} />} />
          <Route path="/perfil" element={<Perfil decodedToken={decodedToken} setDecodedToken={setDecodedToken} />} />
          <Route path="/turma/:uuid_turma/:uuid_curso" element={<TurmaCursoAula professor={true} user={"-1"} decodedToken={decodedToken} />} />
          <Route path="/resposta/:uuid_turma/:uuid_curso/:uuid_aula" element={<RespostaEnviar professor={true} decodedToken={decodedToken} />} />
          <Route path="/centralcursos" element={<Cursos professor={true} decodedToken={decodedToken} central={true} />} />
          <Route path="/centralcursos/:uuid_curso" element={<TurmaCursoAula professor={true} decodedToken={decodedToken} central={true} />} />
          <Route path="/centralcursos/resposta/:uuid_curso/:uuid_aula" element={<RespostaEnviar professor={true} central={true} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

      </BrowserRouter>
    );
  }
  else if (decodedToken !== undefined && decodedToken && decodedToken?.realm_access?.roles.includes('aluno') && decodedToken?.preferred_username) {
    return (

      <BrowserRouter>

        <Header decodedToken={decodedToken} />
        <ToastContainer />

        <Routes>
          <Route path="/" element={<TurmaProfessor professor={false} decodedToken={decodedToken} />} />
          <Route path="/perfil" element={<Perfil decodedToken={decodedToken} setDecodedToken={setDecodedToken} />} />
          <Route path="/turma/:uuid_turma" element={<TurmaCuro professor={false} decodedToken={decodedToken} />} />
          <Route path="/turma/:uuid_turma/:uuid_curso" element={<TurmaCursoAula professor={false} decodedToken={decodedToken} />} />
          <Route path="/trilha/:uuid_turma/:uuid_curso" element={<Caminho decodedToken={decodedToken} />} />
          <Route path="/resposta/:uuid_turma/:uuid_curso/:uuid_aula" element={<RespostaEnviar professor={false} decodedToken={decodedToken} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

      </BrowserRouter>


    );
  }
  else {
    return (<BrowserRouter>

      <ToastContainer />

      <Routes>
        <Route path="/" element={<Nav />} />
        <Route path="/recuperar" element={<RecuperarSenha />} />
        <Route path="/*" element={<Nav />} />
        <Route path="/govtech" element={<FormularioCadastro />} />
      </Routes>

    </BrowserRouter>)
  }

}

export default App;