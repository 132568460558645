import * as uuid from "uuid";
import moment from 'moment-timezone';
import imagem_07A717 from "../img/turmas/07A717.png"
import imagem_F25C1B from "../img/turmas/F25C1B.png"
import imagem_E02A2A from "../img/turmas/E02A2A.png"
import imagem_E11791 from "../img/turmas/E11791.png"
import imagem_B02EEE from "../img/turmas/B02EEE.png"
import imagem_5E34D6 from "../img/turmas/5E34D6.png"

export function formatarNumero(value: any, fix: number) {
    const number = parseFloat(value);
    if (isNaN(number)) {
        return '';
    }
    return number.toFixed(fix);
}

export interface IAulaCriar {
    uuid_aula?: string | undefined;
    editarNovoCurso?: boolean | undefined;
    central?: boolean | undefined;
    uuid_curso?: string;
    setAulaBack?: any
    curso?: CursoItem;
    nova_aula?: boolean;
    setModal: any
    setCursos?: any
    setCurso: any
    setAula: any
    setAtividade: any
    editar?: boolean | undefined;
    atividade: Array<Atividade>
    aula: AulaItem
}


class User {
    id_keycloak: string | undefined
}


export class AulaItem {
    id: string = uuid.v4();
    id_curso: string | undefined
    id_origem: string | undefined
    id_pessoa: string | undefined
    user: User | undefined
    nome: string = ""
    entrega: any = null
    data_entrega: Date | null = null
    timestamp: Date = new Date(new Date(moment().tz('America/Sao_Paulo').toString()));
    descricao: string = ""
    link: string = ""
    pontuacao: number | null = null
    status: boolean = true
    avaliacao: number = 0
    data_abertura: Date | null = null
    atividades: Array<Atividade> | [] = []
}

export interface ISelecao {
    menu: string
    selecionado: string
    cor: string
}

export interface IExpandir {
    atividade: Array<Atividade>;
    cor: string;
    setAtividade: any;
    index: number;
}

export class SubmissaoReposta {
    id_atividade: string = "";
    id_resposta: string | null = "";
    tempo: number = 0;
    mostrar: boolean = true;
}


export const newAulaItem = (id: string) => {
    let p = new AulaItem();
    p.id_curso = id;
    return p;
}

export const newAtividade = (id: string) => {
    let p = new Atividade();
    p.id_aula = id;


    p.timestamp = new Date(new Date(moment().tz('America/Sao_Paulo').toString()));
    return p;
}

export const lottieDefaultOptions = (animar: any) => {
    return {
        loop: true,
        autoplay: true,
        animationData: animar,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        }
    }
}

export function calcular_pontuacao(notas: any, respostas: any, pontuacao_total: any) {
    const total_notas = notas.reduce((sum: any, nota: any) => sum + nota, 0);
    const pontuacoes = [];


    for (let i = 0; i < respostas.length; i++) {
        const resposta = respostas[i];
        const proporcao_resposta = resposta / total_notas;
        const pontuacao = proporcao_resposta * pontuacao_total;
        pontuacoes.push(pontuacao);
    }

    const soma_pontuacoes = pontuacoes.reduce((sum, pontuacao) => sum + pontuacao, 0);
    const porcentagem = (soma_pontuacoes / pontuacao_total) * 100;

    if (Number.isNaN(porcentagem))
        return 0

    return porcentagem;
}

class CorTurma {
    hex: string = ""
    id: string = ""
}

export class Atividade {
    id: string = uuid.v4();
    id_aula: string = "";
    id_origem: string | undefined;
    nome: string = ""
    respostas: Array<Resposta> = []
    resposta_certa: string = ""
    timestamp: Date = new Date(new Date(moment().tz('America/Sao_Paulo').toString()));
    pergunta: string = ""
    nota: number | null = null
    status: boolean = true
}

export class ListaTurma {
    items: TurmaItem[] = [];
    page: number = 1;
    size: number = 25;
    total: number = 0;
}

export const convertToEmbedUrl = (url: any) => {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;

    if (youtubeRegex.test(url)) {
        let videoId = "";

        if (url.includes("youtu.be")) {
            videoId = url.substring(url.lastIndexOf("/") + 1);
        } else {
            videoId = url.match(/[?&]v=([^&]+)/)[1];
        }

        return `https://www.youtube.com/embed/${videoId}`;
    } else {
        return null; // URL não é válida para o YouTube
    }
}

export const validateLink = (str: any) => {
    if (/^(?:(?:https?:\/\/)|(?:www\.)|localhost|[\w.-]+(?:\.[\w\.-]+)+)(?:[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+)?$/g.test(str)) {
        return false
    } else {
        return true
    }
}

export class ListaAula {
    items: AulaItem[] = [];
    page: number = 1;
    size: number = 5;
    total: number = 0;
}

export class ListaCor {
    items: CorTurma[] = [];
    page: number = 1;
    size: number = 5;
    total: number = 0;
}

export interface IAtividade {
    atividade: Atividade;
    funcaoDeletar?: any;
    handleClickEditar?: any;
    cor: string;
    professor: boolean;
}

export interface IAula {
    aula: AulaItem;
    funcaoDeletar?: any;
    handleClickEditar?: any;
    cor: string;
    professor: boolean;
}


export interface AuthToken {
    given_name: string,
    preferred_username: string,
    realm_access: {
        roles: string[];
    },
    contratos: string[];
}

export function getNotAluno(decodedToken: any) {

    return decodedToken?.realm_access?.roles.includes('diretor') || decodedToken?.realm_access?.roles.includes('admin') || decodedToken?.realm_access?.roles.includes('coordenador')

}

export const getContratos = (decodedToken: any) => {
    if (decodedToken?.realm_access?.roles.includes('admin') || decodedToken?.realm_access?.roles.includes('coordenador') || decodedToken?.realm_access?.roles.includes('diretor'))
        return true
    return !decodedToken?.contratos?.includes('planaltina')
}

export class CursoItem {
    nome: string = ""
    aulas: number = 0;
    id: string = uuid.v4();
    id_turma: string | null | undefined = uuid.v4();
    id_origem: string | undefined;
    id_pessoa: string | undefined;
    descricao: string = "";
    pontuacao: number | null = null;
    status: boolean = true;
    data_abertura: Date | null = null;
    timestamp: Date = new Date(new Date(moment().tz('America/Sao_Paulo').toString()));
    central: boolean = false;
    trilha: any = null;
}

export interface MenuTurma {
    uuidTurma: string;
    professor: boolean;
    cor: string;
    turmaNome: string;
    selecionado: string;
    setCurso: any;
    curso: CursoItem;
    criarNovoCurso: any;
}

export class ListaAtividade {
    items: Atividade[] = [];
    page: number = 1;
    size: number = 5;
    total: number = 0;
    turma: string = "";
}

export class ListaCurso {
    items: CursoItem[] = [];
    page: number = 1;
    size: number = 20;
    total: number = 0;
    turma: string = "";
}

export class Resposta {
    id: string = uuid.v4();
    resposta: string = "";
    justificativa: string | null = null;
    id_origem: string | undefined;
    id_atividade: string = "";
    timestamp: Date = new Date(new Date(moment().tz('America/Sao_Paulo').toString()));
}

class Tipo {
    tipo: string = "";
}

export class Pessoa {
    id: string = uuid.v4();
    id_keycloak: string = "";
    nome: string = "";
    tipo: Tipo[] = [];
    foto: string | null = null;
    timestamp: Date = new Date(new Date(moment().tz('America/Sao_Paulo').toString()));
}

export class TurmaItem {
    id: string = uuid.v4();
    nome: string = "";
    cor: CorTurma = new CorTurma();
    id_cor?: string;
    id_escola: string = "";
    id_pessoa: string = "";
    tipo: any;
}

interface CorObj {
    [key: string]: string;
}

export const imagem_turma_listagem: CorObj = {
    "#07A717": imagem_07A717,
    "#F25C1B": imagem_F25C1B,
    "#E02A2A": imagem_E02A2A,
    "#E11791": imagem_E11791,
    "#B02EEE": imagem_B02EEE,
    "#5E34D6": imagem_5E34D6,
}
