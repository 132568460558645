import { Atividade, AulaItem, CursoItem, TurmaItem, getContratos, lottieDefaultOptions } from "../types";
import sucesso from '../../img/lotties/Animation - 1702478317412.json';
import trancado from '../../img/lotties/Animation - 1702479591513.json';
import Lottie from 'react-lottie';
import { Avatar, Dialog, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../axios/axios";
import { CorButton } from "../cor";
import { Button, Modal } from "antd";
import { Button as ButtonMui } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Aula_Item from "../atividade/atividade";
import Aula from "../aula";
import FundoTurma from "../atividade/fundo";
import Selecao from "../atividade/selecao";
import Erro from "../erro";
import carregando from '../../img/lotties/98432-loading.json';
import pergunta from '../../img/lotties/Animation - 1703024961701.json';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';

export default function Caminho({ decodedToken }: any) {
    const [matriz, setMatriz] = useState([
        [-1, -1, -1, -1, -1],
        [-1, -1, -1, -1, -1],
        [-1, -1, -1, -1, -1],
        [-1, -1, -1, -1, -1],
    ]);

    const [aulaMat, setAulaMat] = useState<any>();
    const [aulaItem, setAulaItem] = useState<any>({});
    const navigate = useNavigate()
    const [aulas, setAula] = useState<any>();
    const [open, setOpen] = useState(false);
    const [uuid_aula, setUuidAula] = useState();
    const [aulaNovo, setAulaNovo] = useState<AulaItem>(new AulaItem());
    const [atividade, setAtividade] = useState<Atividade[]>([])
    const [curso, setCurso] = useState<CursoItem>(new CursoItem());
    const [xy, setXy] = useState<any>([0, 0]);
    const [modal, setModal] = useState(false);
    let { uuid_turma, uuid_curso } = useParams();
    const [turma, setTurma] = useState<TurmaItem>();
    const [cor, setCor] = useState("");
    const [loading, setLoading] = useState(true);
    const [erro, setErro] = useState(false);
    const [cursoItem, setCursoItem] = useState<any>(false);
    const [salvar, setSalvar] = useState(false);
    const [editar, setEditar] = useState(false);
    const [admin, setAdmin] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const funcaoDeletar = () => {
        toast.info("Deletando aula");
        api.delete("aula/" + aulaMat[xy[0]][xy[1]].id).then(() => {
            toast.success("Aula deleta");
            matriz[xy[0]][xy[1]] = 0;
            cursoItem.trilha = matriz;
            aulaMat[xy[0]][xy[1]] = null
            setMatriz(matriz)
            setAulaMat(aulaMat)
            setOpen(false)
            api.patch("curso/" + cursoItem.id, JSON.stringify(cursoItem));
        }).catch(() => toast.error("Erro ao deletar aula"));

    }



    useEffect(() => {
        Promise.all([
            api.get(`/aula/list/${uuid_turma}/${uuid_curso}?size=99999`),
            api.get(`/turma/${uuid_turma}`),
            api.get(`/curso/${uuid_curso}`),
        ]).then(async ([r, r2, r3]) => {

            await Promise.all(r.data.items.map((_: any) => api.get(`resposta/${_.id}`),)).then(
                (_: any) => _.map((item: any, j: number) => r.data.items[j].entrega = item.data)
            ).then(() => {
                setAula(r.data);
                if (!r3.data.trilha) {
                    throw new Error("A trilha não está presente nos dados.");
                }

                let aulas = [...r.data.items]
                let mat: { [key: string]: { [key: string]: any } } = {};
                aulas.map((a: any) => {
                    if (a?.xy) {
                        if (mat.hasOwnProperty(a?.xy[0]) === false)
                            mat[a?.xy[0]] = {}
                        mat[a?.xy[0]][a?.xy[1]] = a;
                    }

                })


                setTurma(r2.data)
                setCor(r2.data.cor.hex);


                setAulaMat(mat);

                let adm = ((decodedToken?.realm_access?.roles.includes('admin') || (r2.data?.tipo === 'professor' || r2.data?.tipo === 'coordenador' || r2.data?.tipo === 'diretor') && getContratos(decodedToken)) && r2.data?.tipo !== 'aluno') && ((r2.data?.tipo !== 'aluno' && getContratos(decodedToken)));
                let primeiro: any = true || [];
                if (!adm) {
                    for (let x = 0; x < r3.data.trilha.length; x++) {
                        if (x % 2 === 0)
                            for (let y = 0; y < r3.data.trilha[x].length; y++) {
                                if (r3.data.trilha[x][y] != 0 && r3.data.trilha[x][y] != -1) {

                                    let aul = mat[x][y];


                                    if (aul.entrega) {
                                        r3.data.trilha[x][y] = 1;
                                    }
                                    else {
                                        r3.data.trilha[x][y] = 2;
                                    }
                                    if (primeiro === true && !aul.entrega) {
                                        primeiro = [x, y];
                                        r3.data.trilha[x][y] = 3;
                                    }

                                }

                            }

                        else
                            for (let y = r3.data.trilha[x].length - 1; y >= 0; y--) {
                                if (r3.data.trilha[x][y] != 0 && r3.data.trilha[x][y] != -1) {

                                    let aul = mat[x][y];

                                    if (aul.entrega) {
                                        r3.data.trilha[x][y] = 1;
                                    }
                                    else {
                                        r3.data.trilha[x][y] = 2;
                                    }
                                    if (primeiro === true && !aul.entrega) {
                                        primeiro = [x, y];
                                        r3.data.trilha[x][y] = 3;
                                    }

                                }

                            }
                    }
                }

                setMatriz(r3.data.trilha)
                setCursoItem(r3.data)
                setAdmin(adm)
            })


        }).then(() => setLoading(false)).catch(() => setErro(true))

    }, [])

    if (erro)
        return <><Erro /></>

    if (loading)
        return (<>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "70vh" }}
            >
                <Lottie
                    options={lottieDefaultOptions(carregando)}
                    height={"40%"}
                    width={"40%"}
                />
            </Grid>
        </>)

    const adicionarElemento = () => {
        const lastRow = matriz[matriz.length - 1];
        if (matriz[matriz.length - 1].indexOf(-1) === -1) {
            const novoElemento = matriz.length % 2 ? [-1, -1, -1, -1, 0] : [0, -1, -1, -1, -1];
            setMatriz([...matriz, novoElemento]);
        }
        else if (lastRow.indexOf(-1) !== -1) {

            const replaceFromLeft = matriz.length % 2 === 1;

            const modifiedRow = [...lastRow];

            if (replaceFromLeft) {
                for (let i = 0; i < modifiedRow.length; i++) {
                    if (modifiedRow[i] === -1) {
                        modifiedRow[i] = 0;
                        break;
                    }
                }
            } else {
                for (let i = modifiedRow.length - 1; i >= 0; i--) {
                    if (modifiedRow[i] === -1) {
                        modifiedRow[i] = 0;
                        break;
                    }
                }
            }

            const newMatriz = [...matriz];
            newMatriz[newMatriz.length - 1] = modifiedRow;

            setMatriz(newMatriz);
        }
    };


    const removerElemento = () => {
        const lastRow = matriz[matriz.length - 1];

        const replaceFromLeft = matriz.length % 2 == 0;

        const modifiedRow = [...lastRow];

        if (replaceFromLeft) {
            for (let i = 0; i < modifiedRow.length; i++) {
                if (modifiedRow[i] !== -1) {
                    if (modifiedRow[i] > 0) {
                        if (aulaMat.hasOwnProperty(matriz.length - 1) && aulaMat[matriz.length - 1].hasOwnProperty(i)) {
                            let aula = aulaMat[matriz.length - 1][i];
                            toast.info("Deletando aula")
                            api.delete("aula/" + aula.id).then(() => toast.success("Aula deleta")).catch(() => toast.error("Erro ao deletar aula"));
                            aulaMat[matriz.length - 1][i] = null;
                            matriz[matriz.length - 1][i] = -1;
                            cursoItem.trilha = matriz
                            api.patch("curso/" + cursoItem.id, JSON.stringify(cursoItem));

                            setAulaMat(aulaMat)
                        }
                    }
                    modifiedRow[i] = -1;
                    break;
                }
            }
        } else {
            for (let i = modifiedRow.length - 1; i >= 0; i--) {
                if (modifiedRow[i] !== -1) {
                    if (modifiedRow[i] > 0) {
                        if (aulaMat.hasOwnProperty(matriz.length - 1) && aulaMat[matriz.length - 1].hasOwnProperty(i)) {
                            let aula = aulaMat[matriz.length - 1][i];
                            toast.info("Deletando aula");
                            api.delete("aula/" + aula.id).then(() => toast.success("Aula deleta")).catch(() => toast.error("Erro ao deletar aula"));
                            aulaMat[matriz.length - 1][i] = null;
                            matriz[matriz.length - 1][i] = -1;
                            cursoItem.trilha = matriz;
                            api.patch("curso/" + cursoItem.id, JSON.stringify(cursoItem));

                            setAulaMat(aulaMat)
                        }
                    }
                    modifiedRow[i] = -1;
                    break;
                }
            }
        }

        const newMatriz = [...matriz]

        newMatriz[newMatriz.length - 1] = modifiedRow;

        if (newMatriz.length !== 1 && newMatriz[newMatriz.length - 1].indexOf(1) === -1 && newMatriz[newMatriz.length - 1].indexOf(0) === -1 && newMatriz[newMatriz.length - 1].indexOf(2) === -1 && newMatriz[newMatriz.length - 1].indexOf(3) === -1)

            setMatriz(matriz.slice(0, -1));
        else {
            setMatriz(newMatriz);
        }


    };


    const calcularCoordenadas = (i: any, j: any) => {
        const tamanhoCirculo = 40;
        const espacamento = 80;
        const offsetX = 50;
        const offsetY = 50;

        return {
            cx: offsetX + j * espacamento,
            cy: offsetY + i * espacamento,
            r: tamanhoCirculo / 2,
            fill: 'white',
        };
    };
    const getPersonage = () => {
        for (let i = 0; i < matriz.length; i++) {
            for (let j = 0; j < matriz[i].length; j++) {
                if (matriz[i][j] === 3) {

                    return [i, j]
                }

            }
        }

    }

    const criarConexoes = () => {
        const conexoes = [];
        let personagem: any;

        personagem = getPersonage();

        for (let i = 0; i < matriz.length; i++) {
            for (let j = 0; j < matriz[i].length; j++) {
                if (matriz[i][j] !== -1) {
                    if (matriz[i][j + 1] !== -1 && j + 1 < matriz[i].length) {
                        conexoes.push({
                            x1: calcularCoordenadas(i, j).cx,
                            y1: calcularCoordenadas(i, j).cy,
                            x2: calcularCoordenadas(i, j + 1).cx,
                            y2: calcularCoordenadas(i, j + 1).cy,
                            stroke: "black",
                            strokeDasharray: '5,5',
                        });

                    }

                    else if (i + 1 < matriz.length && i % 2 === 0) {
                        conexoes.push({
                            x1: calcularCoordenadas(i, j).cx,
                            y1: calcularCoordenadas(i, j).cy,
                            x2: calcularCoordenadas(i + 1, j).cx,
                            y2: calcularCoordenadas(i + 1, j).cy,
                            stroke: "black",
                            strokeDasharray: '5,5'
                        });

                    }

                    if (i + 1 < matriz.length && matriz[i + 1][j] !== -1 && j === 0 && i % 2 === 1) {
                        conexoes.push({
                            x1: calcularCoordenadas(i, j).cx,
                            y1: calcularCoordenadas(i, j).cy,
                            x2: calcularCoordenadas(i + 1, j).cx,
                            y2: calcularCoordenadas(i + 1, j).cy,
                            stroke: "black",
                            strokeDasharray: '5,5'
                        });

                    }
                }
            }
        }

        return conexoes;
    };

    const postApi = () => {
        if (matriz) {
            cursoItem.trilha = matriz;

            toast.info("Editando trilha")
            setSalvar(true)
            api.patch(`curso/${cursoItem.id}`, JSON.stringify(cursoItem)).then(_ => {
                toast.success("Trilha editada")
                setSalvar(false)
                setEditar(false)

            }).catch(_ => { toast.error("Erro ao editar trilha"); setSalvar(false) })

        }

    }

    const handleClickEditar = (item: any) => {
        setUuidAula(item.id)
        setAulaNovo(item)
        setAtividade(item.atividades)
        setModal(true)
    }


    return (
        <>


            <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                <FundoTurma cor={cor} turmaNome={turma?.nome ? turma?.nome : ""} />
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={2}
                    spacing={1} >
                    <Grid item xs={12} sm={4} zeroMinWidth  >
                        <Selecao setTela={undefined} cor={cor} menu="TRILHA" selecionado="TRILHA" />
                    </Grid>

                </Grid>
            </Grid >

            <Modal
                width="100%"
                centered
                footer={null}
                open={modal}
                style={{ zIndex: 1 }}
                onCancel={() => setModal(false)}
            >
                <React.Fragment key={aulaItem.id}>
                    <Aula trilha={true} setAulaItem={setAulaItem} cursoTrilha={cursoItem} dataAberta={aulaNovo.data_abertura ? true : false} uuid_aula={uuid_aula} setAula={setAulaNovo} aula={aulaNovo} setModal={setModal} setCurso={setCursoItem} nova_aula={false} setAulaBack={setAula} atividade={atividade} setAtividade={setAtividade} turma={turma} xy={xy} setAulaMat={setAulaMat} aulaMat={aulaMat} matriz={matriz} setMatriz={setMatriz} />
                </React.Fragment>
            </Modal>

            <Modal
                closable={false}
                centered
                open={open}
                footer={null}
                onCancel={() => setOpen(false)}
                style={{ zIndex: 1 }}
                className="custom-modal"
            >

                <React.Fragment key={aulaItem.id}>
                    <Aula_Item editar={editar} aula={aulaItem} funcaoDeletar={funcaoDeletar} cor={cor} professor={true} handleClickEditar={handleClickEditar} central={false} turma={turma} decodedToken={decodedToken} style={true} xy={xy} setAulaMat={setAulaMat} />
                </React.Fragment >
            </Modal >
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginBottom: "1rem" }}>
                <Grid item>
                    <CorButton cor={cor}>
                        <ButtonMui onClick={() => navigate("/turma/" + turma?.id)} size="small" >
                            Voltar
                        </ButtonMui>
                    </CorButton>
                </Grid>
            </div>


            <Grid item xs={11} style={{ marginBottom: "1.5rem" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={8}>
                        <div style={{ position: "relative", border: "solid 1px " + cor, borderRadius: ".5rem" }}>
                            <div style={{ position: "absolute", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "white", padding: "0px 1rem 0px 1rem", color: cor }}>
                                <p style={{ fontSize: "17px" }}>Sobre</p>
                            </div>
                            <div style={{ padding: "20px", textAlign: "justify" }}>
                                <p>{curso.descricao}</p>
                            </div>
                        </div>

                    </Grid>

                </Grid>
            </Grid>

            <Grid
                container
                justifyContent="center"
                alignItems="center" style={{ marginBottom: "1rem", }}>
                <Grid item xs={10} className="sombra" style={{ padding: "1rem", borderRadius: '1rem', width: "800px" }} >
                    {admin ?
                        <Grid item xs={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"

                            >
                                <Grid>
                                    <ButtonMui disabled={editar} onClick={(e) => setEditar(true)} size='small' variant="contained" >
                                        <EditIcon></EditIcon>
                                    </ButtonMui>
                                </Grid>
                            </Grid>
                        </Grid>
                        : ""}

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item xs={12} style={{ marginBottom: "2.5rem" }}>
                            <svg viewBox={`0 0 410 ${80 * matriz.length}`} preserveAspectRatio="xMidYMid meet">
                                {criarConexoes().map((conexao, index) => (
                                    <line key={index} {...conexao} />
                                ))}
                                {matriz.map((linha, i) =>
                                    linha.map((celula, j) => (
                                        <g key={`${i}-${j}`}
                                            style={{ cursor: (matriz[i][j] >= -1 && editar) || matriz[i][j] === 3 || matriz[i][j] === 1 || matriz[i][j] === 4 ? "pointer" : "" }}
                                            onClick={(e) => {

                                                e.stopPropagation();
                                                setXy([i, j])
                                                if (aulaMat && aulaMat.hasOwnProperty(i) && aulaMat[i].hasOwnProperty(j) && (matriz[i][j] === 1 || matriz[i][j] === 3 || matriz[i][j] === 4 || matriz[i][j] === 2)) {
                                                    if ((editar || matriz[i][j] === 3 || matriz[i][j] === 1) || matriz[i][j] === 4) {
                                                        setAulaItem(aulaMat[i][j]);
                                                        setUuidAula(aulaMat[i][j].id)
                                                        handleClickOpen()
                                                    }
                                                }
                                                else if (editar && matriz[i][j] === 0) {
                                                    setUuidAula(undefined); setModal(true); setAulaNovo(new AulaItem()); setAtividade([])
                                                }
                                            }
                                            }>

                                            {editar && celula === 0 && (
                                                <circle {...calcularCoordenadas(i, j)}
                                                    fill="white"
                                                    stroke={cor}
                                                    strokeWidth="1" />
                                            )}

                                            {celula === 1 && (
                                                <circle {...calcularCoordenadas(i, j)} fill="white" />
                                            )}
                                            {celula === 4 && (
                                                <circle {...calcularCoordenadas(i, j)} fill="white" />
                                            )}
                                            {celula === 1 && (
                                                <foreignObject
                                                    x={calcularCoordenadas(i, j).cx - 35}
                                                    y={calcularCoordenadas(i, j).cy - 75}
                                                    width="100"
                                                    height="120"
                                                    style={{ width: "70px" }}
                                                >
                                                    <Lottie
                                                        options={lottieDefaultOptions(sucesso)}
                                                        height={150}
                                                        width={150}
                                                        style={{ marginLeft: '-40px' }}
                                                    />
                                                </foreignObject>
                                            )}
                                            {celula === 2 && (
                                                <circle {...calcularCoordenadas(i, j)} fill="white" />
                                            )}
                                            {celula === 2 && (
                                                <foreignObject
                                                    x={calcularCoordenadas(i, j).cx - 25}
                                                    y={calcularCoordenadas(i, j).cy - 25}
                                                    width="50"
                                                    height="50"
                                                >
                                                    <Lottie
                                                        options={lottieDefaultOptions(trancado)}
                                                        height={50}
                                                        width={50}
                                                    />
                                                </foreignObject>
                                            )}


                                            {celula === 3 && (
                                                <foreignObject
                                                    x={calcularCoordenadas(i, j).cx - (celula % 2 === 0 ? 25 : 30)}
                                                    y={calcularCoordenadas(i, j).cy - (celula % 2 === 1 ? 25 : 25)}
                                                    width="60"
                                                    height="60"
                                                >
                                                    <Avatar src={decodedToken?.foto} component={Paper}
                                                        elevation={1} style={{ border: ".1rem solid " + cor }} sx={{ width: 50, height: 50 }} />

                                                </foreignObject>
                                            )}

                                            {celula === 4 && (
                                                <foreignObject
                                                    // style={{ background: "red" }}
                                                    x={calcularCoordenadas(i, j).cx - 30}
                                                    y={calcularCoordenadas(i, j).cy - 35}
                                                    width="60"
                                                    height="70"
                                                >
                                                    <Lottie
                                                        options={lottieDefaultOptions(pergunta)}
                                                        height={70}
                                                        width={50}
                                                    />
                                                </foreignObject>
                                            )}
                                        </g>
                                    ))
                                )}
                            </svg>

                        </Grid>
                    </Grid>
                    {editar ?
                        <Grid container direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}>
                            {matriz[0]?.filter((e) => e === -1).length < 4 ?

                                <Grid item>
                                    <Button onClick={(e) => removerElemento()} type="primary" danger>
                                        Remover passo
                                    </Button>
                                </Grid>
                                : ""}
                            <Grid item>
                                <Button onClick={(e) => adicionarElemento()} type="primary">
                                    Novo passo
                                </Button>
                            </Grid>
                            <Grid item>
                                <ButtonMui startIcon={<CancelIcon />} onClick={(e) => setEditar(false)} size='small' color="error" variant="contained" >
                                    Cancelar
                                </ButtonMui>
                            </Grid>
                            <Grid item>
                                <LoadingButton loading={salvar} loadingPosition="end" endIcon={<SaveIcon />} disabled={salvar} size='small' onClick={() => postApi()} variant="contained" >
                                    Salvar
                                </LoadingButton>
                            </Grid>


                        </Grid>
                        : ""
                    }


                </Grid>

            </Grid >
        </>
    );
};

