import * as React from 'react';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import { LoadingButton } from '@mui/lab';
import PersonIcon from '@mui/icons-material/Person';
import foto_aluno1 from "../../img/perfil/aluno/avatar-(1).jpg"
import foto_aluno2 from "../../img/perfil/aluno/avatar-(10).jpg"
import foto_aluno3 from "../../img/perfil/aluno/avatar-(11).jpg"
import foto_aluno4 from "../../img/perfil/aluno/avatar-(12).jpg"
import foto_aluno5 from "../../img/perfil/aluno/avatar-(13).jpg"
import foto_aluno6 from "../../img/perfil/aluno/avatar-(14).jpg"
import foto_aluno7 from "../../img/perfil/aluno/avatar-(15).jpg"
import foto_aluno8 from "../../img/perfil/aluno/avatar-(16).jpg"
import foto_aluno9 from "../../img/perfil/aluno/avatar-(17).jpg"
import foto_aluno10 from "../../img/perfil/aluno/avatar-(18).jpg"
import foto_aluno11 from "../../img/perfil/aluno/avatar-(19).jpg"
import foto_aluno12 from "../../img/perfil/aluno/avatar-(2).jpg"
import foto_aluno13 from "../../img/perfil/aluno/avatar-(20).jpg"
import foto_aluno14 from "../../img/perfil/aluno/avatar-(21).jpg"
import foto_aluno15 from "../../img/perfil/aluno/avatar-(22).jpg"
import foto_aluno16 from "../../img/perfil/aluno/avatar-(23).jpg"
import foto_aluno17 from "../../img/perfil/aluno/avatar-(24).jpg"
import foto_aluno18 from "../../img/perfil/aluno/avatar-(25).jpg"
import foto_aluno19 from "../../img/perfil/aluno/avatar-(26).jpg"
import foto_aluno20 from "../../img/perfil/aluno/avatar-(27).jpg"
import foto_aluno21 from "../../img/perfil/aluno/avatar-(28).jpg"
import foto_aluno22 from "../../img/perfil/aluno/avatar-(29).jpg"
import foto_aluno23 from "../../img/perfil/aluno/avatar-(3).jpg"
import foto_aluno24 from "../../img/perfil/aluno/avatar-(30).jpg"
import foto_aluno25 from "../../img/perfil/aluno/avatar-(4).jpg"
import foto_aluno26 from "../../img/perfil/aluno/avatar-(5).jpg"
import foto_aluno27 from "../../img/perfil/aluno/avatar-(6).jpg"
import foto_aluno28 from "../../img/perfil/aluno/avatar-(7).jpg"
import foto_aluno29 from "../../img/perfil/aluno/avatar-(8).jpg"
import foto_aluno30 from "../../img/perfil/aluno/avatar-(9).jpg"

import foto_professor1 from "../../img/perfil/professor/P01.jpg"
import foto_professor2 from "../../img/perfil/professor/P02.jpg"
import foto_professor3 from "../../img/perfil/professor/P03.jpg"
import foto_professor4 from "../../img/perfil/professor/P04.jpg"
import foto_professor5 from "../../img/perfil/professor/P05.jpg"
import foto_professor6 from "../../img/perfil/professor/P06.jpg"
import foto_professor7 from "../../img/perfil/professor/P07.jpg"
import foto_professor8 from "../../img/perfil/professor/P08.jpg"
import foto_professor9 from "../../img/perfil/professor/P09.jpg"
import foto_professor10 from "../../img/perfil/professor/P10.jpg"
import foto_professor11 from "../../img/perfil/professor/P11.jpg"

import { Avatar, AvatarGroup } from '@mui/material';
import { Grid } from '@mui/material'
import { useState } from 'react';
import { Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CameraComponent from './webcam';

export default function EscolherFoto({ esperar, setfotoPerfil, decodedToken }: any) {
  const [open, setOpen] = useState(false);
  const [imagem, setImagem] = useState<any>(null);
  const [openImagem, setOpenImagem] = React.useState(false);
  const [avatares, setAvatares] = React.useState<any>([]);

  const fotos =
    decodedToken?.realm_access?.roles.includes('aluno') ? [foto_aluno1, foto_aluno2, foto_aluno3, foto_aluno4, foto_aluno5, foto_aluno6, foto_aluno7, foto_aluno8, foto_aluno9, foto_aluno10, foto_aluno11, foto_aluno12, foto_aluno13, foto_aluno14, foto_aluno15, foto_aluno16, foto_aluno17, foto_aluno18, foto_aluno19, foto_aluno20, foto_aluno21, foto_aluno22, foto_aluno23, foto_aluno24, foto_aluno25, foto_aluno26, foto_aluno27, foto_aluno28, foto_aluno29, foto_aluno30] :
      [foto_professor1, foto_professor2, foto_professor3, foto_professor4, foto_professor5, foto_professor6, foto_professor7, foto_professor8, foto_professor9, foto_professor10, foto_professor11]


  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {

    let avatares = [...fotos].sort(() => Math.random() - 0.5);
    setAvatares(avatares.slice(0, 7));
  }, []);



  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}>
        <Grid item>
          <AvatarGroup
            total={fotos.length}
            max={50}

          >

            {avatares?.map((avatar: any, index: any) => (
              <Avatar onClick={() => setOpen(true)} key={index} alt={`Avatar ${index + 1}`} src={avatar} />
            ))}

          </AvatarGroup>
        </Grid>

        <Grid item>
          <LoadingButton loading={esperar} loadingPosition="start"
            startIcon={<PersonIcon />} disabled={esperar} onClick={handleOpen} size='small' variant="contained" >
            Selecionar
          </LoadingButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            slots={{ backdrop: StyledBackdrop }}
          >
            <ModalContent sx={style}>
              <h2 id="parent-modal-title" className="modal-title">
                Selecione um avatar
              </h2>

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                style={{ marginTop: "-.5rem", marginBottom: ".5rem" }}
              >

                {fotos?.map((e: any, i: any) => {
                  return <Avatar key={i} style={{ marginRight: ".5rem", marginTop: ".5rem", cursor: "pointer" }} onClick={() => { setImagem(e); setOpenImagem(true) }} alt={`Avatar ${i}`} src={e} />
                })}

              </Grid>
              <ChildModal imagem={imagem} setOpenImagem={setOpenImagem} openImagem={openImagem} setOpen={setOpen} setfotoPerfil={setfotoPerfil} />
            </ModalContent>
          </Modal>
        </Grid>


        <Grid item xs={true}>
          <CameraComponent esperar={esperar} setfotoPerfil={setfotoPerfil} />
        </Grid>
      </Grid>

    </>
  );
}

function ChildModal({ imagem, setOpenImagem, openImagem, setOpen, setfotoPerfil }: any) {

  const handleOpen = () => {
    setOpenImagem(true);
  };
  const handleClose = () => {
    setOpenImagem(false);
  };

  return (
    <React.Fragment>
      <Button startIcon={<CancelIcon />} color="error" variant="contained" onClick={() => setOpen(false)}>Cancelar</Button>
      <Modal
        open={openImagem}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={[style, { width: '240px' }]}>
          <h2 id="child-modal-title" className="modal-title">
            Avatar
          </h2>
          <img src={imagem} alt="avatar" />
          <Button startIcon={<PersonIcon />} variant="contained" onClick={() => { handleClose(); setfotoPerfil({ data: window.location.href.replace("/perfil", "") + imagem, id: -1, nome: imagem.split('/')[imagem.split('/').length - 1] }); setOpen(false) }}>Confirmar</Button>
          <Button startIcon={<CancelIcon />} variant="contained" color="error" onClick={handleClose}>Cancelar</Button>
        </ModalContent>
      </Modal>
    </React.Fragment >
  );
}

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'MuiBackdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const blue = {
  200: '#99CCFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0066CC',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);

const TriggerButton = styled(Button)(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &:active {
    background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
    outline: none;
  }
`,
);

const ModalButton = styled(Button)(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  background-color: ${blue[500]};
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: 1px solid ${blue[500]};
  box-shadow: 0 2px 1px ${theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(45, 45, 60, 0.2)'
    }, inset 0 1.5px 1px ${blue[400]}, inset 0 -2px 1px ${blue[600]};

  &:hover {
    background-color: ${blue[600]};
  }

  &:active {
    background-color: ${blue[700]};
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
    outline: none;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none;
    &:hover {
      background-color: ${blue[500]};
    }
  }
`,
);
