import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { useNavigate, useParams } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { CorButton } from "../cor";
import Aula_Item from "../atividade/atividade";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import api from "../../axios/axios";
import Aula from "../aula";
import React from "react";
import { toast } from "react-toastify";
import { Atividade, ListaAula, CursoItem, AulaItem, TurmaItem, lottieDefaultOptions, getContratos } from "../types";
import Erro from "../erro";
import Lottie from "react-lottie";
import carregando from '../../img/lotties/98432-loading.json';
import Paginacao from "../paginacao";
import banners_gif_pagina_aluno from "../../img/gif_img/banners_gif_pagina02_alunos.gif";
import banners_gif_pagina_professor from "../../img/gif_img/banners_gif_so_videos.gif";

function TurmaCursoAula({ professor, central, decodedToken }: any) {

    const navigate = useNavigate();
    const { uuid_turma, uuid_curso } = useParams();
    const [modal, setModal] = useState(false);
    const [aula, setAula] = useState<ListaAula>();
    const [cursoNovo, setCursoNovo] = useState<CursoItem>(new CursoItem());
    const [aulaNovo, setAulaNovo] = useState<AulaItem>(new AulaItem());
    const [atividade, setAtividade] = useState<Atividade[]>([])
    const [turma, setTurma] = useState<TurmaItem>();
    const [curso, setCurso] = useState<CursoItem>(new CursoItem());
    const [uuid_aula, setUuidAula] = useState();
    const [loading, setLoading] = useState(true)
    const [erro, setErro] = useState(false);
    const [pessoa, setPessoa] = useState<any>({});

    const buscarPaginacao = async (pagina: number = 1) => {
        if (uuid_turma) {
            Promise.all([
                api.get(`/aula/list/${uuid_turma}/${uuid_curso}?page=${pagina}&size=${aula?.size}`),
                api.get(`/turma/${uuid_turma}`),
                api.get(`/curso/${uuid_curso}`),
                api.get('pessoa/username'),
            ]).then(([r1, r2, r3, p]) => {

                if (r3.data.trilha)
                    throw ("")

                setPessoa(p.data)

                Promise.all(r1.data.items.map((_: any) => api.get(`resposta/${_.id}`),)).then(
                    (_: any) => _.map((item: any, j: number) => r1.data.items[j].entrega = item.data)
                ).then(_ => {
                    setAula(r1.data);
                    setTurma(r2.data);
                    setCurso(r3.data);
                    setLoading(false);

                }).catch(_ => {
                    throw ("")
                }
                )

            }).catch(_ => {
                setErro(true)
            })
        }
        else {
            Promise.all([
                api.get(`/aula/list/${uuid_turma}/${uuid_curso}?page=${pagina}&size=${aula?.size}`),
                api.get(`/curso/${uuid_curso}`),

            ]).then(([r1, r2]) => {
                if (r2.data.trilha)
                    throw ("")
                setAula(r1.data);

                setCurso(r2.data);
                setLoading(false)
            }).catch(_ => {
                setErro(true)
            })

        }


    }

    useEffect(() => {
        if (uuid_turma) {

            Promise.all([
                api.get(`/aula/list/${uuid_turma}/${uuid_curso}`),
                api.get(`/turma/${uuid_turma}`),
                api.get(`/curso/${uuid_curso}`)
            ]).then(([r1, r2, r3]) => {

                if (r3.data.trilha)
                    throw ("")
                Promise.all(r1.data.items.map((_: any) => api.get(`resposta/${_.id}`),)).then(
                    (_: any) => _.map((item: any, j: number) => r1.data.items[j].entrega = item.data)
                ).then(_ => {
                    setAula(r1.data);
                    setTurma(r2.data);
                    setCurso(r3.data);
                    setLoading(false);
                }).catch(_ => {
                    setErro(true);
                })

            }).catch(_ => {
                setErro(true);
            })
        }
        else {
            Promise.all([
                api.get(`/aula/list/${uuid_turma}/${uuid_curso}`),
                api.get(`/curso/${uuid_curso}`)
            ]).then(([r1, r2]) => {
                if (r2.data.trilha)
                    throw ("")
                setAula(r1.data);

                setCurso(r2.data);
                setLoading(false)
            }).catch(_ => {
                setErro(true)
            })
        }

    }, [])



    useEffect(() => {
        if (uuid_aula) {
            api.get(`/aula/item/${uuid_turma}/${uuid_aula}`).then((r: any) => {
                setAulaNovo(r.data)
                setAtividade(r.data.atividades);
            }).then(_ => setLoading(false)).catch(_ => setErro(true))
        }

    }, [uuid_aula])


    const handlePage = (pagina: number) => {
        setLoading(true)
        buscarPaginacao(pagina)
    }

    if (erro)
        return <><Erro /></>

    const handleClickEditar = (item: any) => {
        setUuidAula(item.id)
        setCursoNovo(item)
        setModal(true)
    }

    if (loading)
        return (<>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "70vh" }}
            >
                <Lottie
                    options={lottieDefaultOptions(carregando)}
                    height={"40%"}
                    width={"40%"}
                />
            </Grid>
        </>)

    const handleClickDeletar = (itemID: any) => {
        toast.info("Deletando aula")
        api.delete(`aula/${itemID}`).then(_ => {
            toast.success("Aula deletada");
            setAula((ant: any) => {
                const novosItems = ant.items.filter((item: any) => {
                    if (item.id !== itemID) {
                        return item;
                    }

                });
                return { ...ant, items: novosItems };
            });
        }).catch(_ => toast.error("Erro ao deletar aula"))
    }

    return (<>
        <div className="divHeader">
            <div>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={2}
                    spacing={1}
                >
                    <Grid item xs={11}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"

                        >
                            <Grid item xs={12}>
                                <Grid container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center">
                                    <img
                                        style={{ maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto" }}
                                        src={professor === true ? banners_gif_pagina_professor : banners_gif_pagina_aluno}
                                        alt={"banners gif pagina aula"}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <CorButton cor={"#007718"}>
                                    {((central == true || decodedToken?.realm_access?.roles.includes('admin') || (turma?.tipo === 'professor' || turma?.tipo === 'coordenador' || turma?.tipo === 'diretor') && getContratos(decodedToken)) && turma?.tipo !== 'aluno') && ((turma?.tipo !== 'aluno' && getContratos(decodedToken))) ?
                                        <Button startIcon={<PlusOutlined />} style={{ paddingLeft: "1rem", paddingRight: "1.5rem" }} size="small" onClick={() => { setUuidAula(undefined); setModal(true); setAulaNovo(new AulaItem()); setAtividade([]) }}>
                                            Nova aula
                                        </Button>
                                        : ""}
                                    <Modal
                                        width="100%"
                                        centered
                                        footer={null}
                                        open={modal}
                                        onCancel={() => setModal(false)}
                                    >

                                        <Aula dataAberta={aulaNovo.data_abertura ? true : false} uuid_aula={uuid_aula} setAula={setAulaNovo} aula={aulaNovo} setModal={setModal} setCurso={setCurso} nova_aula={false} setAulaBack={setAula} atividade={atividade} setAtividade={setAtividade} turma={turma} />

                                    </Modal>
                                </CorButton>
                            </Grid>

                            <Grid item xs={4}>
                                <Grid item><div style={{ display: "flex", justifyContent: "center" }} ><span style={{ padding: "1rem", border: "solid 1px #007718", borderRadius: "100%" }}><MenuBookIcon style={{ color: "#007718" }} fontSize="large" /></span></div></Grid>
                                <Grid item><div style={{ display: "flex", justifyContent: "center", textAlign: "center", color: "#007718", marginTop: ".5rem" }} >{curso?.nome?.toUpperCase()}</div></Grid>
                                <Grid item><div style={{ display: "flex", justifyContent: "center", color: "#007718", marginTop: ".5rem", fontSize: "12px" }} >{turma?.nome?.toUpperCase()}</div></Grid>
                            </Grid>
                            <Grid item xs={4}><div style={{ display: "flex", justifyContent: "end" }}><Button onClick={_ => uuid_turma !== null && uuid_turma !== undefined ? navigate(`/turma/${uuid_turma}`) : navigate(`/centralcursos/`)} style={{ backgroundColor: "#007718", borderRadius: "2rem" }} className="opacidade" variant="contained">Voltar</Button></div></Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={11}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={8}>
                                <div style={{ position: "relative", border: "solid 1px black", borderRadius: ".5rem", marginTop: "1.5rem" }}>
                                    <div style={{ position: "absolute", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "white", padding: "0px 1rem 0px 1rem", color: "#007718" }}>
                                        <p style={{ fontSize: "17px" }}>Sobre</p>
                                    </div>
                                    <div style={{ padding: "20px", textAlign: "justify" }}>
                                        <p>{curso.descricao}</p>
                                    </div>
                                </div>

                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={11} >
                        <Grid container >
                            {aula?.items?.map((item: AulaItem) =>
                                <React.Fragment key={item.id} >
                                    <Grid item xs={12} >
                                        <Aula_Item central={central} professor={(professor && (turma?.tipo === "professor" || turma?.tipo === "coordenador" || turma?.tipo === "diretor") || central === true)} cor={"#007718"} aula={item} decodedToken={decodedToken} handleClickEditar={handleClickEditar} funcaoDeletar={handleClickDeletar} turma={turma} />
                                    </Grid>
                                    <Grid item></Grid>
                                </React.Fragment>
                            )}
                        </Grid>
                    </Grid>

                </Grid>
            </div>
            <Paginacao atividade={aula} handlePage={handlePage} />


        </div >

    </>)
}

export default TurmaCursoAula;