import Grid from "@mui/material/Grid";
import Paginacao from "../paginacao";
import MenuTurma from "../atividade/menu";
import { CursoItem, TurmaItem, getContratos } from "../types";
import { Button } from "@mui/material";
import LongMenu from '../turma/menu'
import LockIcon from '@mui/icons-material/Lock';
import { toast } from "react-toastify";
import api from "../../axios/axios";


function TrilhaCursos({ professor, decodedToken, turma, setTela, setModal, modal, setCurso, curso, handlePage, navigate, cor, deleteAPI, uuid_turma }: any) {

    deleteAPI = (turmaIte: TurmaItem) => {
        toast.info("Deletando trilha");
        api.delete("/curso/" + turmaIte.id).then(_ => {
            toast.success("Trilha deletada")
            setCurso(() => ({
                ...curso,
                items: curso?.trilha?.filter((tu: CursoItem) => tu.id !== turmaIte.id)
            }));

        }).catch(_ => toast.error("Erro ao deletar trilha"))
    }


    return (<>
        <MenuTurma trilha={true} turma={turma} setTela={setTela} decodedToken={decodedToken} professor={decodedToken?.realm_access?.roles.includes('admin') || (turma?.tipo === 'professor' || turma?.tipo === 'coordenador' || turma?.tipo === 'diretor')} selecionado="TRILHAS" setCurso={setCurso} curso={curso} turmaNome={turma.nome} cor={cor} uuidTurma={uuid_turma ? uuid_turma : ""} editarNovoCurso={true} />

        <Grid item xs={11} style={{ marginTop: "1rem" }}>
            <Grid container
                direction="row"
                justifyContent="flex-start"
                alignItems="center" spacing={2}>
                {curso?.items?.map((item: CursoItem) =>
                    <Grid key={item.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <div style={{ marginBottom: "1rem", boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px", padding: "1rem", borderRadius: " .5rem" }}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                style={{ position: "relative" }}
                            >
                                {((decodedToken?.realm_access?.roles.includes('admin') || (turma?.tipo === 'professor' || turma?.tipo === 'coordenador' || turma?.tipo === 'diretor') && getContratos(decodedToken)) && turma?.tipo !== 'aluno') && ((turma?.tipo !== 'aluno' && getContratos(decodedToken))) ? <>
                                    <div onClick={_ => _.stopPropagation()} style={{
                                        position: "absolute",
                                        top: "3%",
                                        right: ".2rem",
                                    }}>
                                        <LongMenu trilha={true} professor={professor && (turma?.tipo === 'professor' || turma?.tipo === 'coordenador' || turma?.tipo === 'diretor')} curso={item} deleteAPI={deleteAPI} setCurso={setCurso} editar={true} />
                                    </div>
                                    {item.status === false ?
                                        < div onClick={_ => _.stopPropagation()} style={{
                                            position: "absolute",
                                            top: "8%",
                                            left: ".4rem",
                                        }}>
                                            <LockIcon style={{ color: "white", }} />
                                        </div>
                                        : ""}
                                </>
                                    : ""
                                }
                                <Grid item xs={12} >



                                    <div style={{ userSelect: "none", background: `radial-gradient(#009907, #006322)`, padding: "1.5rem", borderRadius: ".5rem", color: "white" }}>

                                        <Grid item xs={12}>
                                            <span style={{ textTransform: "uppercase", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                {item.nome}
                                            </span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <span style={{ marginTop: ".5rem", fontSize: "12px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                {item.aulas !== undefined ? item.aulas + " aulas" : ""}
                                            </span>
                                        </Grid>
                                    </div>

                                </Grid>

                                <Grid item>
                                    <Button onClick={_ => navigate(`/trilha/${uuid_turma}/${item.id}`)} style={{ fontSize: "12px", marginTop: "1rem", paddingLeft: "1.5rem", paddingRight: "1.5rem", backgroundColor: "#0048B6" }} variant="contained">
                                        Abrir
                                    </Button>
                                </Grid>
                            </Grid>



                        </div>



                    </Grid>




                )}
            </Grid>
        </Grid>


        <Paginacao atividade={curso} handlePage={handlePage} trilha={true} />


    </>)
}

export default TrilhaCursos;
