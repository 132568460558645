
import { Button, Grid, RadioGroup } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { GridAtividade, GridAtividades, GridResposta, GridSpanDiv } from '../cor';
import { CaretDownOutlined } from '@ant-design/icons';
import { Input, InputNumber, Radio, Select } from "antd";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import LinkIcon from '@mui/icons-material/Link';
import { Atividade, Resposta, convertToEmbedUrl, formatarNumero, imagem_turma_listagem, validateLink } from '../types';
import { Button as ButtonAntd } from 'antd';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


function ExpandirAtividade({ atividade, cor, setAtividade, index, turma, aula, trilha = false }: any) {
    const [expandido, setExpandido] = useState(true);



    function handleClick() {
        setExpandido(!expandido);
    }

    const handleRespostaCerta = (i: number, id: string) => {

        setAtividade(atividade.map((item: Atividade, indice: number) => {
            if (indice === i) {
                return { ...item, resposta_certa: id };
            }
            return item;
        }))
    }

    const handleChangeResposta = (resposta: ChangeEvent<HTMLInputElement>, i: number, j: number) => {

        setAtividade(atividade.map((item: Atividade, indice: number) => {
            if (i === indice) {
                return {
                    ...item,
                    respostas: item.respostas.map((respostaItem: Resposta, indiceResposta: number) => {
                        if (j === indiceResposta) {
                            return { ...respostaItem, resposta: resposta.target.value };
                        }
                        return respostaItem;
                    })
                };
            }
            return item;
        }));
    }

    const handleChangeRespostaJustificativa = (resposta: ChangeEvent<HTMLInputElement>, i: number, j: number) => {

        setAtividade(atividade.map((item: Atividade, indice: number) => {
            if (i === indice) {
                return {
                    ...item,
                    respostas: item.respostas.map((respostaItem: Resposta, indiceResposta: number) => {
                        if (j === indiceResposta) {
                            return { ...respostaItem, justificativa: resposta.target.value };
                        }
                        return respostaItem;
                    })
                };
            }
            return item;
        }));
    }

    const handleButtonAddResposta = (i: number, j: number = 0, add: boolean = true) => {

        if (add) {

            setAtividade((atividade: Atividade[]) => atividade.map((item: Atividade, indice: number) => {
                if (i === indice) {
                    return {
                        ...item,
                        respostas: [...item.respostas, new Resposta()]
                    };
                }
                return item;
            }));

        } else {

            let ele = atividade[index].respostas.splice(j, 1)[0];

            if (atividade[index].resposta_certa === ele.id) {
                if (atividade[index].respostas.length > 0) {
                    atividade[index].resposta_certa = atividade[index].respostas[0].id;
                }
                else {
                    atividade[index].resposta_certa = "";
                }
            }

            setAtividade((atividade: Atividade[]) => atividade.map((item: Atividade, indice: number) => {
                if (i === indice) {
                    return {
                        ...item,
                        respostas: [...item.respostas]
                    };
                }
                return item;
            }));


        }
    }

    const handleChangeNota = (e: number | null, i: number) => {
        setAtividade(atividade.map((item: Atividade, indice: number) => {
            if (i === indice) {
                return { ...item, nota: e };
            }
            return item;
        }));
    }



    const handleChangeAtividadeIframe = (atv: ChangeEvent<HTMLInputElement>, i: number) => {
        setAtividade(atividade.map((item: Atividade, indice: number) => {
            if (i === indice) {
                return { ...item, pergunta: atv.target.value };
            }
            return item;
        }));
    }


    const handleAtividadeAtividade = (atv: ChangeEvent<HTMLInputElement>) => {
        setAtividade(atividade.map((item: Atividade, indice: number) => {
            if (index === indice) {
                return { ...item, nome: atv.target.value };
            }
            return item;
        }));
    }

    const handleButtonAtividade = (i: number = 0, add: boolean = true) => {
        if (add === false) {
            const filter = atividade.filter((e: Atividade, j: number) => i !== j ? e : "")
            setAtividade([...filter])
        }
    }


    return (
        <Grid item xs={12} style={{ marginTop: "1rem" }} >
            <div style={{ backgroundColor: "#f1f4f8", padding: "1rem", borderRadius: ".5rem" }}>

                <div onClick={handleClick}>
                    <div onClick={_ => _.stopPropagation()} >
                        <Grid item xs={12}>
                            <Grid container direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Grid item>
                                    <GridSpanDiv cor={cor}>
                                        <div style={{ display: "inline-block" }}>
                                            <span style={{ paddingBottom: ".3rem", alignItems: "flex-end" }}>

                                                <EditIcon style={{ marginRight: ".5rem" }} />
                                                Nome da atividade
                                            </span>
                                        </div>
                                    </GridSpanDiv>
                                    <CaretDownOutlined onClick={handleClick} style={{ cursor: "pointer", marginLeft: " .5rem" }} />
                                </Grid>
                                <Grid item>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <ButtonAntd type="primary" onClick={() => handleButtonAtividade(index, false)} danger><DeleteOutlineIcon /></ButtonAntd>
                                    </div>
                                </Grid>
                            </Grid>
                            <GridAtividade cor={cor} style={{ marginTop: "-.1rem" }}>
                                <Input status={atividade[index].nome.trim() === "" ? "error" : ""} value={atividade[index].nome} onChange={(e: ChangeEvent<HTMLInputElement>) => handleAtividadeAtividade(e)} style={{ boxShadow: 'black' }} minLength={1} showCount maxLength={120} className="cor" placeholder="Nome da atividade" />
                            </GridAtividade>
                        </Grid>


                    </div>
                </div>


            </div>
            {expandido ?
                <>










                    <Grid item xs={12} style={{ padding: "1rem" }} >

                        <Grid item xs={12} >


                            {!trilha ?


                                <Grid item xs={12} >
                                    <GridSpanDiv cor={cor}>
                                        <div style={{ display: "inline-block" }}>
                                            <span style={{ paddingBottom: ".5rem", alignItems: "flex-end" }}>

                                                <AccessTimeIcon style={{ marginRight: ".5rem" }} />
                                                Status da atividade
                                            </span>
                                        </div>
                                    </GridSpanDiv>


                                    <GridAtividade cor={cor}>
                                        <Select
                                            labelInValue
                                            value={atividade[index].status}
                                            onChange={(_: any) => setAtividade(atividade.map((item: Atividade, indice: number) => {
                                                if (index === indice) {
                                                    return { ...item, status: _.value };
                                                }
                                                return item;
                                            }))}

                                            style={{ width: "100%" }}
                                            options={[
                                                {
                                                    value: true,
                                                    label: 'Aberto',
                                                },
                                                {
                                                    value: false,
                                                    label: 'Fechado',
                                                },
                                            ]}
                                        />
                                    </GridAtividade>
                                </Grid>
                                :
                                ""}
                            <Grid item xs={12} style={{ marginTop: !trilha ? "2rem" : "0px" }}>
                                <GridSpanDiv cor={cor}>
                                    <div style={{ display: "inline-block" }}>
                                        <span style={{ paddingBottom: ".5rem", alignItems: "flex-end" }}>
                                            <StarBorderIcon style={{ marginRight: ".5rem", }} />
                                            Nota
                                        </span>
                                    </div>
                                </GridSpanDiv>
                                <GridAtividade cor={cor}>
                                    <InputNumber status={atividade[index]?.nota !== null ? "" : "error"} value={atividade[index].nota} onChange={_ => handleChangeNota(_, index)} className="cor" placeholder="Nota" max={100} onBlur={(e: any) => {
                                        e.target.value = formatarNumero(e.target.value.replace(",", "."), 2);
                                    }} style={{ width: "100%" }} />
                                </GridAtividade>
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: "2rem" }}>
                                <GridSpanDiv cor={cor}>
                                    <div style={{ display: "inline-block" }}>
                                        <span style={{ paddingBottom: ".5rem" }}>

                                            <LinkIcon style={{ marginRight: ".5rem" }} />
                                            Pergunta
                                        </span>
                                    </div>
                                </GridSpanDiv>

                                <GridAtividades cor={cor}>
                                    <Input status={atividade[index].pergunta.trim() === "" ? "error" : ""} value={atividade[index].pergunta} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeAtividadeIframe(e, index)} maxLength={200} className="cor" placeholder="Pergunta" />

                                    {validateLink(atividade[index].pergunta) === false ? <>
                                        <Grid item xs={true}>

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Grid item xs={5}>
                                                    <div className="url" style={{ marginTop: "1rem" }}>

                                                        <iframe allow="fullscreen" className="responsive-iframe" title="Atividade" src={(convertToEmbedUrl(atividade[index].pergunta) || atividade[index].pergunta)} />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </> : <>
                                        <Grid item xs={true} style={{ marginTop: "1rem", marginBottom: ".5rem" }}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={0}
                                            >
                                                <Grid item xs={5}>
                                                    <Grid style={{
                                                        color: "white", backgroundColor: turma?.cor?.hex ? turma?.cor?.hex : cor,
                                                        backgroundImage: `url('${imagem_turma_listagem[turma?.cor?.hex ? turma?.cor?.hex : cor]}')`,
                                                        backgroundRepeat: "repeat",
                                                        backgroundSize: "auto 10vh",
                                                        padding: "1rem",
                                                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                                        border: "2px solid black",
                                                        borderRadius: "1rem",
                                                        userSelect: "none",
                                                    }}>
                                                        <p style={{
                                                            textAlign: "center",
                                                            textJustify: "inter-word",
                                                            maxWidth: "51vw"
                                                        }}>{atividade[index].pergunta.trim()}</p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>}
                                </GridAtividades>
                            </Grid>


                        </Grid>


                    </Grid>



                    <GridResposta cor={cor} style={{ marginTop: "1rem" }}>

                        <RadioGroup onChange={_ => handleRespostaCerta(index, _.target.value)}>

                            {atividade[index].respostas.map((elem: Resposta, j: number) =>
                                <React.Fragment key={elem.id}>
                                    <Grid container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center" spacing={2} >
                                        {aula?.avaliacao !== 2 ?
                                            <Grid item >

                                                <Radio checked={atividade[index].resposta_certa === elem.id || (atividade[index].resposta_certa === "" && j === 0) ? true : false} value={elem.id} onChange={() => {
                                                    setAtividade(atividade.map((item: Atividade, indice: number) => {
                                                        if (indice === index) {
                                                            return { ...item, resposta_certa: elem.id };
                                                        }
                                                        return item;
                                                    }))
                                                }} />

                                            </Grid >
                                            : ""}
                                        <Grid item xs={5}>
                                            {aula?.avaliacao === 1 || aula?.avaliacao === 2 ? <>
                                                <Input status={elem.resposta.trim() === "" ? "error" : ""} showCount minLength={1} maxLength={120} onBlur={_ => {

                                                    if (atividade[index].resposta_certa === "") {

                                                        setAtividade(atividade.map((item: Atividade, indice: number) => {
                                                            if (index === indice) {
                                                                return { ...item, resposta_certa: atividade[index].respostas[0].id };
                                                            }
                                                            return item;
                                                        }))

                                                    }
                                                }} value={elem.resposta} onChange={(_: React.ChangeEvent<HTMLInputElement>) => handleChangeResposta(_, index, j)} placeholder={`Resposta ${j + 1}`} />

                                            </> : <>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={true}>
                                                        <Input status={elem.resposta.trim() === "" ? "error" : ""} showCount minLength={1} maxLength={120} onBlur={_ => {

                                                            if (atividade[index].resposta_certa === "") {

                                                                setAtividade(atividade.map((item: Atividade, indice: number) => {
                                                                    if (index === indice) {
                                                                        return { ...item, resposta_certa: atividade[index].respostas[0].id };
                                                                    }
                                                                    return item;
                                                                }))

                                                            }
                                                        }} value={elem.resposta} onChange={(_: React.ChangeEvent<HTMLInputElement>) => handleChangeResposta(_, index, j)} placeholder={`Resposta ${j + 1}`} />
                                                    </Grid>

                                                    <Grid item xs={true}>
                                                        <Input status={elem.resposta.trim() === "" ? "error" : ""} showCount minLength={1} maxLength={120} onBlur={_ => {

                                                            if (atividade[index].resposta_certa === "") {

                                                                setAtividade(atividade.map((item: Atividade, indice: number) => {
                                                                    if (index === indice) {
                                                                        return { ...item, resposta_certa: atividade[index].respostas[0].id };
                                                                    }
                                                                    return item;
                                                                }))

                                                            }
                                                        }} value={elem.justificativa || ""} onChange={(_: React.ChangeEvent<HTMLInputElement>) => handleChangeRespostaJustificativa(_, index, j)} placeholder={`Justificativa ${j + 1}`} />
                                                    </Grid>

                                                </Grid></>}

                                        </Grid>

                                        <Grid item>

                                            {atividade[index].respostas.length > 0 ? <ButtonAntd type="primary" danger onClick={_ => handleButtonAddResposta(index, j, false)}><DeleteOutlineIcon /> </ButtonAntd> : ""}


                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )
                            }

                        </RadioGroup>
                    </GridResposta>
                    <Grid container
                        direction="row" justifyContent="center"
                        alignItems="center" spacing={2}>
                        <Grid item>
                            <Button className="opacidade" onClick={() => handleButtonAddResposta(index)}
                                style={{ backgroundColor: "#0048b6", color: "white", margin: "2rem" }}
                                variant="outlined" startIcon={<AddIcon />}>
                                Adicionar alternativa
                            </Button>
                        </Grid>

                    </Grid>


                </>
                : ""}

        </Grid>

    );
}

export default ExpandirAtividade;