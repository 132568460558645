import Grid from "@mui/material/Grid";
import Paginacao from "../paginacao";
import MenuTurma from "../atividade/menu";
import { Modal } from "antd";
import api from "../../axios/axios";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CursoItem, ListaCurso, TurmaItem, getContratos, lottieDefaultOptions } from "../types";
import { Button } from "@mui/material";
import LongMenu from '../turma/menu'
import { toast } from "react-toastify";
import Cursos from "../central_cursos";
import Erro from "../erro";
import carregando from '../../img/lotties/98432-loading.json';
import Lottie from "react-lottie";
import TurmaPessoas from "../alunos";
import LockIcon from '@mui/icons-material/Lock';
import banners_gif_pagina from "../../img/gif_img/banners_gif_pagina02_nova.gif";
import TrilhaCursos from "./trilha";


function TurmaCurso({ professor, decodedToken }: any) {

    const [curso, setCurso] = useState<ListaCurso>(new ListaCurso());
    const [trilha, setTrilha] = useState<ListaCurso>(new ListaCurso());
    const { uuid_turma } = useParams();
    const [loading, setLoading] = useState(true);
    const [turma, setTurma] = useState(new TurmaItem());
    const [cor, setCor] = useState("");
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [erro, setErro] = useState(false);
    const [tela, setTela] = useState('CURSOS');
    const [primeira, setPrimeira] = useState(true);

    const fetchData = async (pagina: number = 1, trilha = false) => {

        try {
            const [r1, r2, r3] = await Promise.all([
                api.get(`/curso/listar/${uuid_turma}?page=${pagina}&trilha=${false}&size=${curso.size}`).then((r: any) => r.data),
                api.get(`/turma/${uuid_turma}`).then((r: any) => r.data),
                api.get(`/curso/listar/${uuid_turma}?page=${pagina}&trilha=${true}&size=${curso.size}`).then((r: any) => r.data),
            ]);

            const aulaPromises = r1.items.map((item: CursoItem) =>
                api.get(`/aula/list/${uuid_turma}/${item.id}`).then((r) => r.data)
            );
            const aulaResponses = await Promise.all(aulaPromises);

            const updatedCursoData = r1.items.map((item: CursoItem, index: number) => ({
                ...item,
                aulas: aulaResponses[index].total,
            }));

            r1.items = updatedCursoData
            
            if (primeira) {
                setCurso(r1)
                setTurma(r2);
                setPrimeira(false);
                setTrilha(r3);
            }
            else {
                if (!trilha)
                    setCurso(r1)
                else
                    setTrilha(r3);
            }

            setTurma(r2);
            if (cor !== r2.cor.hex) {
                setCor(r2.cor.hex);
            }
        } catch (error) {
            setErro(true);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (uuid_turma !== undefined) {
            fetchData();
        }
    }, []);

    if (erro)
        return <><Erro /></>


    const deleteAPI = (turmaIte: TurmaItem) => {
        toast.info("Deletando curso");
        api.delete("/curso/" + turmaIte.id).then(_ => {
            toast.success("Curso deletado")
            setCurso(() => ({
                ...curso,
                items: curso.items.filter((tu: CursoItem) => tu.id !== turmaIte.id)
            }));

        }).catch(_ => toast.error("Erro ao deletar curso"))
    }



    const handlePage = (pagina: number, trilha = false) => {
        setLoading(true)
        fetchData(pagina, trilha)
    }



    if (loading)
        return (<>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "70vh" }}
            >
                <Lottie
                    options={lottieDefaultOptions(carregando)}
                    height={"40%"}
                    width={"40%"}
                />
            </Grid>
        </>)


    return (<>
        <div className="divHeader">
            <div>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={2}
                    spacing={1}

                >
                    <Grid item >
                        <img
                            style={{ maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto", marginBottom: "-1.5rem" }}
                            src={banners_gif_pagina}
                            alt={"banners gif pagina turma"}

                        />
                    </Grid>
                    {tela === 'CURSOS' ? <>
                        <MenuTurma turma={turma} setTela={setTela} decodedToken={decodedToken} professor={decodedToken?.realm_access?.roles.includes('admin') || (turma?.tipo === 'professor' || turma?.tipo === 'coordenador' || turma?.tipo === 'diretor')} selecionado="CURSOS" setCurso={setCurso} curso={curso} turmaNome={turma.nome} cor={cor} uuidTurma={uuid_turma ? uuid_turma : ""} editarNovoCurso={true} />
                        {(((decodedToken?.realm_access?.roles.includes('admin') || (turma?.tipo === 'professor' || turma?.tipo === 'coordenador' || turma?.tipo === 'diretor') && getContratos(decodedToken)) && turma?.tipo !== 'aluno') && ((turma?.tipo !== 'aluno' && getContratos(decodedToken)))) ?

                            <Grid item xs={12}  >
                                <div style={{ display: "flex", justifyContent: "center" }} >
                                    <span onClick={() => setModal(true)} style={{ cursor: "pointer", userSelect: "none", color: cor, border: `1px solid ${cor}`, padding: ".5rem", borderRadius: "2rem", paddingLeft: "1rem", paddingRight: "1rem", fontSize: "14px", textAlign: "center" }}>ATRIBUIR CURSO</span>
                                </div>
                                <Modal
                                    width="100%"
                                    centered
                                    footer={null}
                                    open={modal}

                                    onCancel={() => setModal(false)}
                                >
                                    <Cursos setCursos={setCurso} voltar={false} setModalBack={setModal} />
                                </Modal>
                            </Grid>
                            : ""}

                        <Grid item xs={11} style={{ marginTop: "1rem" }}>
                            <Grid container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center" spacing={2}>
                                {curso?.items.map((item: CursoItem) =>
                                    <Grid key={item.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                                        <div style={{ marginBottom: "1rem", boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px", padding: "1rem", borderRadius: " .5rem" }}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                style={{ position: "relative" }}
                                            >
                                                {((decodedToken?.realm_access?.roles.includes('admin') || (turma?.tipo === 'professor' || turma?.tipo === 'coordenador' || turma?.tipo === 'diretor') && getContratos(decodedToken)) && turma?.tipo !== 'aluno') && ((turma?.tipo !== 'aluno' && getContratos(decodedToken))) ? <>
                                                    <div onClick={_ => _.stopPropagation()} style={{
                                                        position: "absolute",
                                                        top: "3%",
                                                        right: ".2rem",
                                                    }}>
                                                        <LongMenu professor={professor && (turma?.tipo === 'professor' || turma?.tipo === 'coordenador' || turma?.tipo === 'diretor')} curso={item} deleteAPI={deleteAPI} setCurso={setCurso} editar={true} />
                                                    </div>
                                                    {item.status === false ?
                                                        < div onClick={_ => _.stopPropagation()} style={{
                                                            position: "absolute",
                                                            top: "8%",
                                                            left: ".4rem",
                                                        }}>
                                                            <LockIcon style={{ color: "white", }} />
                                                        </div>
                                                        : ""}
                                                </>
                                                    : ""
                                                }
                                                <Grid item xs={12} >



                                                    <div style={{ userSelect: "none", background: `radial-gradient(#009907, #006322)`, padding: "1.5rem", borderRadius: ".5rem", color: "white" }}>

                                                        <Grid item xs={12}>
                                                            <span style={{ textTransform: "uppercase", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                {item.nome}
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <span style={{ marginTop: ".5rem", fontSize: "12px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                {item.aulas !== undefined ? item.aulas + " aulas" : ""}
                                                            </span>
                                                        </Grid>
                                                    </div>

                                                </Grid>

                                                <Grid item>
                                                    <Button onClick={_ => navigate(`/turma/${uuid_turma}/${item.id}`)} style={{ fontSize: "12px", marginTop: "1rem", paddingLeft: "1.5rem", paddingRight: "1.5rem", backgroundColor: "#0048B6" }} variant="contained">
                                                        Abrir
                                                    </Button>
                                                </Grid>
                                            </Grid>



                                        </div>


                                    </Grid>


                                )}
                            </Grid>
                        </Grid>
                        <Paginacao atividade={curso} handlePage={handlePage} />
                    </> : ""}

                    {tela === 'PESSOAS' ? <TurmaPessoas decodedToken={decodedToken} setTurma={setTurma} professor={professor && (turma?.tipo === 'professor' || turma?.tipo === 'coordenador' || turma?.tipo === 'diretor')} setTela={setTela} setCurso={setCurso} cor={cor} curso={curso} turma={turma} uuid_turma={uuid_turma} /> : ""}

                    {tela === 'TRILHAS' ? <TrilhaCursos handlePage={handlePage} navigate={navigate} decodedToken={decodedToken} setTurma={setTurma} professor={professor && (turma?.tipo === 'professor' || turma?.tipo === 'coordenador' || turma?.tipo === 'diretor')} setTela={setTela} setCurso={setTrilha} cor={cor} curso={trilha} turma={turma} uuid_turma={uuid_turma} /> : ""}
                </Grid>
            </div >






        </div >
    </>)
}

export default TurmaCurso;
