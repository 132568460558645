import { useState } from 'react';
import { Paper, Typography, TextField, Modal, IconButton, Button, Grid, Container } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { makeStyles, styled } from '@mui/material/styles';
import recuperar from '../../img/lotties/animation_lkc0vj5i.json';
import recuperou from '../../img/lotties/animation_lkbzje7w.json';
import Lottie from 'react-lottie';
import { lottieDefaultOptions } from "../../components/types"
import { toast } from 'react-toastify';
import api from '../../axios/axios';
import { useNavigate } from 'react-router-dom';
import background from "../../img/background/background.svg"
import sappens from "../../img/background/logo-sappens.0c9e344815819f9fe519.png"
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

const RecuperarSenha = () => {
    const [nome, setNome] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    const [matricula, setMatricula] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [senha, setSenha] = useState(false);
    const [novaSenha, setNovaSenha] = useState("");
    const navigate = useNavigate();


    const handleShowModal = () => {
        toast.success("Senha recuperada")
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        navigate(`/`);
    };

    const handleSenha = () => {
        setSenha(!senha);
    };

    const handleNomeChange = (e: any) => {
        setNome(e.target.value);
    };

    const handledataNascimentoChange = (e: any) => {
        setDataNascimento(e.target.value);
    };

    const handlematriculaChange = (e: any) => {
        setMatricula(e.target.value);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        toast.info("Recuperando senha")

        api.post('/senha', { "nome_completo": nome, 'data_nascimento': dataNascimento, "id_keycloak": matricula, "cliente": `${process.env.REACT_APP_CLIENTE}` }).then(r => {
            setNovaSenha(r.data.senha)
            handleShowModal()

        }).catch(r =>
            toast.error("Senha não recuperada")
        )

    };



    const VisibilityIcon = styled(Visibility)({
        fontSize: '4rem',
    });

    const VisibilityIconOff = styled(VisibilityOff)({
        fontSize: '4rem',
    });

    const formatarSenha = (senha: string) => {
        return senha.replace(/./g, '*');
    };


    const theme = createTheme({
        palette: {
            primary: {
                main: '#363636'
            }
        }
    });

    return (

        <Grid
            container
            direction="row"
            justifyContent="space-between"
            style={{ background: `url(${background}) center center / cover fixed no-repeat`, minHeight: "100vh" }}
            spacing={1}
        >

            <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ textAlign: "center" }}
                >
                    <Grid item xs={12}>
                        <div
                            style={{
                                background: `url(${sappens}) center center / contain no-repeat`,
                                width: "300px",
                                height: "100px",
                                margin: "auto",
                                marginBottom: "1rem"
                            }}
                        ></div>
                    </Grid>



                    <Grid item xs={12} md={6} lg={5} style={{
                        alignItems: "center",
                        justifyContent: "center",

                    }}>

                        <Modal open={showModal} onClose={handleCloseModal}>
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '2rem', borderRadius: '8px' }}>
                                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} >
                                    <Grid item xs={12}>
                                        <Lottie
                                            options={lottieDefaultOptions(recuperou)}
                                            height={250}
                                            width={350}
                                        />
                                    </Grid>

                                    <Grid item style={{ border: "1px solid #757575", borderRadius: "1rem", marginLeft: "1rem", marginTop: "1rem" }}>
                                        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} >
                                            <Grid item xs={12}>
                                                <Typography variant="h5" sx={{ marginBottom: 2 }} style={{ textAlign: "center" }}>
                                                    Senha recuperada!
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{ marginTop: "-1rem" }}>
                                                <Grid container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center" spacing={1}>
                                                    <Grid item>

                                                        <span style={{ textAlign: 'center', color: "#757575" }}>
                                                            Clique no
                                                        </span>
                                                    </Grid>
                                                    <Grid item>
                                                        <VisibilityOff style={{ color: "#757575" }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <span style={{ textAlign: 'center', color: "#757575" }}>
                                                            para revelar sua senha!
                                                        </span>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center" >
                                                    <Grid item style={{ padding: "1rem" }} >
                                                        <span style={{ color: "#757575", fontSize: "3rem", fontFamily: 'monospace' }}>{senha ? novaSenha : formatarSenha(novaSenha)}</span>
                                                    </Grid>
                                                    <Grid item >

                                                        {senha ? <IconButton onClick={handleSenha} style={{ marginBottom: "0rem" }} ><VisibilityIconOff /></IconButton> : <IconButton onClick={handleSenha} style={{ marginTop: "-1rem" }}><VisibilityIcon /></IconButton>}

                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" onClick={handleCloseModal} style={{ backgroundColor: "#616161", borderRadius: "10px", lineHeight: 1.3333333, textTransform: "capitalize", fontSize: "14px", fontFamily: "Open Sans,Helvetica,Arial,sans-serif", fontWeight: 600 }}>
                                            Fechar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Modal>
                        <Paper elevation={3} sx={{ padding: 6, borderRadius: "30px", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} >
                                <Grid item xs={12}>

                                    <Lottie
                                        options={lottieDefaultOptions(recuperar)}
                                        height={250}
                                        width={"100%"}
                                        style={{ maxWidth: "350px" }}
                                    />

                                </Grid>

                            </Grid>

                            <Typography variant="h5" sx={{ marginBottom: 2 }}>
                                Recuperação de Senha
                            </Typography>
                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <ThemeProvider theme={theme}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="nome"
                                                name="nome"
                                                label="Nome completo"
                                                value={nome}
                                                onChange={handleNomeChange}
                                                className="custom-textfield"
                                            />
                                        </ThemeProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ThemeProvider theme={theme}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="data"
                                                name="data"
                                                label="Data de Nascimento"
                                                type="date"
                                                value={dataNascimento}
                                                onChange={handledataNascimentoChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}

                                            />
                                        </ThemeProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ThemeProvider theme={theme}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="matricula"
                                                name="matricula"
                                                label="Matrícula"
                                                value={matricula}
                                                onChange={handlematriculaChange}
                                                className="custom-textfield"
                                            />
                                        </ThemeProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Button type="submit" fullWidth variant="contained" color="primary" style={{ backgroundColor: "#616161", borderRadius: "10px", lineHeight: 1.3333333, textTransform: "capitalize", fontSize: "14px", fontFamily: "Open Sans,Helvetica,Arial,sans-serif", fontWeight: 600 }}>
                                                    Enviar
                                                </Button>

                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button onClick={() => navigate("/")} type="submit" fullWidth variant="contained" color="primary" style={{ backgroundColor: "#616161", borderRadius: "10px", lineHeight: 1.3333333, textTransform: "capitalize", fontSize: "14px", fontFamily: "Open Sans,Helvetica,Arial,sans-serif", fontWeight: 600 }}>
                                                    Voltar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </Grid >
                </Grid>
            </Grid >
        </Grid >
    );
};

export default RecuperarSenha;
