
import { Grid } from '@mui/material';
import { useState } from 'react';
import { Button, Card, Result } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SendIcon from '@mui/icons-material/Send';
import { Button as ButtonUi } from '@mui/material';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { DataEntrega } from '../data_entrega';
import { Button as ButtonMui } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { CorButton } from '../cor';
import { Atividade, Resposta, calcular_pontuacao, getContratos } from '../types';
import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DangerousIcon from '@mui/icons-material/Dangerous';
import win from "../../img/gif_img/banners_gif_resumo_alunos.gif";
import { BarChartOutlined } from '@ant-design/icons';
import { Modal } from "antd";
import ListarRespostas from '../respostas';
import QuizIcon from '@mui/icons-material/Quiz';

function Aula_Item({ aula, funcaoDeletar, cor, professor, handleClickEditar, turma, decodedToken, style = false, editar = undefined, central = false }: any) {
    const [expandido, setExpandido] = useState(false);
    const navigate = useNavigate();
    const { uuid_turma, uuid_curso } = useParams();
    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleClick() {
        setExpandido(!expandido);
    }

    return (
        <Grid item xs={12} onClick={handleClick} className="borda" style={{ padding: "1rem", marginBottom: style === false ? "1.5rem" : "", cursor: style === false ? "pointer" : "" }}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                rowSpacing={2}
                spacing={1}

            >
                <Grid item xs={12} >
                    <Modal
                        width="70%"
                        centered
                        footer={null}
                        open={modal}
                        style={{ zIndex: 0 }}
                        onCancel={() => setModal(false)}
                    >
                        <ListarRespostas aula={aula} turma={turma} />
                    </Modal>

                    <Grid container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"   >

                        <Grid item xs={12} >

                            <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item style={{ marginRight: "-1rem" }}>
                                    <div style={{
                                        width: 60,
                                        height: 60,

                                    }}>
                                        {aula?.avaliacao !== 2 ?
                                            <>
                                                {professor === false ? aula?.entrega
                                                    ?
                                                    <>
                                                        {aula?.avaliacao === 1 ? <PlayCircleFilledWhiteIcon style={{ width: "100%", height: "100%", color: cor && style ? cor : "green" }} /> : <PlayCircleOutlineIcon style={{ width: "100%", height: "100%", color: 'green' }} />}
                                                    </>
                                                    :
                                                    <>

                                                        {aula?.avaliacao === 1 ? <PlayCircleFilledWhiteIcon style={{ width: "100%", height: "100%", color: "#a6a6a6" }} /> : <PlayCircleOutlineIcon style={{ width: "100%", height: "100%", color: '#a6a6a6' }} />}
                                                    </>
                                                    :
                                                    <>
                                                        {aula?.avaliacao === 1 ? (
                                                            <PlayCircleFilledWhiteIcon style={{ width: "100%", height: "100%", color: cor && style ? cor : "green" }} />
                                                        ) : (
                                                            <PlayCircleOutlineIcon style={{ width: "100%", height: "100%", color: 'green', borderRadius: "10rem" }} />
                                                        )}

                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                {professor === true || aula?.entrega ? <QuizIcon style={{ width: "100%", height: "100%", color: cor && style ? cor : "green" }} /> : <QuizIcon style={{ width: "100%", height: "100%", color: '#a6a6a6' }} />}
                                            </>
                                        }

                                    </div>

                                </Grid>
                                <Grid item xs >
                                    {aula?.avaliacao === 1 ? <>
                                        <Grid>
                                            <span style={{ fontSize: ".9rem", color: "#727272" }}>
                                                Avaliação
                                            </span>
                                        </Grid>
                                        <Grid>
                                            <span style={{ display: "inline-block", fontWeight: "bold", textTransform: "capitalize" }}>
                                                {aula.nome}
                                            </span>
                                        </Grid>
                                    </>
                                        : aula?.avaliacao === 0 ? <><Grid>
                                            <span style={{ fontSize: ".9rem", color: "#727272" }}>
                                                Atividade
                                            </span>
                                        </Grid>
                                            <Grid>
                                                <span style={{ display: "inline-block", fontWeight: "bold", textTransform: "capitalize" }}>
                                                    {aula.nome}
                                                </span>
                                            </Grid></> :
                                            <><Grid>
                                                <span style={{ fontSize: ".9rem", color: "#727272" }}>
                                                    Quiz
                                                </span>
                                            </Grid>
                                                <Grid>
                                                    <span style={{ display: "inline-block", fontWeight: "bold", textTransform: "capitalize" }}>
                                                        {aula.nome}
                                                    </span>
                                                </Grid></>
                                    }

                                </Grid>
                                <Grid item >
                                    <DataEntrega aula={aula} professor={professor} />
                                </Grid>
                            </Grid>
                        </Grid>






                        <Grid item xs={12} style={{ padding: "0px" }}>
                            {expandido || style ? <>
                                <div onClick={_ => _.stopPropagation()} style={{ cursor: "default" }}>
                                    <Grid item xs={12} style={{ marginTop: "1rem" }}>
                                        <div className="linha-horizontal-0" />
                                        <p style={{ textAlign: "justify", marginRight: ".5rem", marginLeft: "1rem" }}>{aula.descricao}</p>
                                        <div className="linha-horizontal-0" />
                                    </Grid>
                                    <Grid item xs={12} style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                                        <Grid container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center" spacing={2}>

                                            <Grid item xs={12}>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                                    {aula?.entrega ? <>

                                                        <Card style={{ boxShadow: "none", marginRight: "1rem" }}>
                                                            <Result
                                                                icon={
                                                                    <>
                                                                        <Grid item >
                                                                            <img
                                                                                style={{ maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto", marginBottom: "-1.5rem" }}
                                                                                src={win}
                                                                                alt={"banners gif win"}
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                }
                                                                title={<>
                                                                    {aula?.avaliacao !== 2 ?
                                                                        <>
                                                                            {aula.atividades.length > 0 ?
                                                                                "Nota: " + calcular_pontuacao(aula.atividades.map((_: Atividade) => _.nota), aula.atividades.map((perguntaItem: Atividade, __: number) => {
                                                                                    const r = aula.entrega.respostas.filter((_: Resposta) => perguntaItem.id === _.id_atividade);

                                                                                    if (perguntaItem.respostas.length === 0 || r[0].id_resposta === perguntaItem.resposta_certa)
                                                                                        return perguntaItem.nota
                                                                                    else
                                                                                        return 0

                                                                                }), aula.pontuacao) + "%" : "Nota: 100%"}
                                                                        </> : "Nota: 100%"

                                                                    }
                                                                </>
                                                                }
                                                                subTitle={"Resumo:"}
                                                                extra={[
                                                                    <div key={aula?.atividades[0]?.id} style={{ marginBottom: "-2rem" }}>
                                                                        <Grid container direction="row"
                                                                            justifyContent="center"
                                                                            alignItems="center" style={{ marginTop: "-.5rem" }} spacing={1}>

                                                                            <Grid item style={{ marginTop: "-1.5rem" }}>
                                                                                <p style={{ fontWeight: "bold" }}>Aula: {aula.nome}</p>
                                                                            </Grid>
                                                                            <Grid item style={{ marginTop: "-1.5rem" }}>
                                                                                <CheckBoxIcon style={{ color: cor && style ? cor : "green" }} />
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                            </Grid>
                                                                            {aula.atividades.map((perguntaItem: Atividade, __: number) =>
                                                                                <React.Fragment key={perguntaItem.id}>

                                                                                    <Grid item style={{ marginTop: "-1.5rem" }}>
                                                                                        <p style={{ fontWeight: "bold" }}>Quiz: {__ + 1}</p>
                                                                                    </Grid>
                                                                                    {aula?.avaliacao !== 2 ?
                                                                                        <>
                                                                                            {
                                                                                                perguntaItem.resposta_certa === aula.entrega.respostas[__].id_resposta || perguntaItem.respostas.length === 0 || aula.entrega.respostas.filter((_: Resposta) => perguntaItem.id === _.id_atividade)[0].id_resposta === perguntaItem.resposta_certa ?
                                                                                                    <Grid item style={{ marginTop: "-1.5rem" }}>
                                                                                                        <CheckBoxIcon style={{ color: cor && style ? cor : "green" }} />
                                                                                                    </Grid>
                                                                                                    :
                                                                                                    <Grid item style={{ marginTop: "-1.5rem" }}>
                                                                                                        <DangerousIcon style={{ color: "red" }} />
                                                                                                    </Grid>

                                                                                            }
                                                                                        </>
                                                                                        : <Grid item style={{ marginTop: "-1.5rem" }}>
                                                                                            <CheckBoxIcon style={{ color: cor && style ? cor : "green" }} />
                                                                                        </Grid>}
                                                                                    <Grid item xs={12} />
                                                                                </React.Fragment>)}
                                                                        </Grid>
                                                                        {aula?.avaliacao === 0 ?
                                                                            <Grid container
                                                                                direction="row"
                                                                                justifyContent="center"
                                                                                alignItems="center" spacing={2}>
                                                                                <Grid item>
                                                                                    <CorButton cor={cor}>
                                                                                        <ButtonUi onClick={_ => { if (window.location.href.includes('centralcursos')) { navigate(`/centralcursos/resposta/${uuid_curso}/${aula.id}`) } else navigate(`/resposta/${uuid_turma}/${uuid_curso}/${aula.id}`) }} style={{ color: "white", paddingLeft: "1.5rem", paddingRight: "1.5rem" }} startIcon={<SendIcon />}  >Responder aula</ButtonUi>

                                                                                    </CorButton>
                                                                                </Grid>
                                                                                {((((turma?.tipo === 'professor' || turma?.tipo === 'coordenador' || turma?.tipo === 'diretor') && getContratos(decodedToken)) || decodedToken?.realm_access?.roles.includes('admin') || (central || professor) && window.location.href.includes('turma')) && turma?.tipo !== 'aluno') && ((turma?.tipo !== 'aluno' && getContratos(decodedToken))) ?
                                                                                    <Grid item>
                                                                                        <CorButton cor={cor}>
                                                                                            <ButtonUi onClick={_ => {
                                                                                                setModal(true)
                                                                                            }
                                                                                            } style={{ color: "white", paddingLeft: "1.5rem", paddingRight: "1.5rem", }} startIcon={<BarChartOutlined />}  >Respostas</ButtonUi>
                                                                                        </CorButton>
                                                                                    </Grid>
                                                                                    : ""}
                                                                            </Grid>
                                                                            : ""}
                                                                    </div>
                                                                ]}

                                                            />

                                                        </Card>


                                                    </> : <>
                                                        <Grid container
                                                            direction="row"
                                                            justifyContent="center"
                                                            alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <CorButton cor={cor}>
                                                                    <ButtonUi onClick={_ => { if (window.location.href.includes('centralcursos')) { navigate(`/centralcursos/resposta/${uuid_curso}/${aula.id}`) } else navigate(`/resposta/${uuid_turma}/${uuid_curso}/${aula.id}`) }} style={{ color: "white", paddingLeft: "1.5rem", paddingRight: "1.5rem" }} startIcon={<SendIcon />}  >Responder aula</ButtonUi>
                                                                </CorButton>
                                                            </Grid>
                                                            {central === false && ((((((turma?.tipo === 'professor' || turma?.tipo === 'coordenador' || turma?.tipo === 'diretor') && getContratos(decodedToken)) || decodedToken?.realm_access?.roles.includes('admin') || (central || professor) && window.location.href.includes('turma')) && turma?.tipo !== 'aluno'))) && (turma?.tipo !== 'aluno' && getContratos(decodedToken)) ?
                                                                <Grid item>
                                                                    <CorButton cor={cor}>
                                                                        <ButtonUi onClick={_ => {
                                                                            setModal(true)
                                                                        }
                                                                        } style={{ color: "white", paddingLeft: "1.5rem", paddingRight: "1.5rem", }} startIcon={<BarChartOutlined />}  >Respostas</ButtonUi>
                                                                    </CorButton>
                                                                </Grid>
                                                                : ""}
                                                        </Grid>
                                                    </>
                                                    }

                                                </div>
                                            </Grid>

                                            {central === true && getContratos(decodedToken) || ((editar === undefined || editar) && ((decodedToken?.realm_access?.roles.includes('admin') || (turma?.tipo === 'professor' || turma?.tipo === 'coordenador' || turma?.tipo === 'diretor') && getContratos(decodedToken)) && turma?.tipo !== 'aluno') && ((turma?.tipo !== 'aluno' && getContratos(decodedToken)))) ?
                                                <>
                                                    <Grid item>
                                                        <ButtonMui variant="outlined" style={{}} size={"large"}
                                                            onClick={i => { i.stopPropagation(); handleClickEditar(aula) }} >
                                                            <BorderColorIcon />
                                                        </ButtonMui>


                                                    </Grid>

                                                    <Grid item>
                                                        <div>

                                                            <ButtonMui style={{


                                                                backgroundColor: "red",
                                                                color: "white"
                                                            }} size={"large"}
                                                                onClick={i => { i.stopPropagation(); handleClickOpen() }}

                                                            ><DeleteIcon />
                                                            </ButtonMui>

                                                            <Dialog
                                                                open={open}
                                                                onClose={handleClose}
                                                                aria-labelledby="alert-dialog-title"
                                                                aria-describedby="alert-dialog-description"
                                                            >
                                                                <DialogTitle id="alert-dialog-title">
                                                                    {"Tem certeza que realmente deseja apagar " + aula.nome + "?"}
                                                                </DialogTitle>
                                                                <DialogContent>
                                                                    <DialogContentText id="alert-dialog-description">

                                                                    </DialogContentText>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button type="primary" onClick={handleClose}>Não</Button>
                                                                    <Button type="primary" danger onClick={_ => { handleClose(); funcaoDeletar(aula.id) }} autoFocus>
                                                                        Sim
                                                                    </Button>

                                                                </DialogActions>
                                                            </Dialog>
                                                        </div>
                                                    </Grid>
                                                </>
                                                : ""}
                                        </Grid>

                                    </Grid>
                                </div></> : ""}
                        </Grid>
                    </Grid >
                </Grid>

            </Grid >
        </Grid >
    );
}

export default Aula_Item