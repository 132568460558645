import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from "@mui/material/Grid";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};




export default function DropDown({ escolas, escolaAtual, setEscolaAtual }: any) {


    const handleChange = (event: any) => {
        setEscolaAtual(escolas.filter((r: any) => r.id === event.target.value)[0]);
    };

    return (


        <FormControl style={{ width: "100%", }}>
            <InputLabel id="demo-multiple-name-label">Escola</InputLabel>
            <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                size='small'
                value={escolaAtual.id}
                onChange={handleChange}
                input={<OutlinedInput label="Escola" />}
                MenuProps={MenuProps}
                renderValue={(selected) => (
                    <div style={{ textAlign: "center", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                        {escolas.filter((item: any) => item.id === selected)[0].nome}
                    </div>
                )}
            >
                {escolas.map((item: any) => (
                    <MenuItem
                        key={item.id}
                        value={item.id}

                    >
                        {item.nome}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>


    );
}
