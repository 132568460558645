import { useEffect, useRef, useState } from "react";
import { Atividade, Resposta, SubmissaoReposta, TurmaItem, convertToEmbedUrl, imagem_turma_listagem, lottieDefaultOptions, validateLink } from "../../components/types";
import { useParams } from "react-router-dom";
import api from "../../axios/axios";
import { Card, Button, Grid, Avatar } from "@mui/material";
import { Result } from 'antd';
import { Button as ButtonAntd } from 'antd';
import Lottie from 'react-lottie';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import ButtonSair from "../../components/sair"
import { toast } from 'react-toastify';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DangerousIcon from '@mui/icons-material/Dangerous';
import sappens from '../../img/caderno.jpg';
import { DataEntrega } from "../../components/data_entrega";
import { Cor, CorButton } from "../../components/cor";
import React from "react";
import Selecao from "../../components/atividade/selecao";
import FundoTurma from "../../components/atividade/fundo";
import Erro from "../../components/erro";
import Confetes from "./confete";
import animationData from '../../img/gif_img/saiu_da_prova_hugo.png';
import duvida from '../../img/gif_img/cartela_certeza_Hugo.png';
import errou from '../../img/gif_img/erro.png';
import acertou from '../../img/gif_img/acerto.png';
import win from '../../img/lotties/107653-trophy.json';
import moment from "moment";
import carregando from '../../img/lotties/98432-loading.json';
import checkin from '../../img/lotties/95778-check-yes-search.json';
import Vimeo from "@vimeo/player";



function RespostaEnviar({ professor, central, decodedToken }: any) {
    const [aula, setAula] = useState<any>();
    const [resposta, setResposta] = useState<any[]>([]);
    const { uuid_curso, uuid_aula, uuid_turma } = useParams();
    const [erro, setErro] = useState(false);
    const [loading, setLoading] = useState(true);
    const [progresso, setProgresso] = useState(0);
    const [continuar, setContinuar] = useState(false)
    const [mostrar, setMostrar] = useState(true)
    const [turma, setTurma] = useState(new TurmaItem());
    const [cor, setCor] = useState("");
    const [saiuDaAba, setSaiuDaAba] = useState(false);
    const [tempoAula, setTempoAula] = useState(0);
    const [tempo, setTempo] = useState(moment());
    const [enviar, setEnviar] = useState(true);
    const [cancelar, setCancelar] = useState(false);
    const [travar, setTravar] = useState(true);
    const [enviarApi, setEnviarApi] = useState(true);
    const [trocar, setTrocar] = useState(false);
    const [voltar, setVoltar] = useState("");
    const [fim, setFim] = useState(false);
    const [check, setChek] = useState(false);
    const iframeRef = useRef<any>(null);
    const [mostrarProximo, setMostrarProximo] = useState(0);


    useEffect(() => {

        if (!aula?.link) {
            setProgresso(1);
            setMostrarProximo(2);
        }

        const intervalId = setInterval(() => {
            try {
                if (aula?.link && mostrarProximo === 0 && aula?.link?.toLowerCase().indexOf('vimeo') === -1) {
                    setMostrarProximo(1)


                }
                else if (mostrarProximo === 0 && aula?.link && iframeRef.current) {
                    const player = new Vimeo(iframeRef.current);

                    player.on('ended', function () {
                        setMostrarProximo(1)
                    });
                }
            }
            catch {
            }
        }, 3000);


        return () => clearInterval(intervalId);
    }, [aula?.link]);



    // Anti-fraude - m pega iframe, mas pode dar alt+tab
    useEffect(() => {
        if (enviar) {
            const handleWindowBlur = () => {
                if (aula && aula?.avaliacao === 1) {
                    if (!document.hasFocus()) {
                        setSaiuDaAba(true);
                    }
                }

            };


            window.addEventListener('blur', handleWindowBlur);
            window.addEventListener('focus', handleWindowBlur);

            return () => {
                window.removeEventListener('blur', handleWindowBlur);
                window.removeEventListener('focus', handleWindowBlur);
            };
        }
    }, [enviar, aula]);

    useEffect(() => {
        if (fim === false && progresso === aula?.atividades?.length === enviarApi) {
            setChek(true)


        }

    }, [progresso]);

    // Anti-fraude - pega iframe
    // useEffect(() => {
    //     const handleBeforeUnload = (event: any) => {
    //         if (document.hidden && document.visibilityState === 'hidden') {
    //             setSaiuDaAba(true)
    //         }
    //     };
    //
    //     const handleBlur = (event: any) => {
    //         if (document.hidden && document.visibilityState === 'hidden') {
    //             setSaiuDaAba(true)
    //         }
    //     };
    //
    //     window.addEventListener('beforeunload', handleBeforeUnload);
    //     window.addEventListener('blur', handleBlur);
    //
    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //         window.removeEventListener('blur', handleBlur);
    //     };
    // }, []);



    useEffect(() => {
        if (uuid_aula !== undefined && uuid_turma !== undefined) {


            Promise.all([
                api.get(`/aula/item/${uuid_turma}/${uuid_aula}`).then((r: any) => r.data),
                api.get(`/turma/${uuid_turma}`).then((r: any) => r.data),
                api.get(`/resposta/${uuid_aula}`).then((r: any) => r.data)
            ]).then(async ([r1, r2, r3]) => {
                r1.atividades.unshift(r1)
                r1.entrega = r3
                if (r3 !== null && r1.avaliacao) {
                    throw new Error('Existe submissão');
                }
                setAula(r1)
                setResposta(r1.atividades.map((_: Atividade) => { let a = new SubmissaoReposta(); a.id_atividade = _.id; return a }))
                setTurma(r2);

                let r = await api.get('curso/' + r1.id_curso).then(r => r.data)
                if (uuid_turma && r?.trilha) {
                    let lista = await Promise.all([
                        api.get(`/aula/list/${uuid_turma}/${uuid_curso}?size=99999`),
                        api.get(`/curso/${uuid_curso}`),
                    ]).then(([r, c]) => [r.data, c.data])

                    await Promise.all(lista[0].items.map((_: any) => api.get(`resposta/${_.id}`),)).then(
                        (_: any) => _.map((item: any, j: number) => lista[0].items[j].entrega = item.data)
                    ).then(() => {

                        let aulas = [...lista[0].items]
                        let mat: { [key: string]: { [key: string]: any } } = {};
                        aulas.map((a: any) => {
                            if (a?.xy) {
                                if (mat.hasOwnProperty(a?.xy[0]) === false)
                                    mat[a?.xy[0]] = {}
                                mat[a?.xy[0]][a?.xy[1]] = a;
                            }

                        })





                        let primeiro: any = true || [];
                        for (let x = 0; x < lista[1].trilha.length; x++) {
                            if (x % 2 === 0)
                                for (let y = 0; y < lista[1].trilha[x].length; y++) {

                                    if (lista[1].trilha[x][y] != 0 && lista[1].trilha[x][y] != -1) {

                                        let aul = mat[x][y];


                                        if (aul.entrega) {
                                            lista[1].trilha[x][y] = 1;
                                        }
                                        else {
                                            lista[1].trilha[x][y] = 2;
                                        }
                                        if (primeiro === true && !aul.entrega) {
                                            primeiro = [x, y];
                                            lista[1].trilha[x][y] = 3;
                                        }

                                    }
                                }
                            else
                                for (let y = lista[1].trilha[x].length - 1; y >= 0; y--) {
                                    if (lista[1].trilha[x][y] != 0 && lista[1].trilha[x][y] != -1) {
                                        let aul = mat[x][y];


                                        if (aul.entrega) {
                                            lista[1].trilha[x][y] = 1;
                                        }
                                        else {
                                            lista[1].trilha[x][y] = 2;
                                        }
                                        if (primeiro === true && !aul.entrega) {
                                            primeiro = [x, y];
                                            lista[1].trilha[x][y] = 3;
                                        }

                                    }

                                }
                        }

                        let adm = ((decodedToken?.realm_access?.roles.includes('admin') || (r2?.tipo === 'professor' || r2?.tipo === 'coordenador' || r2?.tipo === 'diretor')) && r2?.tipo !== 'aluno') && ((r2?.tipo !== 'aluno'));

                        if (!adm && primeiro !== true) {


                            if (primeiro[0] % 2 === 0 && (r1.xy[0] > primeiro[0] || r1.xy[1] > primeiro[1])) {
                                setErro(true);
                            }
                            else if (r1.xy[0] > primeiro[0] || r1.xy[1] < primeiro[1]) {
                                setErro(true);
                            }
                        }

                    })
                }
                if (cor !== r2.cor.hex)
                    setCor(r2.cor.hex)
            }).then(_ => setLoading(false)).catch(_ => { setErro(true) });
        }
        else if (uuid_aula !== undefined)
            Promise.all([
                api.get(`/aula/item/${uuid_turma}/${uuid_aula}`).then((r: any) => r.data),
                api.get(`/resposta/${uuid_aula}`).then((r: any) => r.data)
            ]).then(([r1, r3]) => {
                r1.atividades.unshift(r1)
                r1.entrega = r3
                setAula(r1)
                setResposta(new Array((r1.atividades.length)).fill(null).map(_ => new SubmissaoReposta()))

                setCor("#008000");
            }).then(_ => setLoading(false)).catch(_ => setErro(true));
    }, []);



    if (erro)
        return <><Erro /></>

    if (loading)
        return (<>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "70vh" }}
            >
                <Lottie
                    options={lottieDefaultOptions(carregando)}
                    height={"40%"}
                    width={"40%"}
                />
            </Grid>
        </>)



    const conferir = (perguntaItem: Atividade, resposta: any[], __: any) => {
        if (perguntaItem.respostas.length === 0) {
            return "Concluído!"
        }

        const res = perguntaItem.respostas.filter((r: Resposta) => r.id === resposta[__].id_resposta);
        if (res.length === 0) {
            return "Nenhuma seleção"
        }
        return res[0].resposta;
    }



    const handleEnviar = () => {
        setEnviar(false)
        if (decodedToken?.realm_access?.roles.includes('admin') || (professor && (turma?.tipo === 'professor' || turma?.tipo === 'coordenador')) || central) {
            return
        }

        toast.info("Enviando resposta")
        let respostaAula = {
            id_aula: uuid_aula,
            tempo: tempoAula,
            respostas: [...resposta],
            timestamp: new Date(moment().tz('America/Sao_Paulo').toString())
        }

        try {
            respostaAula.respostas.shift()
        }
        catch {

        }

        api.post(`/resposta/${uuid_turma}`, JSON.stringify(respostaAula)).then(_ => toast.success("Resposta envida")).catch(_ => toast.error("Erro ao enviar resposta"))
    }




    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5
        },
    }));

    const handleReposta = (id: string, passar: boolean = false) => {

        setContinuar(false);
        setMostrar(true);
        if (id !== "-1") {
            setTravar(false);
        }
        let p = progresso + 1;
        resposta[progresso].id_atividade = aula.atividades[progresso].id;
        resposta[progresso].timestamp = new Date(moment().tz('America/Sao_Paulo').toString())
        resposta[progresso].tempo += moment.duration(moment().diff(tempo)).asMilliseconds();
        setTempo(moment())
        if (aula.atividades[progresso]?.respostas?.length === 0) {
            resposta[progresso].id_resposta = null;
        }

        if (passar === false) {
            resposta[progresso].id_resposta = id;

        } else {
            if (aula?.avaliacao === 1 || aula?.avaliacao === 2) {
                setProgresso(p);
            }
            else if (id === "-2") {
                setProgresso(p);
            }
            else if (progresso !== 0 && resposta[progresso].id_resposta === aula.atividades[progresso].resposta_certa) {
                setProgresso(p);
            }
            else if (aula?.avaliacao === 0 && progresso === 0) {
                setProgresso(p);
            } else {
                resposta[progresso].id_resposta = "";

            }

        }
        setResposta([...resposta]);
    }

    const handleTrocar = (id: string) => {
        setTrocar(true);
        setMostrar(false);
        setTravar(false)
        if (voltar === "")
            setVoltar(resposta[progresso].id_resposta);

        resposta[progresso].mostrar = true;
        resposta[progresso].id_atividade = aula.atividades[progresso].id;
        resposta[progresso].timestamp = new Date(moment().tz('America/Sao_Paulo').toString())
        resposta[progresso].tempo += moment.duration(moment().diff(tempo)).asMilliseconds();
        setTempo(moment())

        resposta[progresso].id_resposta = id;



        setResposta([...resposta]);
    }

    const verificaSelecaoFundo = (atual: number) => {

        if (resposta[atual].id_resposta !== "")
            return cor

        return "white"
    }

    const verificaSelecao = (atual: number) => {

        if (resposta[atual].id_resposta !== "")
            return "white"
        return cor
    }

    const opcoes = (elem: any, j: number) => {

        if (aula.avaliacao === 0 && travar) {
            return (
                <React.Fragment key={elem.id} >
                    {
                        resposta[progresso].id_resposta === elem.id ?
                            <Grid item xs={aula.atividades[progresso]?.respostas?.length % 2 !== 0 && j === aula.atividades[progresso]?.respostas?.length - 1 ? 10 : 5}>
                                <div className="textoEscolha respostaSubmissaoLaranja" style={{ display: "flex", cursor: "default", justifyContent: "center" }}>
                                    <p style={{ userSelect: "none" }} >{elem.resposta}</p>
                                </div>
                            </Grid>
                            : <Grid item xs={aula.atividades[progresso]?.respostas?.length % 2 !== 0 && j === aula.atividades[progresso]?.respostas?.length - 1 ? 10 : 5}>
                                <Cor cor={cor} selecionado={true} ativio={true} trancar={true}>
                                    <div onClick={_ => { handleReposta(elem.id, false) }} style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
                                        <p style={{ color: "white", userSelect: "none" }}>{elem.resposta}</p>
                                    </div>
                                </Cor>
                            </Grid>
                    }
                </React.Fragment>
            )
        }

        if (resposta[progresso].mostrar && cancelar && trocar === false && travar) {
            return (<React.Fragment key={elem.id} >
                {
                    resposta[progresso].id_resposta === elem.id ?
                        <Grid item xs={aula.atividades[progresso]?.respostas?.length % 2 !== 0 && j === aula.atividades[progresso]?.respostas?.length - 1 ? 10 : 5}>
                            <div className="textoEscolha respostaSubmissaoLaranja" style={{ display: "flex", cursor: "default", justifyContent: "center" }}>
                                <p style={{ userSelect: "none" }} >{elem.resposta}</p>
                            </div>
                        </Grid>
                        : <Grid item xs={aula.atividades[progresso]?.respostas?.length % 2 !== 0 && j === aula.atividades[progresso]?.respostas?.length - 1 ? 10 : 5}>
                            <Cor cor={cor} selecionado={true} ativio={true} trancar={true}>
                                <div onClick={_ => { handleReposta(elem.id, false) }} style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
                                    <p style={{ color: "white", userSelect: "none" }}>{elem.resposta}</p>
                                </div>
                            </Cor>
                        </Grid>
                }
            </React.Fragment>)
        }
        else if (resposta[progresso].id_resposta === "" && trocar === false && travar) {
            return (<React.Fragment key={elem.id} >
                {
                    resposta[progresso].id_resposta === elem.id ?
                        <Grid item xs={aula.atividades[progresso]?.respostas?.length % 2 !== 0 && j === aula.atividades[progresso]?.respostas?.length - 1 ? 10 : 5}>
                            <div className="textoEscolha respostaSubmissaoLaranja" style={{ display: "flex", cursor: "default", justifyContent: "center" }}>
                                <p style={{ userSelect: "none" }} >{elem.resposta}</p>
                            </div>
                        </Grid>
                        : mostrar === true ? <Grid className="transicao" item xs={aula.atividades[progresso]?.respostas?.length % 2 !== 0 && j === aula.atividades[progresso]?.respostas?.length - 1 ? 10 : 5}>
                            <Cor cor={cor} selecionado={true} ativio={true} trancar={true}>
                                <div onClick={_ => { handleReposta(elem.id, false) }} style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
                                    <p style={{ color: "white", userSelect: "none" }}>{elem.resposta}</p>
                                </div>
                            </Cor>
                        </Grid> : <Grid item xs={aula.atividades[progresso]?.respostas?.length % 2 !== 0 && j === aula.atividades[progresso]?.respostas?.length - 1 ? 10 : 5}>
                            <Cor cor={cor} selecionado={true} ativio={true} trancar={true}>
                                <div onClick={_ => { handleReposta(elem.id, false) }} style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
                                    <p style={{ color: "white", userSelect: "none" }}>{elem.resposta}</p>
                                </div>
                            </Cor>
                        </Grid>
                }
            </React.Fragment>)
        }

        else if ((resposta[progresso].mostrar === false && travar === true) || (trocar && travar)) {
            return (<React.Fragment key={elem.id} >
                {
                    resposta[progresso].id_resposta === elem.id ?
                        <Grid item xs={aula.atividades[progresso]?.respostas?.length % 2 !== 0 && j === aula.atividades[progresso]?.respostas?.length - 1 ? 10 : 5}>
                            <div className="textoEscolha respostaSubmissaoLaranja" style={{ display: "flex", cursor: "default", justifyContent: "center" }}>
                                <p style={{ userSelect: "none" }} >{elem.resposta}</p>
                            </div>
                        </Grid>
                        : <Grid item xs={aula.atividades[progresso]?.respostas?.length % 2 !== 0 && j === aula.atividades[progresso]?.respostas?.length - 1 ? 10 : 5}>
                            <Cor cor={cor} selecionado={true} ativio={true} trancar={true}>
                                <div onClick={_ => { handleTrocar(elem.id); }} style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
                                    <p style={{ color: "white", userSelect: "none" }}>{elem.resposta}</p>
                                </div>
                            </Cor>
                        </Grid>
                }
            </React.Fragment>)
        }

        else {
            return (resposta[progresso].id_resposta === elem.id ?
                <Grid item xs={aula.atividades[progresso]?.respostas?.length % 2 !== 0 && j === aula.atividades[progresso]?.respostas?.length - 1 ? 10 : 5}>
                    <div className="textoEscolha respostaSubmissaoLaranja" style={{ display: "flex", cursor: "default", justifyContent: "center" }}>
                        <p style={{ userSelect: "none" }} >{elem.resposta}</p>
                    </div>
                </Grid>
                : <Grid item xs={aula.atividades[progresso]?.respostas?.length % 2 !== 0 && j === aula.atividades[progresso]?.respostas?.length - 1 ? 10 : 5}>
                    <Cor cor={cor} selecionado={true} ativio={true} trancar={true}>
                        <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
                            <p style={{ color: "white", userSelect: "none" }}>{elem.resposta}</p>
                        </div>
                    </Cor>
                </Grid>)
        }

    }






    return (
        <div className="divHeader">
            <div>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={2}
                    spacing={1}
                >   {uuid_turma !== undefined ?
                    <Grid item xs={12}>
                        <FundoTurma cor={cor} turmaNome={turma.nome} />
                    </Grid>
                    : ""}

                    {saiuDaAba && enviar ? <Card style={{ zIndex: "3" }} >
                        <Result className="boxCentroReposta"
                            icon={saiuDaAba ? <Grid item xs={12}>
                                <img
                                    style={{ maxWidth: "50%", maxHeight: "50%", width: "auto", height: "auto" }}
                                    src={animationData}
                                    alt={"imagem alerta"}
                                />
                            </Grid> : ""}
                            title={"Você saiu da área de prova"}
                            subTitle={"O fiscal foi alertado e medidas podem ser tomadas. Lembre-se de cumprir as regras estabelecidas."}

                            extra={[

                                <Grid container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center" spacing={2}>

                                    <Grid item >
                                        <CorButton cor={cor}>
                                            <Button style={{ marginTop: "0rem" }} size="small" onClick={_ => {
                                                setSaiuDaAba(false);
                                                setVoltar("");
                                            }}>
                                                Continuar
                                            </Button >
                                        </CorButton>
                                    </Grid>

                                </Grid>

                            ]}
                        />
                    </Card> : ""}

                    {(aula.atividades[progresso]?.respostas?.length !== 0 && progresso < aula.atividades.length && resposta[progresso].mostrar === false && travar === false) || (progresso !== 0 && aula.atividades[progresso]?.respostas?.length !== 0 && progresso < aula.atividades.length && resposta[progresso].id_resposta !== "" && resposta[progresso].mostrar) ?

                        <Card style={{ zIndex: 2 }}>
                            <Result className="boxCentroReposta"
                                status={(continuar === true && resposta[progresso].id_resposta === aula.atividades[progresso]?.resposta_certa) || resposta[progresso].id_resposta === null ? "success" : "error"}
                                icon={continuar === false ? <Grid item xs={12}>
                                    <img
                                        style={{ maxWidth: "50%", maxHeight: "50%", width: "auto", height: "auto" }}
                                        src={duvida}
                                        alt={"imagem dúvida"}
                                    />
                                </Grid> : aula?.avaliacao === 2 || (resposta[progresso].id_resposta === aula.atividades[progresso]?.resposta_certa || resposta[progresso].id_resposta === null) ?
                                    <img
                                        style={{ maxWidth: "50%", maxHeight: "50%", width: "auto", height: "auto" }}
                                        src={acertou}
                                        alt={"imagem acerto"}
                                    /> : <img
                                        style={{ maxWidth: "50%", maxHeight: "50%", width: "auto", height: "auto" }}
                                        src={errou}
                                        alt={"imagem erro"}
                                    />}

                                title={continuar === false ? "Tem certeza que essa é a resposta escolhida?" : resposta[progresso].id_resposta === aula.atividades[progresso]?.resposta_certa || resposta[progresso].id_resposta === null || aula?.avaliacao === 2 ? <Confetes avalicao={aula?.avaliacao} /> : "Não foi dessa vez!"}
                                subTitle={continuar && resposta[progresso]?.id_resposta !== undefined && resposta[progresso].id_resposta !== null && resposta[progresso].id_resposta !== "" && resposta[progresso].id_resposta !== "-1" && resposta[progresso].id_resposta !== "-1" ? "Justificativa: " + aula.atividades[progresso]?.respostas.filter((e: any) => e.id === resposta[progresso].id_resposta)[0]?.justificativa ? aula.atividades[progresso]?.respostas.filter((e: any) => e.id === resposta[progresso].id_resposta)[0]?.justificativa : "" : ""}
                                extra={[
                                    <div key={aula.atividades[progresso].id}>{
                                        continuar === false || aula?.avaliacao === 2 ?

                                            <Grid container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center" spacing={2}>

                                                <Grid item >
                                                    <ButtonAntd style={{ marginTop: "0rem", backgroundColor: "red" }} type="primary" onClick={_ => {
                                                        setCancelar(true);
                                                        setTravar(true);
                                                        if (voltar !== "") {
                                                            resposta[progresso].id_resposta = voltar;
                                                            resposta[progresso].mostrar = false;
                                                            setVoltar("");
                                                        }
                                                        else {
                                                            resposta[progresso].id_resposta = "";
                                                        }
                                                        resposta[progresso].timestamp = new Date(moment().tz('America/Sao_Paulo').toString());
                                                        resposta[progresso].tempo += moment.duration(moment().diff(tempo)).asMilliseconds();
                                                        setTempo(moment())
                                                        setResposta([...resposta]);
                                                    }}>
                                                        Cancelar
                                                    </ButtonAntd >
                                                </Grid>
                                                <Grid item >
                                                    <CorButton cor={cor}>
                                                        <Button style={{ marginTop: "0rem" }} size="small" onClick={_ => {
                                                            if (aula?.avaliacao === 1 || aula?.avaliacao === 2) {
                                                                setMostrar(true);
                                                                setCancelar(false);
                                                                // setContinuar(true);
                                                                setVoltar("");
                                                                resposta[progresso].mostrar = false;
                                                                resposta[progresso].tempo += moment.duration(moment().diff(tempo)).asMilliseconds();
                                                                setTempo(moment())
                                                                resposta[progresso].timestamp = new Date(moment().tz('America/Sao_Paulo').toString());
                                                                setResposta([...resposta]);
                                                                handleReposta("-1", true); setTravar(true);
                                                            }
                                                            else {

                                                                setContinuar(true);
                                                            }

                                                        }}>
                                                            Continuar
                                                        </Button >
                                                    </CorButton>
                                                </Grid>

                                            </Grid>

                                            : <Grid container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center" spacing={2}>
                                                {!(resposta[progresso].id_resposta === aula.atividades[progresso]?.resposta_certa || resposta[progresso].id_resposta === null) ?
                                                    <Grid item xs>
                                                        <CorButton cor={cor}>
                                                            <ButtonAntd style={{ marginTop: "0rem", backgroundColor: "red" }} type="primary" onClick={_ => {
                                                                setMostrar(true);
                                                                setVoltar("");
                                                                resposta[progresso].mostrar = false;
                                                                resposta[progresso].tempo += moment.duration(moment().diff(tempo)).asMilliseconds();
                                                                resposta[progresso].timestamp = new Date(moment().tz('America/Sao_Paulo').toString());
                                                                setTempo(moment())
                                                                setResposta([...resposta]);
                                                                handleReposta("-1", true);
                                                                setTravar(true);
                                                            }}>
                                                                Tentar novamente
                                                            </ButtonAntd >
                                                        </CorButton>

                                                    </Grid>
                                                    : ""}
                                                {aula.avaliacao === 0 ?
                                                    <Grid item xs>
                                                        <CorButton cor={cor}>
                                                            <Button style={{ marginTop: "0rem" }} onClick={_ => {
                                                                setMostrar(true);
                                                                setVoltar("");
                                                                resposta[progresso].mostrar = false;
                                                                resposta[progresso].tempo += moment.duration(moment().diff(tempo)).asMilliseconds();
                                                                resposta[progresso].timestamp = new Date(moment().tz('America/Sao_Paulo').toString());
                                                                setTempo(moment())
                                                                setResposta([...resposta]);
                                                                handleReposta("-2", true);
                                                                setTravar(true);
                                                            }}>
                                                                Continuar
                                                            </Button >
                                                        </CorButton>
                                                    </Grid>
                                                    : ""
                                                }
                                            </Grid>}
                                    </div>
                                ]}
                            />
                        </Card>

                        : ""
                    }

                    <Grid item xs={12} >

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            rowSpacing={2}
                            spacing={1} >

                            {uuid_turma !== undefined ?
                                <>

                                    {/* <Grid item xs={12} sm={4} zeroMinWidth>
                                        <Selecao cor={cor} menu="MURAL" selecionado="ATIVIDADES" />
                                    </Grid> */}
                                    <Grid item xs={12} sm={4} zeroMinWidth>
                                        <Selecao cor={cor} menu="AULAS" selecionado="AULAS" />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={4} zeroMinWidth>
                                        <Selecao cor={cor} menu="PESSOAS" selecionado="ATIVIDADES" />
                                    </Grid> */}
                                </> : ""}
                            <Grid item xs={12}>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"

                                    spacing={1} >
                                    <Grid item sx={{ pb: "1rem" }} >
                                        <ButtonSair aula={aula} central={window.location.href.includes('centralcursos')} cor={cor} uuid_turma={uuid_turma || ""} texto={"Sair"} uuid_curso={uuid_curso} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid >

                    {loading ? <>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{ minHeight: "70vh" }}
                        >
                            <Lottie
                                options={lottieDefaultOptions(carregando)}
                                height={"40%"}
                                width={"40%"}
                            />
                        </Grid>
                    </> :
                        <>{progresso !== aula.atividades.length ? <>
                            <Grid item xs={12}>
                                <BorderLinearProgress sx={{
                                    backgroundColor: 'white',
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: cor
                                    }
                                }}
                                    style={{ zIndex: "-1", }} variant="determinate" value={((progresso) * 100) / aula.atividades.length} />
                                {mostrarProximo > 1 ?
                                    <Grid item xs={12} style={{ marginTop: "1rem" }}>
                                        <Grid container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center" spacing={2}>
                                            {aula.atividades.map((_: any, n: number) => <React.Fragment key={_.id}>
                                                <Grid item>

                                                    {n === 0 ?
                                                        <Button variant="outlined" className="opacidade" style={{ borderColor: cor, color: verificaSelecao(n), backgroundColor: verificaSelecaoFundo(n) }} onClick={() => setProgresso(n)} >
                                                            Aula
                                                        </Button>
                                                        :
                                                        <Button variant="outlined" className="opacidade" style={{ borderColor: cor, color: verificaSelecao(n), backgroundColor: verificaSelecaoFundo(n) }} onClick={() => setProgresso(n)} >
                                                            Questão  {n}
                                                        </Button>
                                                    }

                                                </Grid>
                                            </React.Fragment>)}
                                            <Grid item>
                                                <Button variant="outlined" className="opacidade" style={{ borderColor: cor, color: cor, backgroundColor: "white" }} onClick={() => setProgresso(aula?.atividades?.length)} >
                                                    Resumo
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : ""}
                            </Grid>

                            <Grid item xs={12}>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center" >

                                    <Grid item xs={12}>



                                        <Grid item style={{ marginTop: "1rem" }} className="borda">

                                            <Grid container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center" spacing={2}>

                                                <Grid item xs={12} style={{ margin: "1rem" }}>

                                                    <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="center">
                                                        <Grid item >
                                                            <Avatar sx={{ width: 60, height: 60 }} src={sappens} />
                                                        </Grid>
                                                        <Grid item xs style={{ marginLeft: "-1rem" }}>
                                                            <span style={{ display: "inline-block", fontWeight: "bold", textTransform: "capitalize" }}>
                                                                {aula.nome}
                                                            </span>
                                                        </Grid>
                                                        <Grid item >
                                                            <DataEntrega aula={aula} professor={professor} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>



                                            <div className="linha-horizontal-3" />
                                            <Grid item>

                                                {aula.atividades[progresso].nome !== aula.atividades[progresso].descricao ? <>
                                                    <p style={{ margin: "1rem", textAlign: "justify" }}>{aula.atividades[progresso].nome}</p>
                                                    <p style={{ margin: "1rem", textAlign: "justify" }}>{aula.atividades[progresso].descricao}</p>
                                                </>
                                                    : <p style={{ margin: "1rem", textAlign: "justify" }}>{aula.atividades[progresso].nome}</p>}
                                            </Grid>
                                            <Grid item xs={12} style={{ margin: "1rem" }}>
                                                <Grid container direction="row"
                                                    justifyContent="center"
                                                    alignItems="center" >
                                                    {validateLink(aula.atividades[progresso].pergunta) === false || progresso === 0 ?
                                                        <Grid item xs={12} sm={12} md={8} xl={5}>
                                                            <iframe ref={(el) => (iframeRef.current = el)} allow="fullscreen" title="Atividade" src={progresso === 0 ? (convertToEmbedUrl(aula.link) || aula.link) : (convertToEmbedUrl(aula.atividades[progresso].pergunta) || aula.atividades[progresso].pergunta)} />
                                                        </Grid>
                                                        : <Grid xs={true} style={{
                                                            color: "white", backgroundColor: turma?.cor?.hex ? turma?.cor?.hex : cor,
                                                            backgroundImage: `url('${imagem_turma_listagem[turma?.cor?.hex ? turma?.cor?.hex : cor]}')`,
                                                            backgroundRepeat: "repeat",
                                                            backgroundSize: "auto 10vh",
                                                            padding: "1rem",
                                                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                                            border: "2px solid black",
                                                            borderRadius: "1rem",
                                                            userSelect: "none",
                                                            fontSize: "60px",
                                                        }}>
                                                            <p style={{
                                                                textAlign: "center",
                                                                textJustify: "inter-word",
                                                            }}>{aula.atividades[progresso].pergunta}</p>
                                                        </Grid>}
                                                </Grid>
                                            </Grid>

                                            <Grid container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center" spacing={2} style={{ marginBottom: "1.5rem" }}>
                                                <Grid item xs={10} >
                                                    <Grid container
                                                        direction="row"
                                                        justifyContent="center"
                                                        alignItems="center" spacing={3} style={{ marginBottom: "1rem" }}>
                                                        {
                                                            aula.atividades[progresso]?.respostas?.map((elem: Resposta, j: number) => opcoes(elem, j))
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                        {mostrarProximo >= 1 && progresso === 0 ?
                                            <Grid container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center">
                                                <Grid item>
                                                    <CorButton cor={cor}>
                                                        <Button className="opacidade" style={{
                                                            marginTop: "1rem",
                                                            color: "white",
                                                            paddingLeft: "2.5rem",
                                                            paddingRight: "2.5rem"
                                                        }} endIcon={<SendIcon />}
                                                            onClick={_ => {
                                                                setMostrarProximo(2)
                                                                if (progresso === 0) {
                                                                    resposta[progresso].id_resposta = null
                                                                }
                                                                setMostrar(true);
                                                                setTempoAula(moment.duration(moment().diff(tempo)).asMilliseconds())
                                                                resposta[progresso].tempo += moment.duration(moment().diff(tempo)).asMilliseconds();
                                                                resposta[progresso].timestamp = new Date(moment().tz('America/Sao_Paulo').toString());
                                                                setTempo(moment())
                                                                // resposta[progresso].id_resposta = null;
                                                                setResposta([...resposta]);
                                                                handleReposta("-1", true)

                                                            }}
                                                        >
                                                            PRÓXIMO
                                                        </Button>
                                                    </CorButton>
                                                </Grid>

                                            </Grid>
                                            : ""
                                        }
                                        <Grid container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center">
                                            <Grid item>
                                                {progresso > 0 ?
                                                    <Button className="opacidade" style={{
                                                        marginTop: "1rem",
                                                        backgroundColor: "#5e34d6",
                                                        color: "white",
                                                        paddingLeft: "1rem",
                                                        paddingRight: "1.5rem"
                                                    }} startIcon={<SendIcon style={{ transform: "rotate(180deg)" }} />} size={"small"}
                                                        onClick={_ => {
                                                            if (travar) {
                                                                setMostrar(true);
                                                                resposta[progresso].id_atividade = aula.atividades[progresso].id;
                                                                resposta[progresso].tempo += moment.duration(moment().diff(tempo)).asMilliseconds();
                                                                resposta[progresso].timestamp = new Date(moment().tz('America/Sao_Paulo').toString());
                                                                setTempo(moment())
                                                                setResposta([...resposta]);
                                                                setProgresso(progresso - 1)
                                                            }
                                                        }}
                                                    >
                                                        Anterior
                                                    </Button>
                                                    : ""}
                                            </Grid>
                                            <Grid item>
                                                {progresso > 0 && progresso < resposta.length ?
                                                    <Button className="opacidade" style={{
                                                        marginTop: "1rem",
                                                        backgroundColor: "#5e34d6",
                                                        color: "white",
                                                        paddingLeft: "1.5rem",
                                                        paddingRight: "1rem"
                                                    }} endIcon={<SendIcon />} size={"small"}
                                                        onClick={_ => {
                                                            if (travar) {
                                                                setMostrar(true)
                                                                resposta[progresso].id_atividade = aula.atividades[progresso].id;
                                                                resposta[progresso].tempo += moment.duration(moment().diff(tempo)).asMilliseconds();
                                                                resposta[progresso].timestamp = new Date(moment().tz('America/Sao_Paulo').toString());
                                                                setTempo(moment())
                                                                setResposta([...resposta]);
                                                                setProgresso(progresso + 1)
                                                            }
                                                        }}
                                                    >
                                                        {progresso === resposta.length - 1 ? "Finalizar" : "Próxima"}
                                                    </Button>
                                                    : ""
                                                }

                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </>
                            : fim ?
                                <>

                                    <Grid item xs={10} className="sombra" style={{ borderRadius: "1rem" }}>

                                        <Card style={{ boxShadow: "none" }}>
                                            <Result
                                                icon={
                                                    <>
                                                        {/* <Fogos /> */}
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                                            <Lottie
                                                                options={lottieDefaultOptions(win)}
                                                                height={"300px"}
                                                                width={"300px"}
                                                                style={{ marginTop: "-6rem", marginBottom: "-4rem" }}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                title={"Quiz - " + aula.nome + " concluído!"}
                                                subTitle={"Resumo:"}
                                                extra={[
                                                    <div key={aula?.atividades[0]?.id}>
                                                        <Grid container direction="row"
                                                            justifyContent="center"
                                                            alignItems="center" style={{ marginTop: "-.5rem" }} spacing={1}>
                                                            {aula.atividades.map((perguntaItem: Atividade, __: number) =>
                                                                <React.Fragment key={perguntaItem.id}>

                                                                    <Grid item style={{ marginTop: "-1.5rem" }}>
                                                                        {__ === 0 ? <p style={{ fontWeight: "bold" }}>Aula: {aula.nome}</p>
                                                                            : <p style={{ fontWeight: "bold" }}>Quiz: {__}</p>
                                                                        }
                                                                    </Grid>
                                                                    {aula?.avaliacao !== 2 ?
                                                                        <>
                                                                            {perguntaItem.resposta_certa === resposta[__].id_resposta || perguntaItem.resposta_certa === "" || perguntaItem.resposta_certa === undefined ?
                                                                                <Grid item style={{ marginTop: "-1.5rem" }}>
                                                                                    <CheckBoxIcon style={{ color: "green" }} />
                                                                                </Grid>
                                                                                :
                                                                                <Grid item style={{ marginTop: "-1.5rem" }}>
                                                                                    <DangerousIcon style={{ color: "red" }} />
                                                                                </Grid>

                                                                            }
                                                                        </> : <Grid item style={{ marginTop: "-1.5rem" }}>
                                                                            <CheckBoxIcon style={{ color: "green" }} />
                                                                        </Grid>
                                                                    }
                                                                    <Grid item xs={12} />
                                                                </React.Fragment>)}

                                                            <Grid item xs={12} style={{ marginBottom: "-2rem", marginTop: ".5rem" }}>
                                                                <ButtonSair aula={aula} central={window.location.href.includes('centralcursos')} cor={cor} uuid_turma={uuid_turma || ""} uuid_curso={uuid_curso} texto={"Sair"} />
                                                            </Grid>

                                                        </Grid>
                                                    </div>
                                                ]}

                                            />

                                        </Card>

                                    </Grid>
                                </> : <>
                                    <Grid item xs={12}>
                                        <Grid item xs={12}>
                                            <BorderLinearProgress sx={{
                                                backgroundColor: 'white',
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: cor
                                                }
                                            }}
                                                style={{ zIndex: "-1", }} variant="determinate" value={((progresso) * 100) / aula.atividades.length} />

                                            <Grid item xs={12} style={{ marginTop: "1rem" }}>
                                                <Grid container
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center" spacing={2}>
                                                    {aula.atividades.map((_: any, n: number) => <React.Fragment key={_.id}>
                                                        <Grid item>
                                                            {n === 0 ?
                                                                <Button variant="outlined" className="opacidade" style={{ borderColor: cor, color: verificaSelecao(n), backgroundColor: verificaSelecaoFundo(n) }} onClick={() => setProgresso(n)} >
                                                                    Aula
                                                                </Button>
                                                                :
                                                                <Button variant="outlined" className="opacidade" style={{ borderColor: cor, color: verificaSelecao(n), backgroundColor: verificaSelecaoFundo(n) }} onClick={() => setProgresso(n)} >
                                                                    Questão  {n}
                                                                </Button>
                                                            }
                                                        </Grid>
                                                    </React.Fragment>)}
                                                    <Grid item>
                                                        <Button variant="outlined" className="opacidade" style={{ borderColor: cor, color: "white", backgroundColor: cor }} onClick={() => setProgresso(aula?.atividades?.length)} >
                                                            Resumo
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={10} className="sombra" style={{ borderRadius: "1rem", marginTop: "1rem" }}>

                                        <Card style={{ boxShadow: "none", }}>
                                            <Result
                                                icon={
                                                    <>
                                                        {/* <Fogos /> */}
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                                            <Lottie
                                                                options={lottieDefaultOptions(checkin)}
                                                                height={"300px"}
                                                                width={"300px"}
                                                                style={{ marginTop: "-4rem", marginBottom: "-2rem" }}
                                                            />
                                                        </div>
                                                    </>
                                                }

                                                subTitle={"Resumo:"}
                                                extra={[
                                                    <div key={aula?.atividades[0]?.id}>
                                                        <Grid container direction="row"
                                                            justifyContent="center"
                                                            alignItems="center" style={{ marginTop: "-.5rem" }} spacing={1}>
                                                            {aula.atividades.map((perguntaItem: Atividade, __: number) =>

                                                                <React.Fragment key={perguntaItem.id}>

                                                                    <Grid item style={{ marginTop: "-1.5rem" }}>
                                                                        {__ === 0 ? <h1 style={{ fontWeight: "bold" }}>Aula: {aula.nome}</h1>
                                                                            : <>
                                                                                {/* <h2 style={{ fontWeight: "bold" }}>Quiz: {__}</h2> */}
                                                                                <h3>{perguntaItem.nome}</h3><span style={{ fontWeight: "normal" }}>{conferir(perguntaItem, resposta, __)} <br /> <br /></span></>
                                                                        }
                                                                    </Grid>

                                                                    <hr style={{ width: "100%" }} />
                                                                    <Grid item xs={12} />
                                                                </React.Fragment>)}

                                                            <Grid item xs={12} style={{ marginBottom: "-2rem", marginTop: ".5rem" }}>
                                                                <Grid container spacing={2} justifyItems={"center"} justifyContent={"center"}>
                                                                    <Grid item>
                                                                        <CorButton cor={"#ff0000"}>
                                                                            <Button onClick={() => { setProgresso(progresso - 1); setMostrar(true); }}>
                                                                                Cancelar
                                                                            </Button>
                                                                        </CorButton>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <CorButton cor={cor}>
                                                                            <Button onClick={() => { setFim(true); handleEnviar() }}>
                                                                                Finalizar
                                                                            </Button>
                                                                        </CorButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                ]}

                                            />

                                        </Card>

                                    </Grid>
                                </>
                        }
                        </>

                    }


                </Grid>

            </div>
            <div style={{ marginBottom: "1.5rem" }}>

            </div>
        </div >

    )
}

export default RespostaEnviar;
