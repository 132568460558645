import styled from "styled-components";


export function hexToRgb(hex: string, a: number) {

  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return "rgb(" + Math.round(r * a + 255 * (1 - a)) + ", " + Math.round(g * a + 255 * (1 - a)) + ", " + Math.round(b * a + 255 * (1 - a)) + ")";
}


interface CorInterface {
  cor: string;
}

export const CorTurma = styled.div<CorInterface>`
  div{
    
    background-color: ${(props) => props.cor};
  }
`

export const Icone = styled.div<CorInterface>`
    
    display: flex;
    flex-direction: row;
    justify-content: center;
  svg{
    width: 72px;
    height: 72px;
    color:  ${(props) => props.cor};
  }
`

export const ImgTrofel = styled.div<CorInterface>`

    display: flex;
    flex-direction: row;
    justify-content: center;


    span {
        border:  ${(props) => props.cor} solid .2rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15rem;
        height: 15rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 1);
    }

    img {
    max-width: 65%;
    border-radius: 0;
}

`;

export const CorButton = styled.div<CorInterface>`
  
  button{
      color: white;
      padding: 4px 15px;
      border-radius: 6px;
      background-color:  ${(props) => props.cor};
      text-transform: none;
      font-size: 14px;
  }

  button:hover{
      background-color: ${(props) => hexToRgb(props.cor || "", .8)};
  }

`;

export const CorCirculosFundo = styled.div<CorInterface>`
      background-color:  ${(props) => props.cor};
      border-radius: 8px;
      cursor: default;
      user-select: none;
`;

export const CorP = styled.div<CorInterface>`
      p{
        color:  ${(props) => props.cor};
        border-bottom: 1px solid  ${(props) => props.cor};  
      }
      
`;

interface CorSelecaoProps {
  selecionado?: boolean;
  ativio?: boolean;
  trancar?: boolean;
  cor: string;
}


export const CorSelecao = styled.div<CorSelecaoProps>`
    div{
      background-color: ${props => props?.selecionado ? props?.cor : hexToRgb(props?.cor || "", .5)};
    }
`;



export const Cor = styled.div<CorSelecaoProps>`

      
      cursor:  ${props => props?.ativio && props?.trancar === false ? "pointer" : "default"};
      color: white;
      padding: .3rem;
      border: 2px solid #003068;
      border-radius: 1rem;
      background: linear-gradient(#166BCF, #4399FF);
      box-shadow: inset 0px -.8rem 0px #175298, 0px 0px 0px 4px #E1E1E1, rgba(0, 0, 0, 1) 0px 5px 15px;
      font-family: "Nunito", sans-serif;
      text-transform: uppercase;
      text-align: center;
      user-select: none;
      p {
        padding-left: 1rem;
        padding-right: 1rem;
        text-align: center;
        color: white;
    }
    :hover{
      background:linear-gradient(${hexToRgb("#166BCF", .75)}, ${hexToRgb("#4399FF", .75)});
      box-shadow: inset 0px -.8rem 0px ${hexToRgb("#175298", .9)}, 0px 0px 0px 4px #E1E1E1, rgba(0, 0, 0, 1) 0px 5px 15px;
    }
    

`;

export const GridSpan = styled.span<CorInterface>`
  color:  ${(props) => props.cor};
  padding: .5rem 2rem .09rem 2rem;
  background-color: ${props => hexToRgb(props?.cor || "", .1)};
  border-radius: .5rem .5rem 0 0;
  border-top: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  box-shadow: rgba(100, 100, 111, 0.2) 0px -4px 15px 0px;
  
`;

export const GridSpanDiv = styled.span<CorInterface>`
span{
  color:  ${(props) => props.cor};
  padding: .5rem 2rem .09rem 2rem;
  background-color: ${props => hexToRgb(props?.cor || "", .1)};
  border-radius: .5rem .5rem 0 0;
  border-top: .1rem solid #e1e1e1;
  border-right: .1rem solid #e1e1e1;
  border-left: .1rem solid #e1e1e1;
  box-shadow: rgba(100, 100, 111, 0.2) 0px -4px 15px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  font-weight: bold;
  
    
}
`;

export const GridSpanCenter = styled.span`
  color: #0048b6;
  font-size: 18px;
  font-weight: bold;
  padding: .5rem 2rem .09rem 2rem;
  border-radius: .5rem .5rem 0 0;
  border-top: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  box-shadow: rgba(100, 100, 111, 0.2) 0px -4px 15px 0px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: .5rem;
  padding-top: .5rem;
`;



export const GridResposta = styled.div<CorInterface>`
  span{
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
            border-color:  ${(props) => props.cor}; 
      }
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper:focus,
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper-focused {
          border-color:  ${(props) => props.cor};
          box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
      color:  ${(props) => props.cor};
    }

    input{
      color:  ${(props) => props.cor};
      :where(.css-dev-only-do-not-override-mxhywb).ant-input:focus,
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-focused {
          border-color:  ${(props) => props.cor};
          box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
    }
`;

export const GridAtividadeBorda = styled.div<CorInterface>`
    padding: 3rem;
    border: 1px solid #e1e1e1;
    border-radius: .5rem .5rem .5rem .5rem;
    border: .1rem;
    border-style: solid;
    border-color: #cccccc;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 1px 15px 0px;
    
    
    div{
      :where(.css-dev-only-do-not-override-mxhywb).ant-picker .ant-picker-input > input{
        color:  ${(props) => props.cor};
      }
      :where(.css-dev-only-do-not-override-mxhywb).ant-picker:hover,
      :where(.css-dev-only-do-not-override-mxhywb).ant-picker-focused
       {
        border-color:  ${(props) => props.cor};
        box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-number .ant-input-number-input{
        color:  ${(props) => props.cor};
      }
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-number:hover{
        border-color:  ${(props) => props.cor};
        box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-number-focused {
            border-color:  ${(props) => props.cor};
            box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
      
    }


    input{
      color:  ${(props) => props.cor};
      :where(.css-dev-only-do-not-override-mxhywb).ant-input:focus,
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-focused
        {
          border-color:  ${(props) => props.cor};
          box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
    }
    
    textarea{
      color:  ${(props) => props.cor};
      :where(.css-dev-only-do-not-override-mxhywb).ant-input:focus,
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-focused,
      :where(.css-dev-only-do-not-override-mxhywb).ant-input:hover{
        border-color:  ${(props) => props.cor};
        box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
    }

    span{
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
            border-color:  ${(props) => props.cor}; 
      }
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper:focus,
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper-focused {
          border-color:  ${(props) => props.cor};
          box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
    }
    
`;


export const GridAtividade = styled.div<CorInterface>`
    padding: .5rem;
    border: 1px solid #e1e1e1;
    border-radius: 0 .5rem .5rem .5rem;
    border: .1rem;
    border-style: solid;
    border-color: #cccccc;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 1px 15px 0px;
    
    
    div{
      :where(.css-dev-only-do-not-override-mxhywb).ant-picker .ant-picker-input > input{
        color:  ${(props) => props.cor};
      }
      :where(.css-dev-only-do-not-override-mxhywb).ant-picker:hover,
      :where(.css-dev-only-do-not-override-mxhywb).ant-picker-focused
       {
        border-color:  ${(props) => props.cor};
        box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-number .ant-input-number-input{
        color:  ${(props) => props.cor};
      }
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-number:hover{
        border-color:  ${(props) => props.cor};
        box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-number-focused {
            border-color:  ${(props) => props.cor};
            box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
      
    }


    input{
      color:  ${(props) => props.cor};
      :where(.css-dev-only-do-not-override-mxhywb).ant-input:focus,
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-focused
        {
          border-color:  ${(props) => props.cor};
          box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
    }
    
    textarea{
      color:  ${(props) => props.cor};
      :where(.css-dev-only-do-not-override-mxhywb).ant-input:focus,
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-focused,
      :where(.css-dev-only-do-not-override-mxhywb).ant-input:hover{
        border-color:  ${(props) => props.cor};
        box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
    }

    span{
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
            border-color:  ${(props) => props.cor}; 
      }
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper:focus,
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper-focused {
          border-color:  ${(props) => props.cor};
          box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
    }
    
`;

export const GridAtividades = styled.div<CorInterface>`

    padding: .5rem;
    border: 1px solid #e1e1e1;
    border-radius: 0 .5rem .5rem .5rem;
    border: .1rem;
    border-style: solid;
    border-color: #cccccc;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 1px 15px 0px;
    
      input{
      color:  ${(props) => props.cor};
      :where(.css-dev-only-do-not-override-mxhywb).ant-input:focus,
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-focused,
      :where(.css-dev-only-do-not-override-mxhywb).ant-input:hover  {
          border-color:  ${(props) => props.cor};
          box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
    }
`;

export const GridInput = styled.div<CorInterface>`

    
div{
      :where(.css-dev-only-do-not-override-mxhywb).ant-picker .ant-picker-input > input{
        color:  ${(props) => props.cor};
      }
      :where(.css-dev-only-do-not-override-mxhywb).ant-picker:hover,
      :where(.css-dev-only-do-not-override-mxhywb).ant-picker-focused
       {
        border-color:  ${(props) => props.cor};
        box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-number .ant-input-number-input{
        color:  ${(props) => props.cor};
      }
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-number:hover{
        border-color:  ${(props) => props.cor};
        box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-number-focused {
            border-color:  ${(props) => props.cor};
            box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
      
    }


    input{
      color:  ${(props) => props.cor};
      :where(.css-dev-only-do-not-override-mxhywb).ant-input:focus,
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-focused
        {
          border-color:  ${(props) => props.cor};
          box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
    }
    
    textarea{
      color:  ${(props) => props.cor};
      :where(.css-dev-only-do-not-override-mxhywb).ant-input:focus,
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-focused,
      :where(.css-dev-only-do-not-override-mxhywb).ant-input:hover{
        border-color:  ${(props) => props.cor};
        box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
    }

    span{
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
            border-color:  ${(props) => props.cor}; 
      }
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper:focus,
      :where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper-focused {
          border-color:  ${(props) => props.cor};
          box-shadow: 0 0 0 2px ${props => hexToRgb(props?.cor || "", .1)};
      }
    }
`;
