import { memo, useState } from "react"
import "../index.css"
import { useNavigate, useParams } from "react-router-dom"
import { CorSelecao } from "../cor"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Button as ButtonAntd } from 'antd';

function Selecao({ setTela, menu, selecionado, cor }: any) {
    const navigate = useNavigate();
    const { uuid_turma, uuid_curso } = useParams();
    const [open, setOpen] = useState(false);

    const handleNavegar = () => {
        if (menu.toLocaleLowerCase() === 'aulas') {
            navigate(`/turma/${uuid_turma}/${uuid_curso}`)
        }
        if (menu.toLocaleLowerCase() === 'pessoas') {
            setTela('PESSOAS');
        }
        if (menu.toLocaleLowerCase() === 'cursos') {
            setTela('CURSOS');

        }
        if (menu.toLocaleLowerCase() === 'trilhas') {
            setTela('TRILHAS');

        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (<>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`Tem certeza que deseja realmente sair?`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ButtonAntd type="primary" onClick={handleClose}>Não</ButtonAntd>
                <ButtonAntd type="primary" danger onClick={_ => {
                    handleNavegar();
                }} autoFocus>
                    Sim
                </ButtonAntd>

            </DialogActions>
        </Dialog>
        {menu === selecionado ? <>
            <CorSelecao selecionado={true} cor={cor} >
                <div className="centralizar2" style={{ userSelect: "none", boxShadow: ` rgba(0, 0, 0, 0.5) 0px 3px 16px, ${cor} 0px 3px 6px`, }} onClick={window.location.href.includes('resposta') ? handleClickOpen : handleNavegar} >
                    <p >{menu}</p>
                </div>
            </CorSelecao>
        </>
            : <CorSelecao selecionado={false} style={{ userSelect: "none" }} cor={cor} onClick={handleNavegar}>
                <div className="centralizar" style={{ cursor: "pointer" }}>
                    <p>{menu}</p>
                </div>
            </CorSelecao>
        }
    </>)
}


export default memo(Selecao)