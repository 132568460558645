import { useState } from 'react';
import { Paper, Typography, TextField, Button, Grid, RadioGroup, FormControl, FormControlLabel, Radio, Checkbox, Modal } from '@mui/material';
import cadastro from '../../img/lotties/Animation - 1703699625037.json';
import Lottie from 'react-lottie';
import { lottieDefaultOptions } from "../../components/types"
import { toast } from 'react-toastify';
import { Form, useNavigate } from 'react-router-dom';
import background from "../../img/background/background.svg"
import sappens from "../../img/background/logo-sappens.0c9e344815819f9fe519.png"
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { DateRangeIcon } from '@mui/x-date-pickers';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PeopleIcon from '@mui/icons-material/People';
import api from '../../axios/axios';
import recuperou from '../../img/lotties/animation_lkbzje7w.json';

function FormularioCadastro() {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    const navigate = useNavigate();
    const [opcaoSelecionada, setOpcaoSelecionada] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const handleOpcaoChange = (event: any) => {
        setOpcaoSelecionada(event.target.value);
    };


    const handleNomeChange = (e: any) => {
        setNome(e.target.value);
    };

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
    };


    const handledataNascimentoChange = (e: any) => {
        setDataNascimento(e.target.value);
    };


    const handleSubmit = (e: any) => {
        e.preventDefault();
        toast.info("Cadastrando")
        api.post('/cadastro/govtech', { "nome": nome, 'dataNascimento': dataNascimento, 'email': email, "educacao": opcaoSelecionada })
            .then(r => {
                toast.success("Cadastro realizado")
                setShowModal(true)
            })
            .catch((error: any) => {
                toast.error("Cadastro não realizado");
            });

    };




    const theme = createTheme({
        palette: {
            primary: {
                main: '#363636'
            }
        }
    });
    const handleCloseModal = () => {
        setShowModal(false);
        navigate(`/`);
    };
    return (

        <Grid
            container
            direction="row"
            justifyContent="space-between"
            style={{ background: `url(${background}) center center / cover fixed no-repeat`, minHeight: "100vh" }}
            spacing={1}
        >
            <Modal open={showModal} onClose={handleCloseModal}>
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '2rem', borderRadius: '8px' }}>
                    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} >
                        <Grid item xs={12}>
                            <Lottie
                                options={lottieDefaultOptions(recuperou)}
                                height={250}
                                width={350}
                            />
                        </Grid>

                        <Grid item style={{ border: "1px solid #757575", borderRadius: "1rem", marginTop: "1rem", }}>
                            <Grid container direction="column" justifyContent="center" alignItems="center"  >
                                <Grid item xs={12} style={{ marginRight: "1rem" }}>
                                    <Typography variant="h5" sx={{ marginBottom: 2 }} style={{ textAlign: "center" }}>
                                        Senha enviada para o e-mail<br />{email}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" onClick={handleCloseModal} style={{ backgroundColor: "#616161", borderRadius: "10px", lineHeight: 1.3333333, textTransform: "capitalize", fontSize: "14px", fontFamily: "Open Sans,Helvetica,Arial,sans-serif", fontWeight: 600 }}>
                                Fechar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
            <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ textAlign: "center" }}
                >
                    <Grid item xs={12}>
                        <div
                            style={{
                                background: `url(${sappens}) center center / contain no-repeat`,
                                width: "300px",
                                height: "100px",
                                margin: "auto",
                                marginBottom: "1rem"
                            }}
                        ></div>
                    </Grid>



                    <Grid item xs={12} md={6} lg={5} style={{
                        alignItems: "center",
                        justifyContent: "center",

                    }}>


                        <Paper elevation={3} sx={{ padding: 6, borderRadius: "30px", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} >
                                <Grid item xs={12}>

                                    <Lottie
                                        options={lottieDefaultOptions(cadastro)}
                                        height={200}
                                        width={"100%"}
                                        style={{ maxWidth: "250px", marginTop: "-2rem" }}
                                    />

                                </Grid>

                            </Grid>

                            <Typography variant="h5" sx={{ marginBottom: 2, marginTop: "1rem" }}>
                                GOV Tech
                            </Typography>
                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <ThemeProvider theme={theme}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="nome"
                                                name="nome"
                                                label="Nome completo"
                                                value={nome}
                                                onChange={handleNomeChange}
                                                className="custom-textfield"
                                                InputProps={{
                                                    startAdornment: (
                                                        // Use the DateRangeIcon as the start adornment
                                                        <PeopleIcon color="primary" />
                                                    ),
                                                }}
                                            />
                                        </ThemeProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ThemeProvider theme={theme}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="email"
                                                name="email"
                                                label="E-mail"
                                                value={email}
                                                onChange={handleEmailChange}
                                                className="custom-textfield"
                                                InputProps={{
                                                    startAdornment: (
                                                        // Use the DateRangeIcon as the start adornment
                                                        <AlternateEmailIcon color="primary" />
                                                    ),
                                                }}
                                            />
                                        </ThemeProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ThemeProvider theme={theme}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="data"
                                                name="data"
                                                label="Data de nascimento"
                                                type="date"
                                                value={dataNascimento}
                                                onChange={handledataNascimentoChange}
                                                InputLabelProps={{
                                                    shrink: true,

                                                }}
                                                InputProps={{
                                                    startAdornment: (

                                                        <DateRangeIcon color="primary" />
                                                    ),
                                                }}
                                            />
                                        </ThemeProvider>
                                    </Grid>
                                    <Typography variant="h6" sx={{ marginBottom: "-1rem", marginTop: ".5rem", marginLeft: "1rem" }}>
                                        Trabalha na Educação?
                                    </Typography>
                                    <Grid item xs={12}>
                                        <ThemeProvider theme={theme}>
                                            <FormControl fullWidth>
                                                <RadioGroup
                                                    aria-label="Opção"
                                                    name="professor"
                                                    value={opcaoSelecionada}
                                                    onChange={handleOpcaoChange}
                                                    row
                                                >
                                                    <FormControlLabel
                                                        value={true}
                                                        control={<Radio />}
                                                        label="Sim"
                                                    />
                                                    <FormControlLabel
                                                        value={false}
                                                        control={<Radio />}
                                                        label="Não"
                                                    />
                                                    {/* Adicione mais opções conforme necessário */}
                                                </RadioGroup>
                                            </FormControl>
                                        </ThemeProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    required
                                                    style={{ color: '#616161' }}

                                                />
                                            }
                                            label="Eu concordo com os termos e condições"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Button type="submit" fullWidth variant="contained" color="primary" style={{ backgroundColor: "#616161", borderRadius: "10px", lineHeight: 1.3333333, textTransform: "capitalize", fontSize: "14px", fontFamily: "Open Sans,Helvetica,Arial,sans-serif", fontWeight: 600 }}>
                                                    Cadastrar
                                                </Button>

                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button onClick={() => navigate("/")} type="submit" fullWidth variant="contained" color="primary" style={{ backgroundColor: "#616161", borderRadius: "10px", lineHeight: 1.3333333, textTransform: "capitalize", fontSize: "14px", fontFamily: "Open Sans,Helvetica,Arial,sans-serif", fontWeight: 600 }}>
                                                    Voltar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </Grid >
                </Grid>
            </Grid >
        </Grid >
    );
};

export default FormularioCadastro;
