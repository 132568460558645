import Pagination from '@mui/material/Pagination';
import { Grid } from "@mui/material";

function Paginacao({ atividade, handlePage, trilha = false }: any) {

    return (<>
        <div style={{ marginBottom: "1.5rem" }}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                rowSpacing={2}
                spacing={1}
            >
                <Grid item >
                    {atividade.total > atividade.size ?
                        <Pagination
                            onChange={(e, value) => trilha ? handlePage(value, trilha) : handlePage(value)}
                            style={{ marginTop: "1rem" }}
                            count={Math.ceil(atividade.total / atividade.size)}
                            defaultPage={1}
                            page={atividade.page}
                        />
                        : ""
                    }
                </Grid>
            </Grid>

        </div>
    </>)
}

export default Paginacao;